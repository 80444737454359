.m-address {
    $root: &;
    background: $raisin-black004;
    border: rem(1) solid $raisin-black02;
    border-radius: rem(4);
    padding: rem(26) rem(28);
    position: relative;
    cursor: pointer;

    @include mobile-down {
        padding-left: rem(21);
        padding-right: rem(21);
    }

    &-active {
        background: $aero012;
        border: 1px solid $vivid-cerulean;
    }

    &_gerenal {
        @include adjust-flex(flex-start, flex-start);

        &_label {
            min-width: rem(80);

            @include mobile-down {
                min-width: auto;
            }
        }

        &_content {
            margin-left: rem(28);

            @include mobile-down {
                margin-left: rem(10);
            }
        }
    }

    &_floatIcon {
        cursor: pointer;
        position: absolute;
        top: -8%;
        right: rem(-17);
     
        &_edit {
            transform: translateY(-8%);
            transition: $transition linear;
            margin-bottom: rem(10);

            &-active {
                transform: translateY(120%);
            }
        }

        &_tick {
            transition: transform $transition linear;
            transform: scale(0);

            &-active {
                transform: scale(1);
            }
        }
    }
}
