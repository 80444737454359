.t-headerSearch {
    position: relative;

    &_suggestion {
        position: absolute;
        top: calc(100% + 6px);
        right: 0;
        opacity: 0;
        visibility: hidden;
        min-width: rem(335);
        z-index: 22;
        transition: all $transition ease-in-out;

        &-open {
            opacity: 1;
            visibility: visible;
        }
    }

    &_suggestionList {
        background-color: $white;
        padding: rem(15) rem(17) rem(10);
        border-radius: rem(8);
        border: 1px solid $sonic-silver008;
    }

    &_suggestionItem {
        padding: rem(12) 0;
        border-bottom: 1px solid $raisin-black01;

        &:first-child {
            padding-top: 0;
        }
    }
}

.t-cardSearch {
    &_link {
        display: flex;
    }

    &_image {
        width: rem(64);
        flex-shrink: 0;
        margin-right: rem(16);

        .a-image {
            border-radius: rem(4);
            overflow: hidden;
        }
    }

    &_info {
        .a-paragraph-text {
            &:first-child {
                @include text-overflow;
            }

            &:last-child {
                margin-top: rem(21);
            }
        }

    }
}
