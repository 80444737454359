.t-homeProductCategory {
    $root: &;

    &-lg {
        #{$root}_content {
            bottom: rem(24);
            left: rem(42);
            right: rem(42);
            @include break(1200px, 1440px) {
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }
            @include mobile-down {
                bottom: rem(10);
                left: rem(14);
                right: rem(14);
            }
        }

        #{$root}_label {
            @include font-base(20, 24);
            @include desktop-down {
                @include font-base(18, 22);
            }
            @include mobile-down {
                @include font-base(14, 17);
            }
        }
        
        #{$root}_value {
            @include font-base(90, 102);
            margin-top: rem(16);

            @include desktop-down {
                margin-top: rem(10);
            }

            @include mobile-down {
                @include font-base(40, 56);
            }
        }
    }

    &-md {
        #{$root}_content {
            bottom: rem(40);
            left: rem(40);
            right: rem(40);
            @include mobile-down {
                bottom: rem(16);
                left: rem(16);
                right: rem(16);
            }
        }

        #{$root}_label {
            @include font-base(20, 24);
            @include desktop-down {
                @include font-base(18, 22);
            }
            @include mobile-down {
                @include font-base(14, 17);
            }
        }
        
        #{$root}_value {
            @include font-base(36, 48);
            margin-top: rem(16);

            @include mobile-down {
                @include font-base(22, 26);
                margin-top: rem(8);
            }
        }
    }

    .item:nth-child(odd) {
        padding-top: rem(32);
        @include mobile-down {
            padding-top: rem(24);
        }
    }

    &_wrapper {
        position: relative;
        display: block;

        .a-image {
            transition: $transition;
        }

        &:hover {
            .a-image {
                transform: scale(1.1);
            }
        }
    }

    &_thumbnail {
        overflow: hidden;
    }

    &_content {
        position: absolute;
    }

    &_label {
        color: $white;
    }

    &_value {
        color: $white;
        font-weight: 500;
    }
}
