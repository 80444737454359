.t-aboutUsSummary {
    $root: &;
    position: relative;
    padding-top: rem(64);
    
    @include desktop-down {
        padding-top: rem(78);
    }

    @include mobile-down {
        padding-top: rem(54);
    }

    &_title {
        position: absolute;
        top: rem(80);
        z-index: 3;
        max-width: rem(777);
        transform: translateX(rem(634));

        @include break-max(1599px) {
            transform: translateX(70%);
        }
        
        @include break-max(1366px) {
            transform: translateX(60%);
        }

        @include desktop-down {
            position: relative;
            top: 0;
            transform: none;
            max-width: 100%;
        }
    }

    &_content {
        @include adjust-flex(space-between, center);

        @include desktop-down {
            flex-direction: column;
            margin-top: rem(38);
        }

        @include mobile-down {
            margin-top: rem(42);
        }
    }
    
    &_smallimg {
        position: relative;
        z-index: 3;
        width: calc(276 / 705) * 100%;
        transform: translateX(rem(104));

        @include tablet-desktop {
            transform: translateX(rem(26));
        }

        @include mobile-down {
            transform: translateX(rem(14));
        }
    }

    &_bigimg {
        width: calc(389 / 705) * 100%;
        margin-left: auto;
        z-index: 2;

        @include tablet-desktop {
            transform: translateX(rem(-69));
        }

        @include mobile-down {
            transform: translateX(rem(-32));
        }
    }

    &_left {
        position: relative;
        flex: 0 0 calc(705 / 1360) * 100%;

        @include desktop-down {
            width: 100%;
        }

        @include adjust-flex(space-between, center);

        &::before {
            content: '';
            position: absolute;
            width: rem(207);
            height: rem(207);
            background: $verdigris;
            top: rem(80);
            left: rem(30);
            border-radius: 50%;

            @include desktop {
                left: rem(-46);
                top: rem(75);
                width: rem(235);
                height: rem(235);
            }

            @include tablet {
                width: rem(198);
                height: rem(198);
                left: rem(-46);
                top: rem(75);
            }
            
            @include mobile-down {
                width: rem(104);
                height: rem(104);
                left: rem(-23);
                top: rem(40);
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: rem(344);
            height: rem(344);
            background: $azureish-white085;
            top: rem(329);
            left: rem(479);
            border-radius: 50%;
            z-index: 1;

            @include break(1200px, 1599px) {
                width: rem(290);
                height: rem(290);
                left: rem(383);
                top: rem(297);
            }

            @include desktop-down {
                width: rem(330);
                height: rem(330);
                left: unset;
                top: unset;
                right: rem(-46);
                bottom: rem(-79);
            }

            @include mobile-down {
                width: rem(173);
                height: rem(173);
                right: rem(-23);
                bottom: rem(-41);
            }
        }
    }
    
    &_right {
        flex: 0 0 calc(655 / 1360) * 100%;
    }
        
        
    &_description {
        padding-left: rem(72);
        padding-right: rem(72);
        margin-top: rem(70);
        position: relative;
        z-index: 2;

        @include break-max(1366px) {
            padding-left: rem(48);
            padding-right: rem(48);
        }
        
        @include desktop-down {
            padding-left: 0;
            padding-right: 0;
            margin-top: rem(40);
        }

        @include mobile-down {
            margin-top: rem(22);
        }
    }

}
