.t-homeVoucher {
    $root: &;

    @include adjust-flex(space-between, center); 

    &_divider {
        width: 2px;
        height: rem(44);
        background: rgba($color: $raisin-black, $alpha: 0.1);
    }

    @include mobile-down {
        .o-container {
            padding-right: 0;
        }
    }

    &_button {
        display: none;
        white-space: nowrap;
        min-width: rem(207);

        @include break-min(768px) {
            display: block;
        }
    }

    &_carousel {
        min-width: 50%;
        flex: 1;
        @include adjust-flex(flex-start, center);

        &-list {
            width: 100%;
            @include break(768px, 1199px) {
                width: calc(100% - 90px);
            }
        }

        &-arrow {
            width: rem(90);
            display: none;
            justify-content: center;
            align-items: center;

            @include break(768px, 1199px) {
                display: flex;
            }

            &-item {
                background: transparent;
                width: rem(36);
                height: rem(36);
                border-radius: 50%;
                cursor: pointer;

                background-size: rem(12) rem(19);
                background-position: center;
                background-repeat: no-repeat;
                transition: 0.3s;

                &.prev {
                    background-image: url('~assets/icons/ic_chevron_prev_gray.svg');

                    &:hover {
                        background-image: url('~assets/icons/ic_chevron_prev_blue.svg');
                    }
                }

                &.next {
                    background-image: url('~assets/icons/ic_chevron_next_gray.svg');

                    &:hover {
                        background-image: url('~assets/icons/ic_chevron_next_blue.svg');
                    }
                }
            }
        }

        &-item {
            @include adjust-flex(flex-start, center); 
        }

        &-divider {
            flex: 1;
            @include adjust-flex; 

            @include small-mobile {
                min-width: rem(50);
            }
        }

        @include small-mobile {
            width: 100%;
        }

        .slick-active.slick-current + .slick-active {
            @include break(576px, 1199px) {
                #{$root}_divider {
                    width: 0;
                }
            }
        }
    }
}
