.t-productAsked {
    $root: &;
    padding-top: rem(68);
    padding-bottom: rem(100);

    @include desktop-down {
        padding-top: rem(65);
        padding-bottom: rem(69);
    }

    @include mobile-down {
        padding-top: rem(67);
        padding-bottom: rem(60);
    }

    &_item {
        margin-top: rem(32);

        @include desktop-down {
            margin-top: rem(30);
        }

        @include mobile-down {
            margin-top: rem(10);
        }
    }

    .a-categoryItem {
        padding-top: rem(38);
        padding-bottom: rem(38);

        @include tablet-down {
            padding-top: rem(28);
            padding-bottom: rem(28);
        }

        @include mobile-down {
            padding-top: rem(8);
            padding-bottom: rem(8);
        }

        &_icon {
            width: rem(42);
            height: rem(42);
            @include mobile-down {
                height: rem(28);
                width: rem(28);
            }
        }

        &_title {
            @include font-base(20,24);
            color: $raisin-black;
            font-weight: 500;
            margin-top: rem(12);

            @include mobile-down {
                @include font-base(14,18);
                margin-top: rem(8);
            }
        }
    }
}
