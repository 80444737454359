.t-productDetailInfo {
    &_title {
        margin-bottom: rem(20.5);
    }

    &_table {
        width: 100%;
        border-collapse: collapse;
        border-radius: rem(10);
        background: #fff;
        box-shadow: 0 rem(6) rem(40) $ming008;
        overflow: hidden;

        td {
            padding: rem(14) rem(30);

            @include mobile-down {
                padding: rem(14) rem(21);
            }
        }

        tr {
            &:nth-child(odd) {
                background-color: $aero012;
            }
        }
    }
}
