.p-aboutGUMAC {

    @include mobile-down {
        background-color: $white;
    }

    &_summary {
        background-color: $aero012;
    }

    &_founder {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 92%;
            background-color: $aero012;

            @include break-max(1366px) {
                height: 97.5%;
            }

            @include desktop-down {
                height: 105%;
            }

            @include tablet-down {
                height: 110%;
            }

            @include mobile-down {
                height: 105%;
            }
        }
    }

    &_banner {
        display: grid;
        grid-template-columns: auto auto auto auto;

        @include tablet-down {
            grid-template-columns: auto auto;
        }

        @include mobile-down {
            gap: rem(10);
        }

        &-img1 {
            grid-column: 1 / span 2;
            grid-row: 1/ span 2;
        }

        &-img1,
        &-img2,
        &-img3,
        &-img4,
        &-img5 {
            overflow: hidden;
            cursor: pointer;

            .a-image {
                transition: all $transition ease-in-out;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }
}
