.m-policyHeading {
    @include adjust-flex(space-between, center);

    @include max-desktop-down {
        flex-direction: column;
        align-items: flex-start;
    }

    &_title {
        display: flex;
        align-items: baseline;
        flex-shrink: 0;
    }

    &_search {
        display: flex;
        align-items: center;
        border-radius: rem(25);
        overflow: hidden;
        position: relative;
        width: 100%;
        max-width: rem(392);

        @include max-desktop-down {
            margin-top: rem(31);
            max-width: rem(458);
        }

        // @include  tablet-down {
        // }
        
        @include mobile-down {
            max-width: 100%;
            margin-top: rem(22);
        }
        
        
        input {
            height: rem(50);
            background-color: $aero024;
            @include font-base(16, 19);
            padding: 0 rem(50) 0 rem(18);
            border: 0;
            color: $raisin-black;
            font-family: inherit;
            outline: 0;
            width: 100%;

            @include mobile-down {
                height: rem(44);
                padding-right: rem(40);
            }
    
            &::placeholder {
                color: $vivid-cerulean;
            }
        }

        button {
            @include reset-button();
            background-color: transparent;
            position: absolute;
            width: rem(42);
            height: rem(42);
            right: rem(4);
            top: 50%;
            transform: translateY(-50%);
            background-color: $cyan-cornflower;
            border-radius: 50%;

            .a-icon {
                margin: auto;
            }

            @include mobile-down {
                width: rem(36);
                height: rem(36);
            }
        }
    }
}
