.p-search {
    padding: rem(86) 0 rem(100);
    min-height: 60vh;

    @include tablet-down {
        padding: rem(60) 0 rem(80);
    }

    @include mobile-down {
        padding: rem(40) 0 rem(60);
    }
}

.p-search_product {
    &_row {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-16);
        margin-right: rem(-16);
        @include mobile-down {
            margin-left: rem(-10);
            margin-right: rem(-10);
        }
    }

    &_col {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 0 rem(16);
        @include desktop-down {
            flex: 0 0 25%;
            max-width: 25%;
        }

        @include tablet-down {
            flex: 0 0 33.333%;
            max-width: 33.333%;
        }

        @include mobile-down {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 rem(10);
        }
    }
}

.p-search_news {
    &_row {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-16);
        margin-right: rem(-16);
        @include tablet-down {
            margin-left: rem(-10);
            margin-right: rem(-10);
        }
    }

    &_col {
        flex: 0 0 33.333%;
        max-width: 33.333%;
        padding: 0 rem(16);

        @include tablet-down {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 rem(10);
        }

        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}


.p-search_filter {
    &_top {
        display: flex;
        align-items: center;
        @include tablet-down {
            display: block;
        }
    }

    &_divider {
        margin-top: rem(16);
        height: rem(1);
        background-color: $raisin-black01;
        @include mobile-up {
            display: none;
        }
    }

    &_category {
        display: flex;
        align-items: center;
        margin-left: rem(15);
        @include tablet-down {
            margin-left: 0;
            margin-top: rem(16);
        }
        @include mobile-down {
            display: block;
        }
    }

    &_checkbox {
        padding: rem(12) rem(6) rem(12) rem(16);
        background: $white;
        border: 1px solid $platinum1;
        border-radius: rem(24);
        @include mobile-down {
            margin-top: rem(16);

            &:first-of-type {
                margin-top: rem(24);
            }
        }

        &:first-child {
            margin-right: rem(24);
            @include mobile-down {
                margin-right: 0;
            }
        }
    }

    &_main {
        min-height: rem(48);
        padding: 0 rem(24);
        display: flex;
        align-items: center;
        border-radius: rem(24);
        background: $white;
        box-shadow: 0 0 16px rgba($white, 0.03);
    }

    &_content {
        flex: 1;
    }
}

.p-searchFilterProduct {
    display: flex;
    align-items: center;

    &_text {
        &.desktop {
            @include mobile-down {
                display: none;
            }
        }

        &.mobile {
            @include mobile-up {
                display: none;
            }
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: rem(-12);
                top: 50%;
                transform: translateY(-50%);
                width: rem(8);
                height: rem(8);
                background: url('~assets/icons/ic_arrow_down_blue.svg') no-repeat center / contain;
            }
        }
    }

    &_group {
        display: flex;
        align-items: center;
        margin-left: rem(95);
        margin-right: rem(20);
        @include tablet-down {
            margin-left: rem(46);
        }
        @include mobile-down {
            display: none;
        }

        > div {
            margin-right: rem(30);

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &_right {
        margin-left: auto;
        flex: 0 0 rem(173);

        .m-pulldownResize_container {
            padding: rem(4) rem(20) rem(4) rem(16);
            border-radius: rem(16);
            border: 1px solid $anti-flash-white;

            &_arrow {
                right: rem(8);
            }

            &-menu {
                left: auto;
                right: 0;
                width: 100%;
            }
        }
    }

    &_navbar {
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        visibility: hidden;
        transition: $transition linear;
        width: 100%;
        height: 100vh;
        overflow: auto;
        background-color: $white;
        z-index: $z-filter-search-mobile;
        padding-top: rem(40);

        &-open {
            visibility: visible;
            transform: translateX(0);
        }

        &_pulldown {
            padding-bottom: rem(16);
            border-bottom: 1px solid $anti-flash-white;
        }

        .m-pulldownResize {
            width: fit-content;
        }

        &_btn {
            display: flex;
            align-items: center;
            margin-left: rem(-10);
            margin-right: rem(-10);

            > div {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 rem(10);
            }
        }
    }
}

.p-searchFilterNews {
    display: flex;
    align-items: center;

    &_text {
        &.desktop {
            flex-shrink: 0;
            @include mobile-down {
                display: none;
            }
        }

        &.mobile {
            @include mobile-up {
                display: none;
            }
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: rem(-12);
                top: 50%;
                transform: translateY(-50%);
                width: rem(8);
                height: rem(8);
                background: url('~assets/icons/ic_arrow_down_blue.svg') no-repeat center / contain;
            }
        }
    }

    &_group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include mobile-down {
            display: none;
        }

        > div {
            margin-left: rem(24);
        }
    }

    &_checkbox {
        .a-checkbox {
            flex-direction: row-reverse;

            input {
                min-width: auto;
                min-height: auto;
                width: rem(16);
                height: rem(16);

                &::before {
                    width: rem(10);
                    height: rem(10);
                }
            }

            label {
                padding: 0 rem(5) 0 0;
            }
        }
    }

    &_navbar {
        position: fixed;
        right: 0;
        top: 0;
        transform: translateX(100%);
        visibility: hidden;
        transition: $transition linear;
        width: 100%;
        height: 100vh;
        overflow: auto;
        background-color: $white;
        z-index: $z-filter-search-mobile;
        padding-top: rem(40);

        &-open {
            visibility: visible;
            transform: translateX(0);
        }

        &_btn {
            display: flex;
            align-items: center;
            margin-left: rem(-10);
            margin-right: rem(-10);

            > div {
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 rem(10);
            }
        }
    }
}

.m-pulldownResize {
    &_container {
        $container: &;
        position: relative;
        padding-right: rem(13);
        cursor: pointer;
        display: flex;
        align-items: center;

        &_title {
            flex-grow: 1;
            @include text-overflow(1);
        }

        &_clear {
            width: rem(20);
            height: rem(20);
            flex-shrink: 0;
            margin-left: rem(10);
            margin-right: rem(-5);
            @include adjust-flex;
            cursor: pointer;
        }

        &_arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: rem(8);
            height: rem(8);
            transition: 0.3s;
            background: url('~assets/icons/ic_arrow_down_blue.svg') no-repeat center / contain;
        }

        &-menu {
            position: absolute;
            top: calc(100% + 20px);
            left: 0;
            width: rem(200);
            border: 1px solid $platinum1;
            transition: 0.3s;
            z-index: 10;
            list-style: none;
            background-color: $white;
            opacity: 0;
            visibility: hidden;
            border-radius: rem(8);
            max-height: rem(200);
            overflow: auto;
        }

        &-menu-item {
            padding: rem(10);
            @include font-base(16, 20);
            color: rgba($color: $raisin-black, $alpha: 0.5);
            @include mobile-down {
                @include font-base(14, 18);
            }

            &:hover {
                color: $vivid-cerulean;
            }
        }

        &-open {
            #{$container}_arrow {
                transform: translateY(-50%) rotate(180deg);
            }

            #{$container}-menu {
                top: calc(100% + 5px);
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
