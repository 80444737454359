.t-policyQuestion {
    border-radius: rem(10);
    padding: rem(33) rem(40) rem(54);
    background-color: $white;
    @include tablet-down {
        padding: rem(35) rem(35) rem(21);
    }
    @include mobile-down {
        padding: rem(34) rem(20) rem(27);
    }
}
