.a-textarea {
    $root: &;

    &_label {
        margin-bottom: rem(6);
    }

    &_inputele {
        border: 1px solid $sonic-silver05;
        border-radius: rem(25);
        color: $raisin-black;
        @include font-base(16, 20);
        margin: 0;
        outline: none;
        padding: rem(13) rem(20);
        transition: all $transition linear;
        resize: none;
        width: 100%;
        font-family: inherit;

        &::placeholder {
            color: $raisin-black07;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

    }

    &-secondary {
        position: relative;

        #{$root}_inputele {
            border-color: $sonic-silver025;
            border-radius: rem(999);
        }
        
        #{$root}_label {
            background: $white;
            position: absolute;
            width: fit-content;
            z-index: 1;
            top: rem(-15);
            left: rem(26);
            padding: rem(0) rem(4);

            .a-paragraph-text {
                @include font-base(16, 30);
                color: $raisin-black;
            }
        }
    }

    &-error {
        #{$root}_label {
            .a-paragraph-text {
                color: $electric-crimson;
            }
        }
        #{$root}_inputele {
            border-color: $electric-crimson !important;
            color: $electric-crimson;
        
            &::placeholder {
                color: $electric-crimson;
            }
        }
    }
}
