.t-cardComplex {
    &_divider {
        background: $cyan-cornflower;
        height: rem(1);
        width: 100%;
    }
}

.t-cardBasic {
    $root: &;
    @include adjust-flex();
    position: relative;
    cursor: pointer;
}
