.t-profileOrderDetail {
    @include adjust-flex(space-between, flex-start);

    @include max-desktop-down {
        display: block;
    }

    &_back {
        @include reset-button();
        background-color: transparent;
        padding: 0;
    }

    &_label {
        max-width: rem(167);
        min-width: rem(167);

        @include mobile-down {
            max-width: rem(98);
            min-width: rem(98); 
        }
    }

    &_orderStatus {
        @include adjust-flex(flex-start);
        border-radius: rem(10);
        background-color: $aero012;
        padding: rem(23) rem(28);
        margin-top: rem(40);

        @include mobile-down {
            margin-top: rem(17);
            padding: rem(22) rem(24);
        }
    }

    &_btnWrap {
        max-width: rem(217);
        width: 100%;

        @include desktop-down {
            max-width: rem(152);
        }
    }

    &_left {
        width: 100%;
    }

    &_right {
        margin-left: rem(116);
        min-width: rem(285);

        @include max-desktop-down {
            margin-top: rem(42);
            margin-left: 0;
        }

        @include tablet-down {
            margin-left: 0;
        } 
    }

    &_orderCard {
        align-items: center;
        display: flex;

        &:not(:last-child) {
            margin-bottom: rem(30);
        }

        @include mobile-down {
            &:not(:last-child) {
                margin-bottom: rem(16);
            }
        }

        &-img {
            max-width: rem(117);
            width: 100%;
            border-radius: rem(8);
            overflow: hidden;
            margin-right: rem(20);

            @include mobile-down {
                margin-right: rem(13);
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
        }
    }

    &_orderCardList {
        max-height: rem(360);
        margin-top: rem(40);
        overflow: auto;
        @include scroll-bars(4, $vivid-cerulean, $sonic-silver02, 100, 100, 0);
        @include mobile-down {
            margin-top: rem(16);
        }
    }

    .divider {
        width: 100%;
        border-top: 1px solid $raisin-black01;
    }
}
