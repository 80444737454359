.a-image {
    display: block;
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    transition: $transition-long ease;
    width: 100%;

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-inherit {
        img {
            object-fit: inherit;
        }
    }

    &-initial {
        img {
            object-fit: initial;
        }
    }

    &-contain {
        img {
            object-fit: contain;
        }
    }

    &-cover {
        img {
            object-fit: cover;
        }
    }

    &-1x1 {
        @include aspect-ratio(1, 1);
    }

    &-2x1 {
        @include aspect-ratio(2, 1);
    }

    &-3x4 {
        @include aspect-ratio(3, 4);
    }

    &-4x3 {
        @include aspect-ratio(4, 3);
    }

    &-16x9 {
        @include aspect-ratio(16, 9);
    }

    &-43x27 {
        @include aspect-ratio(43, 27);
    }

    &-116x44 {
        @include aspect-ratio(116, 44);
    }

    &-151x23 {
        @include aspect-ratio(151, 23);
    }

    &-158x24 {
        @include aspect-ratio(158, 24);
    }

    &-184x28 {
        @include aspect-ratio(184, 28);
    }

    &-246x364 {
        @include aspect-ratio(246, 364);
    }

    &-284x375 {
        @include aspect-ratio(284, 375);
    }

    &-631x599 {
        @include aspect-ratio(631, 599);
        @include mobile-down {
            @include aspect-ratio(346, 328);
        }
    }
    
    &-594x566 {
        @include aspect-ratio(594, 566);
    }

    &-661x566 {
        @include aspect-ratio(661, 566);
    }

    &-1600x635 {
        @include aspect-ratio(1600, 635);

        @include tablet-down {
            @include aspect-ratio(834, 635);
        }

        @include mobile-down {
            @include aspect-ratio(390, 737);
        }
    }

    &-852x674 {
        @include aspect-ratio(852, 674);
    }

    // collection summary
    &-631x599 {
        @include aspect-ratio(631, 599);
    }

    &-293x281 {
        @include aspect-ratio(293, 281);
    }

    &-375x400 {
        @include aspect-ratio(375, 400);
    }

    &-375x169 {
        @include aspect-ratio(375, 169);
    }

    &-271x360 {
        @include aspect-ratio(271, 360);
    }

    // ---------------------------
    &-780x489 {
        @include aspect-ratio(780, 489);

        @include tablet-down {
            @include aspect-ratio(668, 419);
        }

        @include mobile-down {
            @include aspect-ratio(346, 205);
        }
    }

    &-548x230 {
        @include aspect-ratio(548, 230);

        @include tablet-down {
            @include aspect-ratio(324, 136);
        }

        @include mobile-down {
            @include aspect-ratio(168, 238);
        }
    }

    &-316x288 {
        @include aspect-ratio(316, 288);
    }

    &-1424x320 {
        @include aspect-ratio(1424, 320);

        @include tablet-down {
            @include aspect-ratio(834, 275);
        }

        @include mobile-down {
            @include aspect-ratio(390, 320);
        }
    }

    &-432x189 {
        @include aspect-ratio(432, 189);
    }

    &-276x281 {
        @include aspect-ratio(276, 281);
    }

    &-389x591 {
        @include aspect-ratio(389, 591);
    }

    &-336x491 {
        @include aspect-ratio(336, 491);
    }
    
    &-95x128 {
        @include aspect-ratio(95, 128);
    }

    &-548x868 {
        @include aspect-ratio(548, 868);
    }

    &-664x714 {
        @include aspect-ratio(664, 714);
    }

    &-664x414 {
        @include aspect-ratio(664, 414);

        @include tablet-down {
            @include aspect-ratio(326, 427);
        }

        @include mobile-down {
            @include aspect-ratio(257, 160);
        }
    }

    &-170x225 {
        @include aspect-ratio(170, 225);
    }

    &-1600x525 {
        @include aspect-ratio(1600, 525);

        @include tablet-down {
            @include aspect-ratio(834, 457);
        }

        @include mobile-down {
            @include aspect-ratio(390, 259);
        }
    }

    &-1600x300 {
        @include aspect-ratio(1600, 300);

        @include tablet-down {
            @include aspect-ratio(834, 265);
        }

        @include mobile-down {
            @include aspect-ratio(390, 259);
        }
    }

    &-257x386 {
        @include aspect-ratio(257, 386);
    }

    &-591x740 {
        @include aspect-ratio(591, 740);
    }

    &-54x83 {
        @include aspect-ratio(54, 83);
    }

    &-751x683 {
        @include aspect-ratio(751, 683);
    }

    &-224x117 {
        @include aspect-ratio(224, 117);
    }

    &-432x224 {
        @include aspect-ratio(432, 224);
    }

    &-548x688 {
        @include aspect-ratio(548, 688);
    }

    &-673x540 {
        @include aspect-ratio(673, 540);
    }
    
    &-379x484 {
        @include aspect-ratio(379, 484);
    }
    
    &-534x469 {
        @include aspect-ratio(534, 469);
    }

    &-93x126 {
        @include aspect-ratio(93, 126);
    }

    &-135x184 {
        @include aspect-ratio(135, 184);
    }

    &-117x150 {
        @include aspect-ratio(117, 150);
    }
    
    &-348x518 {
        @include aspect-ratio(348, 518);
    }
    
    
    &-1192x510 {
        @include aspect-ratio(1192, 510);
        @include tablet-down {
            @include aspect-ratio(536, 229);
        }
        @include mobile-down {
            @include aspect-ratio(346, 192);
        }
    }

    &-64x84 {
        @include aspect-ratio(64, 84); 
    }

    &-140x114 {
        @include aspect-ratio(140, 114);
    }

    &-231x296 {
        @include aspect-ratio(231, 296);
    }
    
    &-743x570 {
        @include aspect-ratio(743, 570);
    }

    &-2x3 {
        @include aspect-ratio(2, 3);
    }
    
    &-54x80 {
        @include aspect-ratio(54, 80);
    }
}
