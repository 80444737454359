.o-container {
    padding-left: rem(16);
    padding-right: rem(16);

    @include break-min($standard-viewport) {
        max-width: $standard-viewport;
        padding-left: rem(120);
        padding-right: rem(120);
    }

    @include desktop-down {
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &-noPaddingRight {
        padding-right: 0;
    }

    &-noPaddingLeft {
        padding-left: 0;
    }

    &-fullscreen {
        padding-left: 0;
        padding-right: 0;
    }

    &-header {
        @include break-min($standard-viewport) {
            padding-left: rem(83);
            padding-right: rem(83);
        }
    }

    &-noPaddingRightTablet {
        @include tablet {
            padding-right: 0;
        }
    }

    &-noPaddingRightTabletDesktop {
        @include tablet-desktop {
            padding-right: 0;
        }
    }
}
