.t-collectioncombocards {
    position: relative;

    .container {
        position: relative;
        z-index: 2;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc(1048 /1600) * 100%;

        background-color: $aero061;
        z-index: 1;

        @include desktop-down {
            width: 100%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: rem(385);
        height: rem(330);
        background-image: url('~assets/images/comboCard/bg-top-left.png');
        background-position: rem(-52) rem(-32);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
        pointer-events: none;
    }
    
    padding-top: rem(54);
    padding-bottom: rem(73);
    margin-top: rem(-97);

    @include desktop-down {
        padding-top: rem(36);
        padding-bottom: rem(80);
        margin-top: rem(-79);
    }

    @include break-max(800px) {
        margin-top: rem(-184);
        padding-top: rem(200);
    }

    @include mobile-down {
        margin-top: rem(-116);
        padding-top: rem(158);
        padding-bottom: rem(67);
    }

    &_button {
        padding-left: rem(234);

        @include break(1366px, 1599px) {
            padding-left: rem(168);
        }

        @include break(1200px, 1365px) {
            padding-left: rem(160);
        }

        @include desktop-down {
            padding-left: 0;
        }

        .a-button {
            width: auto;
            border-radius: rem(25);
            width: rem(160);
            height: rem(43);
        }
    }

    &_cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: rem(70);
        padding-top: rem(30);
        max-width: rem(858);

        @include max-desktop-down {
            max-width: rem(802);
        }

        @include break-max(1366px) {
            max-width: rem(722);
            column-gap: rem(40);
        }

        @include desktop-down {
            max-width: 100%;
        }

        @include mobile-down {
            grid-template-columns: 1fr;
            column-gap: rem(0);
            padding-top: rem(4);

            .c-productCollection {
                &:not(:first-of-type) {
                    margin-top: rem(36);
                }
            }
        }

        .m-cardCollection {
            padding-top: rem(36);
        }
    }

    &-no-video {
        margin-top: rem(36);
        @include tablet-down {
            margin-top: rem(24);
        }
    }
}
