.t-productDetailInfo {
    &_code {
        &_separate {
            width: 1px;
            height: rem(15);
            background-color: $raisin-black07; 
        }
    }

    &_quantityText {
        color: $celadon-blue;
    }

    &_list {
        margin-left: rem(-16.5);
        margin-right: rem(-16.5);
        @include tablet-down {
            margin-left: rem(-10);
            margin-right: rem(-10);
        }
    }

    &_item {
        padding: 0 rem(16.5);
        @include tablet-down {
            padding: 0 rem(10);
        }

        .a-colorpicker_body {
            width: rem(36);
            height: rem(36);

            span {
                width: rem(30);
                height: rem(30);
            }
        }
    }

    &_favorite {
        width: rem(32);

        .a-icon {
            transition: $transition ease-in-out;
        }
    }

    &_divider {
        height: 1px;
        background-color: $raisin-black01; 
    }

    &_quantity {
        width: rem(104);
    }

    &_btnAddCart {
        position: relative;

        @include tablet-down {
            max-width: rem(174);
        }
        
        .a-button {
            &:hover {
                background-color: $vivid-cerulean;
            }

            &_wrapper {
                display: flex;
                align-items: center;
            }
        }
        

        img {
            margin-right: rem(12.5);
            @include tablet-down {
                display: none;
            }
        }
    }

    &_buyNow,
    &_addToCart {
        width: 100%;
        max-width: rem(290);
    }

    &_buyNow {
        @include mobile-down {
            max-width: 100%;
        }
    }

    &_addToCart {
        @include mobile-down {
            position: fixed;
            bottom: rem(60);
            left: 0;
            max-width: 100%;
            padding: rem(20) rem(24);
            background: $white;
            box-shadow: -3px 2px 6px rgba(0, 0, 0, 0.16);
            z-index: 10;
            transition: all 0.3s ease;
            height: auto;
            transform: scaleY(1);

            &.hidden {
                bottom: 0;
                transform: scaleY(0);
            }
        }
    }
}

.t-productDetailPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.16);
    z-index: 105;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(100%);
    transition: all 0.3s ease;
    @include mobile-up {
        display: none;
    }

    &-show {
        transform: translateY(0);
    }

    &_wrap {
        background-color: $white;
        border-radius: 12px 12px 0 0;
        padding: rem(21) rem(26);
        position: relative;
    }

    &_close {
        position: absolute;
        top: rem(16);
        right: rem(16);
    }

    &_content {
        display: flex;
        gap: rem(8);
    }

    &_thumbnail {
        max-width: rem(54);
        width: 100%;
    }

    &_info {
        flex: 1;

        > p {
            padding-right: rem(40);
        }
    }

    &_list {
        gap: rem(10);
    }

    &_addToCart {
        margin-top: rem(35);
    }
}

.t-productCategory {
    &_mobileCategory{
        @include mobile-up {
            display: none;
        }
    }

    &_desktopCategory{
        @include mobile-down {
            display: none;
        }
    }
}