.t-homeBanner {
    position: relative;

    .slick-dots {
        bottom: rem(65);
        left: 50%;
        transform: translateX(-50%);
        
        @include tablet-down {
            margin-left: 8%;
            left: 0;
            transform: translateX(0);
        }

        @include break-max(800px) {
            margin-left: 5%;
        }

        @include mobile-down {
            margin-left: 3%;
        }
    }
}
