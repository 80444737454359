.p-profileHistoryOrders {
    $root: &;

    &_panel {
        padding: rem(60) rem(60) rem(55);
        background-color: $white;
        border-radius: rem(10);

        @include desktop-down {
            padding: rem(44) rem(39) rem(47);
        }

        @include mobile-down {
            padding: rem(20) rem(16) rem(16);
        }
    }

    &_thumbnail {
        border-radius: rem(10);
        overflow: hidden;

        @include mobile-down {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    &_product {
        @include mobile-down {
            display: flex;
        }
    }

    &_content {
        @include mobile-down {
            flex: 1;
            margin-left: rem(14);
        }
    }

    &_textfield {
        display: flex;

        &-label {
            flex: 0 0 rem(110);
        }

        &-option {
            flex: 1;

            input,
            textarea {
                border-radius: rem(8) !important;
                border-color: $sonic-silver025 !important;
            }
        }

        &.button-desktop {
            @include break-max(1366px) {
                display: none;
            }
        }

        &-nameFile {
            @include text-overflow(1);
            word-break: break-word;
        }
    }

    &_row {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-12);
        margin-right: rem(-12);
    }

    &_col {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: rem(12);
        padding-right: rem(12);
        @include break-max(1366px) {
            flex: 0 0 100%;
            max-width: 100%;

            &:last-of-type {
                margin-top: rem(32);
            }
        }
    }

    &_detail {
        display: flex;
        align-items: center;

        .thumbnail {
            border-radius: rem(10);
            overflow: hidden;
            flex: 0 0 rem(257);
            
            @include desktop-down {
                flex: 0 0 rem(237);
            }

            @include mobile-down {
                flex: 0 0 rem(168);
            }
        }

        .content {
            flex: 1;
            margin-left: rem(29);

            @include mobile-down {
                margin-left: rem(10);
            }
        }

        .button-tablet {
            @include break-min(1440px) {
                display: none;
            }
            width: fit-content;
            min-width: rem(140);
        }
    }

    &_submit {
        width: fit-content;
        min-width: rem(140);
    }
    
}
