.a-buttonIcon {
    $root: &;
    @include reset-button(); 
    @include adjust-flex();
    background-color: transparent;

    .a-icon {
        flex-shrink: 0;
    }

    
    &_text {
        margin-left: rem(10);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: rem(-2);
            width: 0;
            border-bottom: rem(2) solid $vivid-cerulean;
            transition: all $transition ease-in-out;
        }
    }

    &:hover {
        .a-paragraph-text {
            color: $vivid-cerulean;
        }

        #{$root}_text::before {
            width: 100%;
        }
    }
}
