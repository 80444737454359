.t-showroomList {
    padding-top: rem(94);
    padding-bottom: rem(107);

    @include desktop-down {
        padding-top: rem(75);
        padding-bottom: rem(115);
    }

    @include mobile-down {
        padding-top: rem(56);
        padding-bottom: rem(122);
    }

    li {
        list-style: none;
    }

    &_cardShowroom {
        border-radius: rem(10);
        filter: drop-shadow(1 0 0 $black016);
        cursor: pointer;

        &_image {
            border-radius: rem(10) rem(10) 0 0;
            overflow: hidden;
        }

        &_inner {
            padding: rem(12) 0 0 0;

            @include desktop-up {
                padding: rem(20) rem(26) rem(25) rem(26);
            }
    
        }

        &_contact {
            margin-top: rem(16);
    
            &_item {
                @include adjust-flex(flex-start, center);

                &:not(:first-of-type) {
                    margin-top: rem(4);
                }
            }

            .a-icon {
                margin-right: rem(10);
            }
        }

    }
 
    &_carousel {
        margin-top: rem(30);
        @include tablet-down {
            margin-top: rem(0);
        }

        @include mobile-down {
            margin-top: rem(22);
        }

        .slick-list {
            margin-left: rem(-16);
            margin-right: rem(-16);

            @include mobile-down {
                margin-left: rem(-5);
                margin-right: rem(-5);
            }
        }
    }

    &_item {
        padding-left: rem(16);
        padding-right: rem(16);

        @include tablet-down {
            margin-top: rem(30);
        }

        @include mobile-down {
            padding: 0 rem(5);
            margin-top: 0;
        }
    }

    &_description {
        max-width: rem(664);
    }

    .o-carousel {
        &_arrow {
            width: rem(37);
            height: rem(37);

            &.slick-prev,
            &.slick-next {
                transform: none;
                top: rem(-67);

                @include tablet-down {
                    top: auto;
                    bottom: rem(-49);
                }

                @include mobile-down {
                    bottom: rem(-57);
                }
            }

            &_prev {
                left: auto;
                right: rem(47);
                
                @include tablet-down {
                    left: 0;
                    right: auto;
                }
            }

            &_next {
                right: 0;
                left: auto;
                @include tablet-down {
                    left: rem(47);
                    right: auto;
                }

                @include mobile-down {
                    left: rem(47);
                }
            }
        }
    }
}
