.p-forgotPassword {
    background-color: $linen !important;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    @include tablet-up {
        min-height: rem(620);
    }

    @include tablet-down {
        padding-bottom: 71%;
    }

    @include mobile-down {
        background-image: none !important;
    }

    &_content {
        max-width: 45%;

        @include tablet-down {
            max-width: 85%;
        }

        @include mobile-down {
            max-width: 100%;
            padding: rem(40) rem(21) rem(31);
            background-color: $white;
            border-radius: rem(10);
        }
    }

    .o-consultancy_form {
        .a-button {
            background-color: $cyan;
        }
    }
}

.p-forgotPassword_verify {
    &_otp {
        @include mobile-up {
            max-width: rem(294);
        }
    }

    &_btn {
        display: flex;
        align-items: center;
    }
    
    &_request {
        margin-left: rem(16);

        &.loading {
            pointer-events: none;
            opacity: 0.8;
        }
    }
}

.p-forgotPassword_success {
    &_icon {
        max-width: rem(48);
    }
    
    &_notify {
        @include mobile-down {
            max-width: rem(223);
        }
    }

    &_btn {
        width: fit-content;
        min-width: rem(224);
    }
}
