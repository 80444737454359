.t-profileShippingAddress {
    padding: rem(60) rem(60) rem(70);
    background-color: $white;
    border-radius: rem(10);

    @include desktop-down {
        padding: rem(36) rem(24) rem(68);
    }

    &_btnAdd {
        width: 100%;
        border-radius: rem(4);
        border: rem(1) solid $raisin-black02;
        padding-top: rem(50);
        padding-bottom: rem(50);
        margin-top: rem(62);
        background: $raisin-black004;

        .a-icon {
            margin: 0 auto rem(24);
        }

        @include mobile-down {
            margin-top: rem(32);
            padding-top: rem(36);
            padding-bottom: rem(36);
        }
    }

    &_btnDelete {
        @include reset-button;
        background-color: transparent;
        padding: rem(3);
        border-radius: 50%;
        border: 1px solid $raisin-black;
        position: absolute;
        top: rem(5);
        right: rem(5);
        z-index: 1;
    }

    &_infoAddress {
        position: relative;
        margin-top: rem(20);
        border-radius: rem(4);
        border: rem(1) solid $vivid-cerulean;
        background: $aero012;
        padding: rem(30) rem(30) rem(27);
        @include adjust-flex(space-between, flex-end);
        @include mobile-down {
            display: block;
            padding: rem(24);
        }
    }

    &_right {
        max-width: rem(271);
        width: 100%;
        @include tablet-down {
            max-width: rem(238);
        }

        @include mobile-down {
            margin-top: rem(18);
            max-width: rem(200);
        }
    }
}
