.m-productCard {
    $root: &;
    height: 100%;
    display: flex;
    flex-direction: column;

    &_thumbnail {
        border-radius: rem(10);
        overflow: hidden;
        position: relative;

        &:hover {
            #{$root}_addCart {
                opacity: 1;
                visibility: visible;
            }

            #{$root}_thumbnail-item-scale {
                .a-image {
                    transform: scale(1.3);
                }
            }
        }
    }

    &_content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &_listColor {
        flex: 1;
    }

    &_name {
        @include text-overflow(1);
    }

    &_iconFlashSale {
        position: absolute;
        top: rem(17);
        right: rem(11);
        cursor: pointer;

        .a-icon {
            width: rem(26);
            height: rem(34);
        }
    }

    &_iconFavorite {
        position: absolute;
        top: rem(12);
        right: rem(13);
        cursor: pointer;

        .a-icon {
            transition: all $transition ease-in-out;
        }
    }

    &_addCart {
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        transition: all $transition ease-in-out;

        @include desktop-down {
            opacity: 1;
            visibility: visible;
        }

        .a-icon {
            width: rem(39);
            height: rem(35);
        }
    }

    &_discountPercent {
        position: absolute;
        background-image: url('~assets/images/productCard/discountBg.png');
        background-size: contain;
        background-repeat: no-repeat;
        min-width: rem(218);
        min-height: rem(116);
        bottom: 0;
        right: 0;

        @include mobile-down {
            min-width: rem(132);
            min-height: rem(72);
        }

        p {
            position: absolute;
            right: rem(40);
            top: rem(50);

            @include mobile-down {
                right: rem(30);
                top: rem(31);
            }
        }

        div {
            position: absolute;
            right: rem(15);
            top: rem(71);
            display: inline-flex;

            @include mobile-down {
                right: rem(12);
                top: rem(45);
            }

            span {
                @include font-base(25, 25);

                @include tablet-down {
                    @include font-base(29, 29);
                }

                @include mobile-down {
                    @include font-base(18, 18); 
                }
            }
        }
    }

    &_tag {
        position: absolute;
        top: rem(15);
        left: 0;
        z-index: 1;

        @include mobile-down {
            left: rem(3);       
        }

        &_item {
            margin-bottom: rem(11);
            padding: rem(8);
            border-radius: 0 rem(12) rem(12) 0;
            width: fit-content;
            max-width: rem(70);

            @include mobile-down {
                border-radius: rem(12);
            }
        }
    }

    &_progress {
        &-track {
            width: 100%;
            height: rem(4);
            background-color: $raisin-black011;
            border-radius: rem(2);
            position: relative;
        }

        &-range {
            position: absolute;
            background: $dark-pink;
            height: 100%;
            border-radius: rem(2);
            transition: all $transition ease-in-out;            ;
        }
    }
}
