.t-commentList {
    &_list {
        @include scroll-bars;
        @include tablet-up {
            max-height: rem(542);
            overflow: auto;
            padding-right: rem(48);
        }

        @include desktop {
            padding-right: rem(20);
        }
    }

    &_item {
        @include tablet-up {
            padding-bottom: rem(50);
            border-bottom: 1px solid $raisin-black01;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &_pagination {
        @include tablet-down {
            border-top: 1px solid $raisin-black01;
            padding-top: rem(29);
        }
        margin-top: rem(33);
    }
}
