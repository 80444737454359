.t-newsBanner {
    $root: &;
    height: 100%;

    .o-carousel {
        &_arrow {
            &_prev {
                left: 0;
                @include tablet-down {
                    left: rem(-18);
                }
            }

            &_next {
                right: 0;
                @include tablet-down {
                    right: rem(-18);
                }
            }
        }
    }


    &_slider {
        max-width: 87.5%;
        margin-left: auto;
        margin-right: auto;
        @include desktop-down {
            max-width: 80%;
        }
        @include mobile-down {
            max-width: 100%;
        }

        &-img {
            border-radius: rem(32);
            overflow: hidden;
            @include tablet-down {
                border-radius: rem(16);
            }
        }
    }

    &_content {
        margin-top: rem(8);
        display: flex;
        flex-direction: column;
        gap: rem(8);
    }

    &_subDesc {
        display: flex;
        gap: rem(16);

        img {
            margin-right: rem(4);
            width: rem(13);
        }
    }

    &_date,
    &_views {
        @include adjust-flex();
    }

    &_icon,
    &_views {
        @include mobile-down {
            display: none;
        }
    }

    &_iconMobile,
    &_author {
        @include mobile-up {
            display: none;
        }
    }

    .slick-dots {
        margin: rem(24) auto 0;
        position: relative;
        bottom: 0;
        @include mobile-down {
            margin: rem(16) auto 0;
        }
    }
}
