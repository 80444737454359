.t-popupProduct {
    display: flex;
    flex-wrap: wrap;

    &_left {
        flex: 0 0 40%;
        max-width: 40%;
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_right {
        flex: 0 0 60%;
        padding-left: rem(16);
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0;
        }
    }

    .a-colorpicker_body {
        width: rem(36);
        height: rem(36);

        span {
            width: rem(30);
            height: rem(30);
        }
    }

    .t-productDetailSlider_main .a-image {
        padding-bottom: 186%;
        @include tablet-down {
            padding-bottom: 139%;
        }
    }   

    .favorite {
        .a-icon {
            transition: all $transition ease-in-out;
        }
    }
}

.o-popup-product {
    $root: '.o-popup';

    #{$root}_wrapper {
        max-width: rem(790);
        overflow: visible;
        padding: 0;
        @include tablet-down {
            max-width: rem(485);
        }

        @include mobile-down {
            max-height: calc(100vh - 20%);
        }
    }
    
    #{$root}_body {
        margin: 0;
        padding: rem(32);
        @include mobile-down {
            padding: rem(24);
        }
    }

    #{$root}_close {
        top: rem(8);
        right: rem(8);
    }
}
