.t-homeAdvertise {
    &_card {
        transition: 0.3s;

        &:hover {
            transform: scale(1.05);
        }
    }

    .slick-track {
        display: flex;
        justify-content: center;
    }

    .slick-slide {
        padding-left: rem(16);
        padding-right: rem(16);
        @include desktop-down {
            padding-left: rem(10);
            padding-right: rem(10);
        }

        @include mobile-down {
            padding: 0;
        }
    }
}
