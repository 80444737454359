.t-profileVoucher {
    background: $white;
    padding: rem(60) rem(24);
    border-radius: rem(10);
    width: 100%;
    overflow: hidden;

    @include desktop-down {
        padding: rem(36) rem(24) rem(68);
    }

    @include mobile-down {
        padding: rem(36) 0 rem(68);
    }

    &_title {
        margin-bottom: rem(48);
        padding: 0 rem(36);

        @include max-desktop-down {
            padding: 0 rem(16);
            margin-bottom: rem(20);
        }
    }

    &_voucherElement {
        @include adjust-flex(center, flex-start);
        padding: rem(15) rem(36);

        @include max-desktop-down {
            padding: rem(10) rem(16);
        }

        @include mobile-down {
            max-width: rem(325);
        }
    }
}
