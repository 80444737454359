// fonts
$font-path: '~assets/fonts/';
$font-neue-kabel: 'Neue Kabel';
// transition
$transition: 0.25s;
$transition-medium: 0.3s;
$transition-long: 0.4s;

// z-index
$z-header: 102;
$z-header-nav: 103;
$z-modal: 105;
$z-1: 1;
$z-2: 2;
$z-loading: 999;
$z-filter-search-mobile: 104;

// space
$height-header-desktop: 123;
$height-header-tablet-down: 123;
$height-header-mobile-down: 111;
$height-footer-desktop: 456;
$height-footer-tablet-down: 850;
$height-footer-mobile-down: 641;
$standard-viewport: 1600px;

$offsets: (top, bottom, left, right);
$breakpoints: (
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
);

// size screen
$width-container-value: 1362;

$breakpoints-size: (
    lg: '(max-width: 1199px)',
    md: '(max-width: 767px)',
);

