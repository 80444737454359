.t-policyFeedback {
    background: $white;
    border-radius: rem(10);

    &_top {
        padding: rem(33) rem(40) 0 rem(45);
        margin-bottom: rem(52);

        @include tablet-down {
            padding: rem(33) rem(35) 0;
        }

        @include mobile-down {
            padding: rem(33) rem(21) 0;
            margin-bottom: rem(40);
        }
    }

    &_bottom {
        @include adjust-flex(space-between, flex-end);
    }

    &_form {
        flex: 0 0 calc(100% * 2/3);
        padding-right: rem(24);
        padding-left: rem(45);
        padding-bottom: rem(41);

        @include mobile-down {
            flex: 0 0 100%;
            padding-right: rem(21);
            padding-left: rem(21);
            padding-bottom: rem(21);
        }
    }

    &_img {
        flex: 0 0 calc(100% * 1/3);

        @include mobile-down {
            display: none;
        }
    }

    &_formWrap {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-16);
        margin-right: rem(-16);
    }

    &_field {
        padding-left: rem(16);
        padding-right: rem(16);

        &:not(:first-child) {
            margin-top: rem(19);
        }
    }

    &_field12 {
        flex: 0 0 100%;
    }

    &_field6 {
        flex: 0 0 50%;

        @include tablet-down {
            flex: 0 0 100%;
        }
    }

    &_general {
        @include adjust-flex(space-between, center);

        &_button {
            width: fit-content;
        }
    }

    .m-pulldown {
        &_container {
            border-color: rgba($sonic-silver, 0.5);
        }
    }

    .error-pulldown {
        .m-pulldown_container {
            border-color: $electric-crimson;
        }
    }
}
