.t-homeNews {
    &_inner {
        @include tablet-up {
            display: flex;
            flex-wrap: wrap;
        }
    }
  
    &_left {
        @include tablet-up {
            flex: 0 0 calc(780 / #{$width-container-value} * 100%);
            max-width: calc(780 / #{$width-container-value} * 100%);
        }
    }
    
    &_right {
        @include tablet-up {
            flex: 0 0 calc(100% - (780 / #{$width-container-value} * 100%));
            max-width: calc(100% - (780 / #{$width-container-value} * 100%));
            padding-left: rem(32);
        }

        @include tablet-down {
            @include adjust-flex();
        }
    }

    &_item {
        & + & {
            @include tablet-up {
                margin-top: rem(30);
            }
        }

        @include tablet-down {
            margin-top: rem(23);
            width: 100%;
            height: 100%;

            &:last-child {
                margin-left: rem(20);
            }
        }

        @include mobile-down {
            margin-top: rem(30);
            width: 100%;
            height: 100%;

            &:last-child {
                margin-left: rem(10);
            }
        }
    }

    &_newsCard {
        .a-image {
            border-radius: rem(10);
        }
    }
}
