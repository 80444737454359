.t-popupVoucherCustomer {
    &_list {
        // margin: rem(-9) rem(-12);
        display: flex;
        flex-wrap: wrap;
        max-height: rem(450);
        overflow-y: auto;
        @include scroll-bars(8, $vivid-cerulean, $sonic-silver02, 100, 100, 16);

        &-item {
            padding: rem(9) rem(12);
            flex: 0 0 50%;

            @include tablet-down {
                flex: 0 0 100%;
            }
        }
    }
}
