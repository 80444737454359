.a-quantity {
    $root: &;
    border: 1px solid $raisin-black043;
    border-radius: rem(24);
    @include adjust-flex(space-between, stretch);
    overflow: hidden;

    &-disabled {
        pointer-events: none;
    }

    &-error {
        border-color: $electric-crimson;
    }

    &_decrease,
    &_increase {
        flex: 1;
        border-radius: rem(24) 0 0 rem(24);
        position: relative;
        cursor: pointer;
        transition: 0.3s;
        width: rem(32);
        flex-shrink: 0;

        &:hover {
            background-color: $vivid-cerulean;

            #{$root}_horizontal {
                background-color: $white;
            }

            #{$root}_vertical {
                background-color: $white;
            }

            #{$root}-error & {
                background-color: $electric-crimson;
            }
        }
    }

    &_increase {
        border-radius: 0 rem(24) rem(24) 0;
        #{$root}-error & {
            cursor: not-allowed;
        }
    }

    &_quantity {
        flex: 1;
        padding: rem(11) rem(4);
        @include adjust-flex();
        
        #{$root}-error & {
            color: $electric-crimson;
        }
    }

    &_horizontal {
        width: rem(10);
        height: rem(2);
        background: $raisin-black043;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
        flex-shrink: 0;
        #{$root}-error & {
            background: $electric-crimson;
        }
    }

    &_vertical {
        width: rem(2);
        height: rem(10);
        background: $raisin-black043;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
        flex-shrink: 0;
        #{$root}-error & {
            background: $electric-crimson;
        }
    }
}
