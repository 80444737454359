.t-productFilter {
    $root: &;
    width: 100%;

    @include desktop-down {
        position: fixed;
        background: $raisin-black05;
        width: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
        transition: all $transition ease-in-out;
        z-index: 2;
    }

    &-open {
        opacity: 1;
        visibility: visible;
        z-index: $z-modal;
        #{$root}_content {
            transform: translateX(0);
        }
    }

    &_content {
        @include desktop-down {
            max-width: 40%;
            overflow-y: auto;
            height: 100vh;
            padding: rem(32) rem(16);
            background: $white;
            transform: translateX(-100%);
            transition: all $transition ease-in-out;
        }


        @include mobile-down {
            max-width: 70%;
        }

        .a-multiInputRange_head {
            margin-top: rem(22);
        }
    }

    &_collapseFilter {
        $collapse: &;

        &-head {
            cursor: pointer;

            .a-icon {
                transition: all $transition ease-in-out;
            }

            &-open {
                .a-icon {
                    transform: rotate(-180deg);
                }
            }
        }

        &-option {
            transition: all $transition ease-in-out;
            max-height: 0;
            opacity: 0;
            visibility: hidden;

            &-open {
                padding-top: rem(10);
                opacity: 1;
                visibility: visible;
                transition: all $transition ease-in-out;
                max-height: 500px;
            }
        }

        &-item {
            cursor: pointer;

            .a-paragraph-text {
                position: relative;

                &::before {
                    content: '';
                    width: 0;
                    position: absolute;
                    border-bottom: rem(1) solid $vivid-cerulean;
                    background: $vivid-cerulean;
                    transition: all $transition-long ease;
                    bottom: 0;
                }
            }

            &-active,
            &:hover {
                .a-paragraph-text {
                    color: $vivid-cerulean;
                    position: relative;

                    &::before {
                        content: '';
                        width: 100%;
                        position: absolute;
                        border-bottom: rem(1) solid $vivid-cerulean;
                        background: $vivid-cerulean;
                    }
                }
            }
        }
    }

    &_category {
        &_panel {
            transition: all $transition ease-in-out;
            max-height: 0;
            overflow: hidden;
            
            &.open {
                max-height: unset;
            }
        }

        &_list {    
            @include desktop-up {
                max-height: rem(330);
                padding-right: rem(6);
                overflow-y: auto;
                @include scroll-bars(4, $vivid-cerulean, $sonic-silver02, 100, 100, 0);
            }
        }
    }

}
