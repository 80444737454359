.c-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        > div {
            overflow: hidden;
            background-color: $snow;
            flex-grow: 1;
            min-height: 50vh;
            position: relative;
        }
    }
}
