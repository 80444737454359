.t-footer {
    background-color: #d8eef1;
    padding: rem(43) 0 rem(23);
    position: relative;


    &_logo {
        max-width: rem(158);

        img {
            width: 100%;
            height: auto;
        }
    }

    &_description {
        margin-top: rem(28);
        margin-bottom: rem(30);

        @include desktop-down {
            margin-top: 0;
            margin-bottom: 0;
        }

        @include mobile-down {
            margin-top: rem(18);
            margin-bottom: rem(16);
        }
    }

    .tablet {
        @include desktop-up {
            display: none;
        }

        @include desktop-down {
            display: block;
        }

        @include mobile-down {
            display: none;
        }
    }

    .desktop {
        @include desktop-up {
            display: block;
        }

        @include desktop-down {
            display: none;
        }
    }

    .mobile {
        @include mobile-down {
            display: block;
        }
    }

    &_link:hover {
        .a-paragraph-text {
            color: $vivid-cerulean;
        }
    }

    @include tablet-up() {
        padding: rem(48) 0 rem(12);


    }

    @include desktop-up() {
        padding: rem(63) 0 rem(12);
    }

    &_textWrapper {
        margin-bottom: rem(31);
    }

    &_social {
        display: flex;
        flex-wrap: wrap;
    }

    &_socialLink {
        width: rem(36);
        height: rem(36);
        margin-right: rem(14);
        @include adjust-flex(center, center);
    }

    &_partList {
        list-style: none;
    }

    &_paymentList {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-right: rem(-12);
    }

    &_paymentItem {
        width: rem(44);
        margin-bottom: rem(10);

        &:not(:last-child) {
            margin-right: rem(4);
        }
    }

    &_register {
        position: relative;
    }

    &_registerHandler {
        border-radius: rem(500);
        border: 1px solid rgba(0, 0, 0, 0.36);
        background-color: #fff;
        position: relative;
        overflow: hidden;
        padding: rem(2);
        @include adjust-flex(flex-start, stretch);

        input {
            border: 0;
            @include font-base(14, 20);
            width: 100%;
            padding: rem(11) rem(5) rem(11) rem(22);
            font-family: inherit;

            &:focus {
                outline: none;
            }

            &:placeholder-shown {
                color: rgb(37, 36, 37);
            }
        }

        button {
            font-family: inherit;
            padding: rem(12.5) rem(20);
            border-radius: rem(500);
            background-color: #000;
            color: #fff;
            border: 0;
            text-transform: uppercase;
            min-width: rem(128);
            @include adjust-flex(center, center);
            @include font-base(14, 17);
        }

        &-error {
            border-color: $electric-crimson;
        }
    }

    &_divider {
        border-top: rem(1) solid rgba(37, 36, 37, 0.3);
        margin-top: rem(48);
        margin-bottom: rem(17);

        @include tablet-up() {
            margin-top: rem(46);
            margin-bottom: rem(7);
        }

        @include desktop-up() {
            margin-bottom: rem(14);
        }
    }

    &_bottomInfo {
        justify-content: space-between;
        align-items: center;
    }

    &_copyright {
        margin-bottom: rem(16);

        @include mobile-up() {
            margin-bottom: 0;
        }
    }

    &_boCongThuong {
        width: rem(116);
    }
}
