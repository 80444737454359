@import './SliderImages';
@import './Info';

.t-productDetailItem {
    &_wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-25);
    }

    &_left {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(25);
        @include desktop {
            padding: 0 rem(15);
        }
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_right {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(25);
        @include desktop {
            padding: 0 rem(15);
        }
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
