.t-salevoucher {
    overflow: hidden;
    position: relative;

    &_listVoucher {
        .slick-list {
            margin: rem(-15) rem(-20.5);

            @include mobile-down {
                margin-left: rem(-15);
                margin-right: rem(-15);
            }
        }

        .slick-track {
            min-width: 100% !important;
            display: flex;
            justify-content: center;
        }

        .o-carousel_arrow {
            height: rem(35);
            width: rem(35);

            &::before {
                background-size: rem(12);
            }
        }
    }

    &_voucher {
        padding: rem(15) rem(20.5);

        @include mobile-down {
            padding-left: rem(15);
            padding-right: rem(15);
        }

        .m-voucher {
            &_wrapper {
                @include mobile-up {
                    width: 100%;
                }
            }
        }

    }

    &_listAds {
        .slick-list {
            margin: rem(-16);
        }

        @include desktop-down {
            display: none;
        }
    }

    &_ads {
        padding: rem(16);
        transition: 0.3s;

        &:hover {
            transform: scale(1.05);
        }
    }
}
