.a-sizePicker {
    $root: &;

    &-secondary {
        #{$root} {
            &_body {
                border-radius: rem(12);
                width: rem(60);
                border: 0;
                background-color: $white;
            }

            &_title {
                font-weight: normal;
                color: $raisin-black07;
            }
        }

        #{$root}_input:hover:not(:disabled) +  #{$root}_body {
            background-color: $vivid-cerulean;
        }

        #{$root}_input:hover:not(:disabled) +  #{$root}_body > #{$root}_title {
            color: $white;
        }

        #{$root}_input:checked:not(:disabled) +  #{$root}_body {
            background-color: $vivid-cerulean;
        }
    }
  
    position: relative;
    display: inline-block;
      
    &_label {
        display: flex;
        align-items: center;
        margin: 0;
    }
      
    &_body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(36);
        height: rem(36);
        border: solid 1px $raisin-black05;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        overflow: hidden;
      
        #{$root}_input:disabled + & {
            pointer-events: none;
            opacity: 0.3;
        }

        #{$root}_input:checked:not(:disabled) + & {
            background-color: $vivid-cerulean;
            border-color: $vivid-cerulean;
        }

        #{$root}_input:hover:not(:disabled) + & {
            border-color: $vivid-cerulean;
        }
    }
      
 
    &_title {
        @include font-base(17, 20);
        font-weight: 500;
        vertical-align: middle;
        color: $raisin-black;
        
        #{$root}_input:checked + #{$root}_body > & {
            color: $white;
        }
    }
    
}


