@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-BoldItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Bold.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-ExtraLight.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Black.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-BlackItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-ExtraBold.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-ThinItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Regular.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Light.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Thin.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-LightItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Medium.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-ExtraLightItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-MediumItalic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-neue-kabel;
    src: url('#{$font-path}NeueKabel-Italic.woff2') format('woff2'),
        url('#{$font-path}NeueKabel-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

