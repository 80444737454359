.p-recruitment {
    &_banner {
        &_slice {
            position: relative;
        }

        .slick-dots {
            bottom: rem(16);
            left: 50%;
            transform: translateX(-50%);    
        }

        &_content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: rem(432);
            margin-top: rem(-12);

            @include mobile-down {
                max-width: rem(346);
            }
        }

        &_action {
            @include adjust-flex;
            gap: rem(10);
            margin-top: rem(12);
    
            @include mobile-down {
                margin-top: rem(38);
                gap: rem(16);
            }

            .button-1 .a-button {
                background-color: $white02;
                border: 1px solid $white;
            }

            .button-2 .a-button {
                background-color: $celadon-blue;
            }
        }
    }

    .t-aboutUsSummary {
        padding: rem(100) 0 rem(188);
        @include tablet-down {
            padding: rem(88) 0;
        }
        @include mobile-down {
            padding: rem(41) 0 rem(64);
        }
    }

    .t-aboutUsHistory {
        padding: rem(108) 0 rem(87);

        @include tablet-down {
            padding: rem(59) 0 rem(69);
        }

        @include mobile-down {
            padding: rem(41) 0;
        }

        &_title {
            .a-paragraph-heading {
                text-align: center;
            }
        }
    }


    &_jobFinder {

        padding-top: rem(100);
        padding-bottom: rem(100);

        @include tablet-down {
            padding-top: rem(80);
            padding-bottom: rem(80);
        }

        @include mobile-down {
            padding-top: rem(60);
            padding-bottom: rem(60);
        }

        &_tabContainer {
            @include adjust-flex();

            .a-button {
                width: max-content;
                margin-right: rem(24);

                &:last-child {
                    margin-right: 0;
                }
            }

            @include mobile-down {
                flex-direction: column;

                .a-button {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: rem(8);
                    

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &_filter {
            display: flex;
            margin-top: rem(24);
            @include mobile-down {
                margin-top: rem(16);
            }
        }

        &_filterItem {
            width: 100%;
            max-width: rem(172);

            &:not(:last-of-type) {
                margin-right: rem(16);
            }
        }

        &_jobList {
            .m-jobItem {
                margin-top: rem(20);
            }

            @include desktop-down {
                @include adjust-flex(flex-start, stretch);
                flex-wrap: wrap;
                
                .m-jobItem {
                    padding: rem(32) rem(16);
                    margin-top: 0;
                    width: 50%;

                    &:not(:nth-last-child(-n + 2)) {
                        border-bottom: 1px solid $sonic-silver01;
                    }
                }
            }

            @include mobile-down {
                .m-jobItem {
                    width: 100%;
                    border-bottom-width: 0 !important;
                }
            }
        }

        &_paginate {
            margin-top: rem(24);

            @include mobile-down {
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 2px;
                    margin-bottom: rem(24);
                    background-color: $sonic-silver01;
                }
            }
        }
    }

}
