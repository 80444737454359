.a-input {
    $root: &;
    position: relative;

    &_label {
        margin-bottom: rem(6);
        @include font-base(14, 18);

        &-required {
            color: $electric-crimson;
            margin-left: rem(4);
            font-size: rem(14);
        }
    }

    &_wrap {
        position: relative;
    }

    &_input {
        appearance: none;
        margin: 0;
        outline: none;
        transition: all $transition linear;
        width: 100%;
        color: $raisin-black;
        font-family: inherit;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &-primary {
        #{$root}_input {
            border: 1px solid $sonic-silver05;
            border-radius: rem(999);
            padding: rem(14) rem(20);
            @include font-base(16, 20);

            &::placeholder {
                @include font-base(16, 20);
                color: $raisin-black07;
            }
        }
    }

    &-secondary {
        #{$root}_input {
            border: 1px solid $sonic-silver025;
            border-radius: rem(46);
            padding: rem(12) rem(16);
            @include font-base(14, 20);

            &::placeholder {
                @include font-base(14 , 20);
                color: $dark-gray;
            }
        }

        #{$root}_label {
            background: $white;
            position: absolute;
            width: fit-content;
            z-index: 1;
            top: rem(-15);
            left: rem(26);
            padding: rem(0) rem(4);

            .a-paragraph-text {
                @include font-base(16, 30);
                color: $raisin-black;
            }
        }
    }
 

    &-password {
        #{$root}_input {
            padding-right: rem(72);
        }
    }

    &-error { 
        
        #{$root}_input {
            border-color: $electric-crimson !important;
        
            &::placeholder {
                color: $electric-crimson;
            }
        }
    }

    &_messageError {
        margin-top: rem(4);
    }

    &_showPassword {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: rem(31);
        border: 0;
        background: transparent;
        display: flex;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
