.t-profileMenu {
    $root: &;

    &_infoUser {
        width: 100%;
        @include adjust-flex(center, flex-start);
        flex-direction: column;
        margin-bottom: rem(28);

        @include desktop-down {
            margin-bottom: rem(30);
        }

        @include mobile-down {
            margin-bottom: rem(40);
        }
    }

    &_info {
        @include adjust-flex();
        gap: rem(18);
    }

    &_avatar {
        width: rem(80);
        height: rem(80);
        border-radius: 50%;
        border: rem(3) solid $platinum;
        overflow: hidden;
        background-color: $white;
    }

    &_username {
        margin-top: rem(6);
        margin-left: rem(12);
    }

    &_point {
        margin-top: rem(15);
    }

    &_list {
        @include list-style-none();

        li {
            margin-top: rem(15);

            &:first-of-type {
                margin-top: 0;
            }
        }
    }

    &_item {
        display: flex;
        align-items: center;
        gap: rem(10);
        padding: rem(10) rem(16);
        background-color: $white;
        border-radius: rem(10);
        transition: $transition;

        &-icon {
            width: rem(24);
            height: rem(24);
        }
    }

    &_link {
        cursor: pointer;

        &.active,
        &:hover {
            #{$root}_item {
                background-color: $vivid-cerulean;

                .a-paragraph-text {
                    color: $white;
                }
        
                &-icon {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &_wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &_left {
        width: rem(285);
        @include desktop-down {
            width: 100%;
        }
    }

    &_right {
        // flex: 1;
        width: calc(100% - rem(285));
        padding-left: rem(41);
        @include desktop-down {
            width: 100%;
            padding-left: 0;
            display: flex;
            #{$root}_sidebarTablet {
                padding-right: rem(20);

                li {
                    margin-top: 0;
                }

                .a-paragraph-text {
                    display: none;
                }

                #{$root}_list {
                    border-radius: rem(10);
                    background-color: $white;
                    padding: rem(6);
                }

                #{$root}_item {
                    border-radius: 0;
                    padding: rem(15);
                }

                #{$root}_link {
                    &:hover,
                    &.active {
                        #{$root}_item {
                            background-color: $aero012;
                            border-radius: rem(10);
                        }

                        .a-icon {
                            filter: none;
                        }
                    }
                }
            }
        }
    }

    &_content {
        @include desktop-down {
            width: calc(100% - rem(86));
        }
        @include mobile-down {
            margin-top: rem(42);
            width: 100%;
        }
    }
}
