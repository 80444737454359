.p-productDetail {
    padding: rem(30) 0  rem(100);

    @include tablet-down {
        padding: rem(39) 0  rem(72);
    }

    @include mobile-down {
        padding-top: rem(20);
    }

    &_wrap {
        @include tablet-down {
            .o-container {
                max-width: 100%;
                padding: 0;
            }
        }
    }

    &_tab {
        display: flex;
        margin: 0 rem(-9) rem(44);

        @include mobile-down {
            margin-bottom: rem(25);
        }

        &_item {
            width: 100%;
            max-width: rem(360);
            padding: 0 rem(9);

            @include tablet-down {
                max-width: 100%;
            }
        }
    }

    &_inner {
        background-color: $white;
        border-radius: rem(10);
        padding: rem(66);
        padding-bottom: rem(33);

        @include desktop-down {
            padding: rem(52) rem(83) rem(60);
        }

        @include mobile-down {
            padding: rem(34) rem(22) rem(70);
        }
    }

    &_shipping {
        ul {
            padding: rem(18);
            list-style-type: circle;
        }
    }

    &_content {
        img,
        video {
            max-width: 100%;
            height: auto !important;
        }

        ul,
        ol {
            margin: revert;
            padding: revert;
        }
    }

}

.pswp .pswp__img {
    object-fit: contain;
}
