.t-changePasswordForm {
    background: $white;
    padding: rem(60);
    border-radius: rem(10);
    
    @include desktop-down {
        padding: rem(44) rem(40);
    } 
    @include mobile-down {
        padding: rem(16) rem(16) rem(46) rem(16);
    }

    &_description {
        opacity: 0.5;
    }

    &_buttonGroup {
        @include adjust-flex(flex-end, center);

        margin-top: rem(24);

        @include mobile-down {
            justify-content: center;
        }

        > .a-button {
            max-width: rem(132);
            margin-left: rem(24);

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
