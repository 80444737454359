.p-login {
    background-color: $linen !important;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    padding-top: rem(53);
    padding-bottom: rem(41);

    @include tablet-down {
        padding-bottom: 75%;
    }

    @include mobile-down {
        background-image: none !important;
        padding-top: rem(40);
        padding-bottom: rem(90);
    }

    &_title {
        position: absolute;
        top: 15%;
        left: 53%;

        @include max-desktop-down {
            left: 48%;
        }
    }

    &_content {
        max-width: rem(462);
        padding: rem(36) rem(32);
        background-color: $white;
        border-radius: rem(10);

        @include tablet-down {
            padding: rem(28) rem(32);
            max-width: 100%;
        }

        @include mobile-down {
            padding: rem(28) rem(20);
        }
    }

    &_loginForm {
        &_submitSession {
            @include small-mobile {
                flex-direction: column;
            }
        }

        &_forgotPass {
            @include small-mobile {
                margin-top: rem(15);
            }
        }   

        &_submitButton {
            min-width: rem(180);
            @include small-mobile {
                width: 100%;
            }
        }

        &_authFlex {
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-12);
            margin-right: rem(-12);
            @include mobile-down {
                margin-left: rem(-24);
                margin-right: rem(-24);
            }
        }

        &_authCol {
            flex: 0 0 50%;
            padding-left: rem(12);
            padding-right: rem(12);
            @include mobile-down {
                padding-left: rem(24);
                padding-right: rem(24);
            }
        }

        &_authButton {
            @include adjust-flex();
            cursor: pointer;
            background-color: rgba($raisin-black, 0.02);
            border-radius: rem(4);
            padding: rem(6) rem(10);

            .a-image {
                width: rem(24);
                height: rem(24);
            }
        }

        &_divider {
            position: relative;
            display: flex;
            justify-content: center;
        
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 1px;
                z-index: 1;
                pointer-events: none;
                background-color: $raisin-black02;
                width: 100%;
            }

            .a-paragraph-text {
                position: relative;
                z-index: 2;
                padding: 0 rem(20);
                background-color: $white;
            }
        }
    }


}
