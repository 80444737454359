.m-accordion {
    $root: &;

    &_header {
        @include adjust-flex(space-between, flex-start);
        cursor: pointer;
        border-bottom: rem(1) solid $raisin-black01;
    }

    &_icon {
        transition: transform $transition ease-in-out;
        margin-left: rem(55);
    }

    &_body {
        overflow: hidden;
        transition: all $transition ease-in-out;
    }

    &-open {
        #{$root}_icon {
            transform: rotate(180deg);
        }
    }
}
