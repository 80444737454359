.o-commentItem {
    &_top,
    &_wrapInfo {
        display: flex;

        > * {
            margin-right: rem(40);
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border-right: 1px solid $sonic-silver025;
                height: 70%;
                right: rem(-20);
            }

            @include tablet-down {
                margin-right: rem(16);

                &::after {
                    display: none;
                }
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }

            @include mobile-down {
                margin-right: rem(20);

                &::after {
                    right: rem(-10);
                }
            }
        }
    }

    &_wrapInfo {
        @include tablet-down {
            flex-direction: column;
        }
    }

    &_wrapImage {
        @include adjust-flex;

        .a-image {
            border-radius: 50%;
        }
    }
    
    &_imageInner {
        width: rem(60);
        height: rem(60);
        background: $platinum;
        padding: rem(4);
        border-radius: 50%;

    }

    &_wrapDate {
        margin-top: rem(8);

        @include tablet-down {
            margin-top: rem(4);

            .a-paragraph-text {
                @include font-base(14,20);
            }
        }
    }

    &_wrapName {
        .a-paragraph-text {
            @include tablet-down {
                @include font-base(16, 20);
            }
        }
    }

    &_content {
        margin-top: rem(30);

        @include tablet-down {
            margin-top: rem(20);
        }
    }

    &_rating {
        margin-top: rem(12);

        @include tablet-down {
            margin-top: rem(8);
        }
    }
}
