.t-profileOrder {
    &_info {
        @include max-desktop {
            display: flex;
        }
    }

    &_infoRight {
        margin-top: rem(4);

        @include max-desktop {
            padding-left: rem(16);
            margin-top: 0;
        }
    }

    &_infoDetail {
        margin-top: rem(10);

        @include max-desktop {
            margin-top: rem(22);
        }
    }

    &_table {
        width: 100%;
        border-collapse: collapse;

        &_header,
        &_body {
            &_row {
                > :last-child {
                    text-align: end;
                }

                > :nth-last-child(2) {
                    @include tablet-down {
                        text-align: start;
                    }
                }
            }

            &_cell {
                &:not(:last-child) {
                    padding-right: rem(14);
                }
            }
        }

        &_header {
            border-bottom: 1px solid $raisin-black008;

            &_cell {
                padding-bottom: rem(20.5);

                @include max-desktop {
                    width: 100%;
                }

                &:nth-child(2) {
                    min-width: rem(150);
                }

                &:nth-child(3) {
                    min-width: rem(95);
                }

                &:last-child {
                    min-width: rem(150);
                }
            }
        }

        &_body {
            &_row {
                cursor: pointer;
                transition: $transition linear;

                &:not(:last-child) {
                    border-bottom: 1px solid $raisin-black01;
                }

                &:hover {
                    background-color: $raisin-black01;
                }
            }

            &_cell {
                padding-top: rem(19.5);
                padding-bottom: rem(25.5);
                vertical-align: top;
            }
        }
    }

    &_orderWrap {
        &-img {
            flex-grow: 1;
            flex-shrink: 0;
            max-width: rem(93);
            width: 100%;
            border-radius: rem(8);
            overflow: hidden;
            align-self: flex-start;
        }
    }

    &_listWrapper {
        margin-top: rem(9);
    }

    &_orderCard {
        display: flex;
        cursor: pointer;

        &:not(:last-child) {
            padding-bottom: rem(16);
            border-bottom: 1px solid $raisin-black01;
        }

        &:not(:first-child) {
            padding-top: rem(16);
        }

        &-img {
            align-self: flex-start;
            flex-shrink: 0;
            max-width: rem(135);
            width: 100%;
            border-radius: rem(8);
            overflow: hidden;
            margin-right: rem(8);
        }
    }
}
