.t-checkoutType {
   
    border-radius: rem(10);
    background-color: $white;
    padding-left: rem(65);
    padding-right: rem(65);
    padding-bottom: rem(41);
    padding-top: rem(28);
    @include tablet-down {
        padding-left: rem(37);
        padding-right: rem(37);
    }  
    @include mobile-down {
        padding-left: rem(22);
        padding-right: rem(22);
        padding-bottom: rem(37);
        padding-top: rem(34);
    }  

    &_list {
        margin-top: rem(-25);
        margin-bottom: rem(-25);

        &-item {
            border-top: rem(1) solid $raisin-black011;

            &:first-child {
                border: 0;
            }
        }
    }

    &_thumbnail {
        img {
            max-width: rem(50);
            height: rem(32);
        }
    }

    .a-image {
        min-width: rem(32);
        max-width: rem(43);
        width: 100%;
    }


   
}
