.p-pageDefault {
    img,
    video {
        max-width: 100%;
        height: auto !important;
    }

    ul,
    ol {
        padding: revert;
        margin: revert;
    }

    .divider {
        padding-top: rem(16);
        padding-bottom: rem(16);
        border-bottom: 1px solid $raisin-black02;
    }

    .contact {
        display: flex;
        flex-wrap: wrap;
        // margin-left: rem(-24);
        // margin-right: rem(-24);
        padding-top: rem(32);
        padding-bottom: rem(32);
        width: 100%;

        &-item {
            flex: 0 0 (100% / 3);
            // padding-left: rem(24);
            // padding-right: rem(24);

            @include mobile-down {
                flex: 0 0 100%;
                padding-right: 0;
                padding-left: 0;

                &:not(:first-of-type) {
                    margin-top: rem(24);
                }
            }
        }
    }
}
