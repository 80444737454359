.m-otpInput {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: rem(24);

    &_single {
        input {
            width: 100%;
            outline: none;
            color: $raisin-black;
            font-family: inherit;
            @include font-base(24,29);
            border: 0;
            border-bottom: 1px solid $raisin-black07;
            padding: rem(5);
            text-align: center;
            background-color: transparent;
        }
    }
}
