.p-homeCollectionFirst {
    overflow: hidden;
    position: relative;

    > .a-image {
        padding-bottom: 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &_layerMobile {
        max-width: 90%;
        margin-left: auto;
    }

    &_top {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-20);
        position: relative;
        z-index: 2;

        @include desktop-down {
            margin: 0 rem(-10);
        }
            
        @include small-mobile {
            display: none;
        }
    }

    &_left {
        flex: 0 0 32.2%;
        max-width: 32.2%;
        padding: 0 rem(20);
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        @include desktop-down {
            order: 2;
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            padding-left: 0;
            padding-right: 0;
        }

        @include mobile-down {
            padding-top: rem(18);
        }

    }

    &_right {
        flex: 0 0 67.8%;
        max-width: 67.8%;
        padding: 0 rem(20);
        @include desktop-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
        
        &_wrap {
            position: relative;
        }

        &_image {
            max-width: 66%;
            margin-left: auto;
            @include mobile-down {
                max-width: 100%;
            }
        }
    }

    &_wrapCardSub {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 rem(-14);
    }

    &_cardSub {
        padding: 0 rem(14);
        flex: 0 0 100%;
        max-width: 100%;

        &:not(:first-of-type) {
            @include tablet-up {
                margin-top: rem(31);
            }
        }

        @include desktop-down {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: rem(26);
        }

        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: rem(16);
        }
    }

    &_info {
        z-index: 1;
        position: absolute;
        top: 15%;
        left: 7%;
        max-width: 47%;

        @include max-desktop-down {
            left: 3%;
        }
        @include desktop-down {
            max-width: 53%;
            left: 0;
        }
        @include tablet-down {
            max-width: 70%;
        }
        @include mobile-down {
            position: static;
            max-width: 100%;
        }

        &_des {
            @include desktop-down {
                display: none;
            }
        }

    }
}

.p-homeCollectionSecond {
    position: relative;

    > .a-image {
        padding-bottom: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &_wrapTop {
        .container {
            @include mobile-down {
                max-width: 100%;
                padding-right: 0;
            }
        }
    }

    &_top {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-20);
        position: relative;
        z-index: 2;

        @include desktop-down {
            margin: 0 rem(-10);
        }
    }

    &_left {
        flex: 0 0 32.2%;
        max-width: 32.2%;
        padding: 0 rem(20);
        display: flex;
        justify-content: center;
        flex-direction: column;
        order: 2;

        @include desktop-down {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @include small-mobile {
            display: none;
        }
    }

    &_right {
        flex: 0 0 67.8%;
        max-width: 67.8%;
        padding: 0 rem(20);

        @include desktop-down {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &_wrap {
            position: relative;
        }

        &_image {
            margin-right: auto;
            max-width: 93%;
            @include desktop-down {
                max-width: 80%;
            }
            @include tablet-down {
                max-width: 85%;
            }
            @include mobile-down {
                max-width: 100%;
            }
        }
    }

    &_wrapCardSub {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 rem(-14);

        @include small-mobile {
            margin: 0;
        }
    }

    &_cardSub {
        padding: 0 rem(14);
        flex: 0 0 100%;
        max-width: 100%;

        &:last-child {
            @include tablet-up {
                margin-top: rem(31);
            }
        }

        @include desktop-down {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: rem(26);
        }

        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: rem(16);
        }

        @include small-mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &_info {
        z-index: 1;
        position: absolute;
        top: 15%;
        right: 0;
        max-width: 38%;

        @include max-desktop-down {
            top: 5%;
            right: 6%;
        }

        @include desktop-down {
            max-width: 50%;
            right: -5%;
            top: 0;
        }

        @include mobile-down {
            position: static;
            max-width: 100%;
        }

        &_des {
            @include desktop-down {
                display: none;
            }
        }
    }
}

.p-homeCollectionThree {
    position: relative;

    > .a-image {
        padding-bottom: 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &_left {
        position: relative;
        @include tablet-desktop {
            order: 2;
        }
    }

    &_right {
        @include tablet {
            order: 1;
        }

        @include mobile-down {
            margin-top: rem(64);
        }
    }

    &_title {
        position: absolute;
        top: 0;

        @include tablet-desktop {
            display: none;
        }

        @include mobile-down {
            position: static;
            top: 0;
        }
    }

    &_titleTablet {
        display: none;
        @include tablet-desktop {
            display: block;
        }
    }

    &_list {
        display: flex;
        margin: rem(155) rem(-20) 0;

        @include tablet-desktop {
            margin: rem(24) rem(-14) 0;
        }

        @include mobile-down {
            margin: rem(12) rem(-5) 0;
        }

        @include small-mobile {
            display: none;
        }
    }

    &_item {
        width: 50%;

        &:last-child {
            margin-top: rem(90);

            @include tablet-desktop {
                margin-top: 0;
            }

            @include mobile-down {
                margin-top: rem(39);
            }
        }

        @include tablet-desktop {
            .c-productCollection {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }

    &_link {
        @include desktop-down {
            display: none;
        }
    }

    &_wrapDesc {
        margin-top: rem(149);
        margin-right: rem(20);
        position: relative;

        @include tablet-desktop {
            margin-top: rem(120);
        }

    }
    
    &_buttonBuy {
        max-width: rem(108);
        margin-right: rem(19);
    }

    &_desc {
        @include desktop-down {
            display: none;
        }
    }

    &_wrapThumbnail {
        max-width: rem(473);
        width: 100%;
        position: relative;
        flex-shrink: 0;

        @include max-desktop-down {
            max-width: rem(330);
            margin-left: auto;
        }

        @include tablet-down {
            max-width: rem(248);
        }

        @include mobile-down {
            max-width: rem(281);
            margin: auto;
        }

        @include small-mobile {
            margin-left: auto;
            margin-right: 0;
        }

        &::before {
            content: '';
            background-image: url('~assets/images/HomeCollectionThree/bg.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            width: 100%;
            max-width: rem(338);
            max-height: rem(344);
            position: absolute;
            bottom: 0;
            left: rem(-170);

            @include desktop-down {
                bottom: rem(-40);
                left: rem(-120);
            }

            @include tablet-down {
                max-width: rem(172);
                max-height: rem(167);
                bottom: 0;
                left: rem(-110);
                transform: rotate(-30deg);
            }

            @include mobile-down {
                max-width: rem(164);
                left: rem(-80);
            }
        }
    }

    &_thumbnail {
        overflow: hidden;
    }
}

.flatList {
    .container {
        @include small-mobile {
            padding-right: 0;
        }
    }

    &_carousel {
        margin: rem(-9);

        .o-carousel {
            &_arrow {
                top: 35%;

                &_prev {
                    left: rem(-22);
                }

                &_next {
                    right: rem(-22);
                }
            }
        }
    }

    &_item {
        padding: rem(9);
    }

    &_button {
        margin: rem(32) auto 0;
        max-width: rem(148);
        display: none;

        @include tablet-down {
            display: block;
        }
    }
}
