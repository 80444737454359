.t-popupCombo {
    &_list {
        display: flex;
        align-items: center;
    }

    &_item {
        width: 100%;
        align-self: flex-start;
    }

    &_plus {
        transform: rotate(45deg);
        padding-left: rem(32);
        padding-right: rem(32);

        @include desktop-down {
            padding-left: rem(16);
            padding-right: rem(16);
        }

        @include mobile-down {
            padding-left: rem(8);
            padding-right: rem(8);
        }
    }

    &_buy {
        margin: rem(32) auto 0;
        width: fit-content;
        min-width: rem(130);
    }

    .m-productCard {
        &_addCart {
            display: none;
        }

        a {
            pointer-events: none;
        }

        @include mobile-down {
            .a-colorpicker_body {
                width: rem(17);
                height: rem(17);

                img {
                    width: rem(13);
                    height: rem(13);
                }
            }

            .a-sizePicker_body {
                width: rem(24);
                height: rem(24);

                span {
                    @include font-base(12, 14);
                }
            }
        }
    }


}


.o-popup-combo {
    $root: '.o-popup';

    #{$root}_wrapper {
        max-width: rem(690);
        overflow: visible;
        padding: 0;
    }
    
    #{$root}_body {
        margin: 0;
        padding: rem(32);
        @include mobile-down {
            padding: rem(32) rem(16);
        }
    }

    #{$root}_close {
        top: rem(-12);
        right: rem(-12);
    }
}
