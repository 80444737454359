.p-news {
    padding-top: rem(30);

    @include tablet-down {
        padding-top: rem(88);
    }

    @include mobile-down {
        padding-top: rem(38);
    }

    &_listButton {
        display: flex;
        margin: rem(27) auto 0;
        overflow: auto;
        width: 100%;
        max-width: rem(510);
        @include scroll-bars(4, $vivid-cerulean, $sonic-silver02, 100, 100, 0);
        padding-bottom: rem(10);

        @include mobile-down {
            margin: rem(-2) rem(-3) rem(0);
        }

        &-item {
            padding: 0 rem(9);
            width: 100%;
            max-width: fit-content;

            @include mobile-down {
                max-width: 50%;
                padding: 0 rem(3);
                margin-top: rem(10);
            }

            .a-tabButton {
                justify-content: center;
                padding: 0 rem(28);
                white-space: nowrap;
            }
        }
    }
}
