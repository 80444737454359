.p-collectionDetail {
    &_bottomBanner {
        .o-carousel {
            &_arrow {
                height: rem(35);
                width: rem(35);
    
                &::before {
                    background-size: rem(12);
                }
    
                &_prev {
                    left: rem(48);
    
                    @include tablet-down {
                        left: rem(14);
                    }
                }
    
                &_next {
                    right: rem(48);
    
                    @include tablet-down {
                        right: rem(14);
                    }
                }
            }
        }
    }
}
