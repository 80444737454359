.t-oderStatus {
    &_wrapper {
        display: flex;
        overflow: hidden;
        border-radius: rem(10);
        background-color: $white;

        @include tablet-down {
            flex-direction: column;
            border-radius: 0;
            background-color: transparent;
        }
    }

    &_left {
        background-color: $white;

        @include tablet-down {
            border-radius: rem(10);
        }

        &-wrapper {
            padding: rem(60) rem(32) rem(62) rem(65);

            @include max-desktop-down {
                padding: rem(45) rem(32) rem(45) rem(50);
            }

            @include tablet-down {
                padding: 0;
            }
        }
    }

    &_image {
        display: flex;
        height: 100%;
        align-items: center;

        @include tablet-down {
            padding-top: rem(48);
        }

        @include mobile-down {
            padding-top: rem(40);
        }

        .a-image {
            height: 100%;
            @include tablet-down {
                border-radius: rem(10);
                overflow: hidden;
            }
        }
    }

    &_divider {
        background-color: $raisin-black01;
        display: block;
        height: rem(1);
        margin: rem(25) 0;
        width: 100%;
    }

    &_orderInfomation {
        &_wrapper {
            margin-top: rem(67);

            @include tablet-down {
                margin-top: rem(55);
            }

            @include mobile-down {
                margin-top: rem(46);
            }
        }

        &_description {
            margin-top: rem(12);

            @include mobile-down {
                margin-top: rem(16);
            }
        }

        &_receiver,
        &_phone,
        &_address,
        &_paymentMethod {
            margin-top: rem(20);
        }

        &_code,
        &_totalCost {
            margin-top: 0;
        }

        &_button {
            margin-top: rem(60);

            @include mobile-down {
                margin-top: rem(48);
            }
        }

        @include tablet-down {
            padding: rem(50) rem(54) rem(53);
        }

        @include mobile-down {
            padding: rem(37) rem(27) rem(38);
        }
    }
}
