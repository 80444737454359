.m-breadcrumb {
    $root: &;
    
    align-items: center;
    display: flex; 
    margin-bottom: rem(24);

    &_li {
        display: inline-block;
        &::before{
            content: " / ";
            padding: 0 10px;
        }
    }
    &_li_first {
        display: inline-block;
    }
}
