.t-profileInfo {
    background: $white;
    padding: rem(60);
    border-radius: rem(10);
    
    @include desktop-down {
        padding: rem(44) rem(40);
    } 
    @include mobile-down {
        padding: rem(16) rem(16) rem(46) rem(16);
    }

    &_listInfo {
        margin-top: rem(60);
        margin-bottom: rem(60);
        @include desktop-down {
            margin-top: rem(35);
            margin-bottom: rem(40);
        }
        @include mobile-down {
            margin-top: rem(20);
            margin-bottom: rem(24);
        }
    }

    &_info {
        @include adjust-flex(flex-start, center);
        padding-top: rem(16);
        padding-bottom: rem(16);

        &:not(:first-of-type) {
            border-top: 1px solid $raisin-black01;
        }
    }

    &_label {
        flex: 0 0 15%;
        @include tablet-down {
            flex: 0 0 28%; 
        }
        @include mobile-down {
            flex: 0 0 50%;
        }
    }

    &_btnEdit {
        width: fit-content;
        min-width: rem(146);
        margin-bottom: rem(16);
    }
}
