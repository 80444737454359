.t-homeSizeAdvisor {
    $root: &;
    padding-top: rem(68);
    position: relative;
    // background-color: #fddfd4;
    overflow: hidden;

    @include tablet-down {
        padding-top: rem(40);
    }

    @include mobile-down {
        padding-top: rem(24);
    }

    &_sizeRecommend {
        overflow: hidden;
        transition: max-height $transition-long ease-in-out;

        &-list {
            @include adjust-flex(flex-start, center);
            flex-wrap: wrap;
            margin: rem(16) rem(-10) rem(-10);
        }

        &-item {
            padding: rem(10);
        }
    }

    &_category {
        @include desktop-down {
            margin-top: rem(37);
        }

        @include mobile-down {
            margin-top: rem(33);
        }

        &_list {
            display: flex;
            flex-wrap: wrap;
            margin: rem(17) rem(-27.5) rem(-25);

            @include break(1200px, 1599px) {
                margin-left: rem(-10);
                margin-right: rem(-10);
            }

            @include tablet-down {
                margin: rem(17) rem(-7) 0;
            }

            @include mobile-down {
                margin: rem(17) rem(-14) rem(-25);
            }
        }

        &_item {
            padding-left: rem(27.5);
            padding-right: rem(27.5);
            margin-bottom: rem(25);
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);

            @include break(1200px, 1599px) {
                padding-left: rem(14);
                padding-right: rem(14);
            }

            @include desktop-down {
                padding-left: rem(7);
                padding-right: rem(7);
                margin-bottom: 0;
                flex: 0 0 calc(100% / 6);
                max-width: calc(100% / 6);
            }

            @include mobile-down {
                padding-left: rem(14);
                padding-right: rem(14);
                margin-bottom: rem(25);
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
            }
        }

        &_range {
            margin-top: rem(40);

            @include desktop-down {
                max-width: rem(384);
            }

            @include mobile-down {
                margin-top: rem(35);
                max-width: rem(346);
            }

            &-weight {
                margin-top: rem(40);
            }
        }

        .a-inputRange_input {
            margin-top: rem(28);
        }
    }

    &_thumbnail {
        max-width: rem(852);
        width: 100%;
        margin-right: 0;
        margin-left: auto;
        position: relative;

        @include tablet-desktop {
            max-width: rem(504);
            margin-right: -10%;
        }

        @include tablet-down {
            max-width: rem(714);
            margin-right: -26%;
        }

        @include mobile-down {
            max-width: rem(531);
            margin-right: -5%;
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 2%;
        //     left: 7%;
        //     width: rem(625);
        //     height: rem(566);
        //     border-radius: 50%;
        //     background-color: rgba($white, 0.55);

        //     @include desktop-down {
        //         width: rem(524);
        //         height: rem(474);
        //         top: 0;
        //         left: -5%;
        //     }

        //     @include mobile-down {
        //         width: rem(361);
        //         height: rem(346);
        //         left: 8%;
        //     }
        // }
    }

    &_grid {
        display: flex;
        margin-left: rem(-16);
        margin-right: rem(-16);
        flex-wrap: wrap;

        &_left,
        &_right {
            padding-left: rem(16);
            padding-right: rem(16);
            width: 100%;
            position: relative;
        }

        &_left {
            z-index: $z-2;
            min-height: rem(694);

            @include mobile-down {
                min-height: unset;
            }

            #{$root}_heading {
                @include desktop-up {
                    display: none;
                }
            }
        }

        &_right {
            z-index: $z-1;
            display: flex;
            align-items: flex-end;

            @include desktop {
                margin-top: -41%;
            }

            @include tablet {
                margin-top: -60%;
            }

            @include mobile-down {
                padding-right: 0;
                margin-top: rem(18);
            }

            #{$root}_heading {
                @include desktop-down {
                    display: none;
                }
            }
        }

        @include desktop-up {
            &_left {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
            }

            &_right {
                flex: 0 0 66.66667%;
                max-width: 66.66667%;
            }
        }
    }

    &_heading {
        @include desktop-up {
            position: absolute;
            width: rem(308);
            top: 10%;
            left: 10%;
            z-index: $z-2;
        }

        @include break(1200px, 1599px) {
            width: rem(180);
        }

        .a-paragraph-heading {
            @include font-base(81, 95);

            @include break-max(1599px) {
                @include font-base(50, 62);
            }

            @include desktop-down {
                @include font-base(36, 48);
            }
        }


    }

    &_external {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin-top: rem(40);
        @include reset-button();
        background-color: transparent;

        @include mobile-down {
            margin-top: rem(35);
        }

        &_icon {
            width: rem(52);
            height: rem(52);
            background-color: $vivid-cerulean;
            border-radius: 50%;
            @include adjust-flex(center, center);
            margin-right: rem(10);
        }
    }

    .o-container-noPaddingRight {
        @include mobile-down {
            padding-right: rem(16);
        }
    }
}
