@import './CommentList';

.t-productDetailFeedback {
    background-color: $white;
    border-radius: rem(10);

    @include mobile-down {
        background-color: transparent;
    }

    &_wrapTab {
        padding: rem(66);
        
        @include mobile-down {
            padding: 0;
        }
    }

    &_tab {
        display: flex;
        align-items: center;
        @include mobile-down {
            display: none;
        }

        &_item {
            max-width: rem(314);
            width: 100%;
            @include tablet-down {
                max-width: rem(200);
            }

            &:first-child {
                margin-right: rem(20);
            }
        }
    }

    &_content {
        display: flex;
        flex-wrap: wrap;
        margin: rem(32) rem(-41) 0;
        @include max-desktop-down {
            margin: rem(32) rem(-20) 0;
        }

        @include desktop-down {
            margin-left: 0;
            margin-right: 0;
        }

        &_left {
            flex: 0 0 30%;
            max-width: 30%;
            padding: 0 rem(41);
            @include max-desktop-down {
                flex: 0 0 40%;
                max-width: 40%;
                padding: 0 rem(20);
            }
            @include desktop-down {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }

        &_right {
            flex: 0 0 70%;
            max-width: 70%;
            padding: 0 rem(41);
            @include max-desktop-down {
                flex: 0 0 60%;
                max-width: 60%;
                padding: 0 rem(20);
            }
            @include desktop-down {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: rem(55);
                padding: 0;
            }
        }

        &_rating {
            @include desktop-down {
                max-width: rem(316);
            }

            @include mobile-down {
                max-width: 100%;
                padding-left: rem(12);
                padding-right: rem(12);
            }
        }
    }

    &_panel {
        @include mobile-up {
            display: none;
        }

        &-active {
            display: block;
        }

        @include mobile-down {
            background-color: $white;

            &.listcomment {
                padding: rem(56) rem(38) rem(46);
            }

            &.comment {
                padding: rem(57) rem(38) rem(51);
            }
        }
    }
}
