.t-interfaceMobile {
    &_listYear {
        padding-left: rem(16);
        padding-right: rem(16);

        .slick-slide {
            text-align: center;
        }

        .slick-current {
            .a-paragraph-text {
                font-weight: 700;
            }
        }
    }

    &_listCard {
        .o-carousel_arrow {
            bottom: rem(-70);
            top: auto;
            transform: none;

            &_next {
                left: rem(48);
            }

            &_prev {
                left: 0;
            }
        }
    }
}
