.m-pagination {
    @include adjust-flex;

    .main {
        display: flex;
    }

    .page {
        background: transparent;
        border: 0;
        box-sizing: border-box;
        cursor: pointer;
        height: rem(30);
        list-style-type: none;
        margin: 0 rem(5.5);
        min-width: rem(30);
        overflow: hidden;

        &:nth-child(2) {
            margin-left: 0;
        }
  
        &:nth-last-child(2) {
            margin-right: 0;
        }
  
        .link-page {
            @include adjust-flex;
            @include font-base(16, 21);
            color: $raisin-black;
            height: 100%;
            outline: none;
            padding: 0;
            width: 100%;
        }

        .link-break {
            align-items: flex-end;
            padding-bottom: rem(5);
        }
    }
  
    .active-page {
        .link-page {
            font-weight: bold;
            color: $vivid-cerulean;
        }
    }
}
