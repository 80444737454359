.a-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;


    input {
        appearance: none;
        background-color: transparent;
        margin: 0;
        width: rem(18);
        height: rem(18);
        border: rem(2) solid $eerie-black015;
        border-radius: rem(50);
        cursor: pointer;
        flex-shrink: 0;
        @include adjust-flex();
    
        &::before {
            content: '';
            width: rem(10);
            height: rem(10);
            transform: scale(0);
            border-radius: rem(50);
            transition: transform $transition ease-in-out;
            background-color: $vivid-cerulean;
        }
    
        &:checked::before {
            transform: scale(1);
        }
    }

    &-secondary {
        input {
            border-radius: rem(4);
            min-width: rem(24);
            min-height: rem(24);
            border-color: $sonic-silver;

            &::before {
                width: rem(16);
                height: rem(16);
                border-radius: rem(4);
            }
        }
    }

    label {
        padding-left: rem(7);
        cursor: pointer;
        color: $raisin-black;
        @include font-base(16, 30); 
        flex-grow: 1;
    }

    &-error {
        input {
            border-color: $electric-crimson;
        }

        label {
            color: $electric-crimson;
        }
    }
}
