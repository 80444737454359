.nav-search_backdrop {
    background-color: $raisin-black05;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 400;
    display: none;
    top: 0;
    left: 0;

    &-open {
        display: block;
    }
}

.nav-search {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: $white;
    transition: $transition-long ease-in-out;
    max-width: 80%;
    padding: rem(40) rem(24) rem(10);
    z-index: 401;
    
    @include mobile-up {
        max-width: rem(430);
        padding-left: rem(32);
        padding-right: rem(32);
    }

    &-open {
        right: 0;
    }

    &_search {
        .header-search-input {
            border: 1px solid $platinum1;

            input {
                color: $raisin-black;

                &::placeholder {
                    color: $raisin-black;
                }
            }
        }
    }

    &_groupBtn {
        display: flex;
        align-items: center;
        margin: rem(30) rem(-10) 0;

        > div {
            flex: 0 0 50%;
            padding: 0 rem(10);
        }
    }
}
