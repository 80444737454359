.a-inputRange {
    width: 100%;

    &_head {
        @include adjust-flex(space-between, center); 

        label {
            font-weight: 400;
            @include font-base(18, 24); 
            color: $raisin-black;

            &:first-child {
                font-weight: 700;
            }
        }
    }

    &_input {
        width: calc(100%);
        margin: rem(20) auto 0 auto;
        outline: none;
        appearance: none;
        height: rem(2);
        transition: 4s ease-in-out;
        background: $raisin-black02;

        &::-webkit-slider-thumb {
            appearance: none;
            width: rem(12);
            height: rem(12);
            border-radius: 50%;
            background-image: url('~assets/icons/ic_input_range.svg');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
}

.a-multiInputRange {
    position: relative;

    &_head {
        @include adjust-flex(space-between, center);
        margin-top: rem(16);

        label {
            @include font-base(16, 21);
            color: $raisin-black;
        }
    }

    &_inputMin,
    &_inputMax {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        margin: 0;
        outline: none;
        appearance: none;
        top: rem(1);


        &::-webkit-slider-thumb {
            appearance: none;
            width: rem(12);
            height: rem(12);
            border-radius: 50%;
            background-image: url('~assets/icons/ic_input_range.svg');
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            cursor: pointer;
            pointer-events: all;
            position: relative;
        }
    }

    &_slider {
        position: relative;
        width: 100%;
        margin: auto;
        height: rem(2);

        &-track {
            position: absolute;
            background: $raisin-black02;
            inset: 0;
        }

        &-range {
            background: $vivid-cerulean;
            position: absolute;
            height: 100%;
            transition: all 0s ease-in-out;
        }
    }
}
