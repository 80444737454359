.o-ratingProduct {
    &_listRate {
        &-progress {
            width: 100%;
        }
    }

    &_starList {
        &-cursor {
            cursor: pointer;
        }
    }


    &_progress {
        height: rem(4);
        border-radius: rem(2);
        background: $raisin-black011;
        position: relative;
        overflow: hidden;
        width: 100%;

        &-range {
            position: absolute;
            height: 100%;
            border-radius: rem(2);
            background-color: $dark-pink;
        }
    }
}
