.o-productEntry {

    display: flex;

    &_image {
        margin-right: rem(12);
        flex-shrink: 0;
        
        .a-image {
            border-radius: rem(8);
            width: rem(50);
        }
    } 

    &_content {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        gap: rem(16);
    }

    &_info {
        flex-grow: 1;
    }

    &_name {
        .a-paragraph-text {
            @include text-overflow(1);
        }
    }

    &_amountBadge {
        border-radius: rem(12);
        border: 1px solid $dark-gray;
        padding: 0 rem(8);
        display: flex;
        align-items: center;
        gap: rem(4);
    }

    &_priceZone {
        flex-shrink: 0;
    }

}
