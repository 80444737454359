/* stylelint-disable max-nesting-depth */
.t-collectionsummary {
    $root: &;
    padding: rem(74) 0 rem(36);
    @include tablet-down {
        padding: rem(72) 0 rem(36);
    }
    @include mobile-down {
        padding: rem(48) 0 rem(36);
    }

    &_header {
        position: relative;
        z-index: 1;
        margin-bottom: rem(40);

        &::before {
            content: '';
            position: absolute;
            width: rem(105);
            height: rem(77);
            transform: translate(-50%, -50%);
            left: 30%;
            top: 0;
            background: url('~assets/images/collection-summary/layer_quote.png') no-repeat;
            background-size: cover;
            z-index: -1;
            
        }
        @include tablet-down {
            margin-bottom: rem(34);

            &::before {
                left: 15%;
                top: -3.5%;
            }
        }
        @include mobile-down {
            margin-bottom: rem(36);

            &::before {
                left: -7%;
                top: -9%;
                transform: none;
            }

            .a-paragraph-heading {
                text-align: left;
                position: relative;
            }
        }
      
    }

    &_subTitle {
        position: relative;
    }

    &_descContent {
        @include mobile-down {
            margin-bottom: rem(12);
        };
    }

    &_desRow {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-46);
        @include tablet-down {
            margin: 0  rem(-34);
        }
        @include mobile-down {
            margin-top: rem(16);
        }
    }

    &_desCol {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(46);
        margin-top: rem(22);
        @include tablet-down {
            padding: 0 rem(34);
            margin-top: rem(36);
        }
        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 0;
        }
    }

    &_wrapper {
        position: relative;
    
        @include tablet-up {
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: rem(416);
                height: rem(416);
                background: url('~assets/images/collection-summary/layer.png') no-repeat;
                transform: translate(50%, 25%);
            }
        }
    }

    &_contentMain {
        @include tablet-up {
            display: none;
        }
    }

    &_item {
        &:hover {
            .a-image {
                overflow: hidden;

                img {
                    transition: $transition ease-in-out;
                    transform: scale(1.2);
                }
            }
        }

     
    }

    &_wrapContentList {
        &.type1 {
            overflow: hidden;
            #{$root}_contentList {
                /* fixed height, độ cao của item cao nhất */
                height: rem(599);
                width: 100%;
                display: flex;
                align-content: space-between;
                justify-content: space-between;
                flex-flow: column wrap;
                gap: rem(30);
                @include tablet-down {
                    margin-top: rem(56);
                    #{$root}_item {
                        &.main {
                            display: none;
                        }
                    }
                }
                @include mobile-down {
                    flex-flow: row wrap;
                    width: 100%;
                    height: inherit;
                    gap: 0;
                    margin: rem(-5.5 + 48) rem(-5) rem(-5.5);
                    width: calc(100% + 10px);
                }
            }
            #{$root}_item {
                width: 100%;
    
                &.main {
                    flex-shrink: 0;
                    max-width: rem(631);
                    order: 1;
                    
                    @include tablet-up() {
                        .a-image {
                            min-height: rem(599);
                        }
                    }
                    @include max-desktop-down() {
                        max-width: rem(440);
                    }
                    @include desktop-down() {
                        max-width: rem(400);
                    }
                    @include tablet-down() {
                        max-width: 100%;
                    }
                }

                &.full {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
         
                /* Re-order items into 3 rows */
                &:not(.main) {
                    &:nth-child(2) {
                        max-height: rem(283);
                    }

                    &:nth-child(3) {
                        max-height: rem(400);

                        .a-image {
                            min-height: rem(400);
                        }
                    }

                    &:nth-child(4) {
                        max-height: rem(283);
                    }

                    &:nth-child(5) {
                        max-height: rem(169);
                    }
               

                    @include tablet-up {
                        &:nth-of-type(3n+2),
                        &:nth-of-type(3n+4) { 
                            max-width: rem(293);
                            order: 2;

                            .a-image {
                                min-height: rem(283);
                            }
                            @include desktop-down {
                                max-width: rem(220);
                            }
                        }
    
                        &:nth-of-type(3n) ,
                        &:nth-of-type(3n+5) { 
                            order: 3;
                            max-width: rem(375);
                            @include max-desktop-down() {
                                max-width: rem(320);
                            }
                            @include desktop-down {
                                max-width: rem(280);
                            }
                        }
                    }
                    @include tablet-down {
                        &:nth-of-type(2n+1) {
                            order: 2;
                            max-width: rem(359);
    
                        }
    
                        &:nth-of-type(2n+2) {
                            order: 1;
                            max-width: rem(280);
                        }
                    }
                    @include mobile-down {
                        width: 50% !important;
                        overflow: hidden;
                        padding: rem(0) rem(5);

                        &:nth-of-type(2n) {
                            height: rem(161);
                            margin-bottom: rem(11);

                            .a-image {
                                min-height: rem(161);
                            }
                        }
    
                        &:nth-of-type(2n+1) {
                            height: rem(180);
                            min-height: rem(180);

                            .a-image {
                                min-height: rem(180);

                            }
                        }
                    }
                }
            }
        }

        &.type2 {
            &.lastLayer {
                @include tablet {
                    margin-top: rem(31);
                }
            }
            margin-top: rem(63);
            @include tablet-down {
                margin-top: rem(24);
            }
            @include mobile-down {
                margin-top: rem(28);
            }

            #{$root}_contentList {
                @include adjust-flex(flex-start,center);
                @include mobile-down {
                    flex-wrap: wrap;
                    margin: rem(-8);
                }
            }
            #{$root}_item {
                @include tablet-up {
                    flex: 1;

                    .a-image {
                        min-height: rem(400);
                    }

                    &:nth-child(2n+1) {
                        flex: 0 0 27%;
                        max-width: 27%;
                        padding-right: rem(39);
                    }

                    &:nth-child(2n) {
                        flex: 0 0 23%;
                        max-width: 23%;
                        padding-right: rem(34);
                    }
                }
                @include desktop {
                    .a-image {
                        min-height: rem(300);
                    }
                }
                @include tablet {
                    .a-image {
                        min-height: rem(197);
                    }
    
                    &:nth-child(2n+1) {
                        flex: 0 0 28%;
                        max-width: 28%;
                        padding-right: rem(20);
                    }
    
                    &:nth-child(2n) {
                        flex: 0 0 22%;
                        max-width: 22%;
                        padding-right: rem(17);
                    }
                }

                &:last-child {
                    padding-right: 0;
                }
                @include mobile-down {
                    padding: rem(8) !important;
                   
                    &:nth-child(2n+1) {
                        flex: 0 0 55%;
                        max-width: 55%;

                        .a-image {
                            @include aspect-ratio(186,209);
                        }
                    }

                    &:nth-child(2n) {
                        flex: 0 0 45%;
                        max-width: 45%;

                        .a-image {
                            @include aspect-ratio(144,200);
                        }
                    }
                }

            }
        }
        
        &.type3 {
            margin-top: rem(63);
            @include tablet-down {
                margin-top: rem(31 + 8);
            }
            @include mobile-down {
                margin-top: rem(28);
            }

            #{$root}_contentList {
                @include adjust-flex(flex-start,center);
                margin: rem(-16);
                @include tablet-down {
                    margin: rem(-8);
                }
                @include mobile-down {
                    flex-wrap: wrap;
                    margin: rem(-14);
                }
            }
            
            #{$root}_item {
                flex: 1;
                padding: rem(16);
                
                @include tablet-down {
                    padding: rem(8);
                }
                @include mobile-down {
                    flex: 0 0 100%;
                    padding: rem(14);
                }
            }
        }
    }  
}
