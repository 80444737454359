.p-register {
    background-color: $linen !important;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    padding-top: rem(61);
    padding-bottom: rem(40);
    @include tablet-down {
        padding-bottom: 71%;
    }
    @include mobile-down {
        background-image: none !important;
        padding-bottom: rem(40);
        padding-bottom: rem(48);
    }

    &_title {
        position: absolute;
        top: 15%;
        left: 53%;

        @include max-desktop-down {
            left: 48%;
        }
    }
    
    &_form {
        max-width: rem(472);
        @include tablet-down {
            max-width: 100%;
        }

        &_wrap {
            background-color: $white;
            border-radius: rem(10);
            padding: rem(35);
            @include tablet-down {
                padding: rem(27) rem(32) rem(30) rem(32);
            }
            @include mobile-down {
                padding: rem(28) rem(20) rem(31) rem(20);
            }
        }

        &_btn {
            width: fit-content;
            min-width: rem(128);
        }

        &_label {
            width: rem(70);
        }

        &_groupPullDown {
            @include mobile-up {
                flex: 0 0 calc(100% - 70px);
                max-width: calc(100% - 70px);
            }
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-4);
            margin-right: rem(-4);

            > div {
                flex: 0 0 calc(100% / 3);
                max-width: calc(100% / 3);
                padding: 0 rem(4);
            }
        }

        &_btnRequestOtp {
            @include reset-button();
            background-color: transparent;
            padding: 0;
            width: fit-content;
            margin-left: auto;
            display: block;

            &.repeat {
                margin-left: initial;
                margin-right: auto;
            }

            &.loading {
                pointer-events: none;
                opacity: 0.7;
            }
        }

        .a-checkbox {
            align-items: baseline;

            input {
                width: rem(24);
                height: rem(24);
                border-radius: rem(4);

                &::before {
                    width: rem(14);
                    height: rem(14);
                    border-radius: rem(4);
                }
            }
        }
    }

}
