.header-search-input {
    display: flex;
    align-items: center;
    border-radius: rem(22);
    background-color: $white024;
    padding: 0 3px 0 rem(18);
    height: rem(38);
    width: 100%;

    input {
        @include font-base(16, 19);
        border: 0;
        color: $white;
        font-family: inherit;
        outline: 0;
        width: 100%;
        background-color: transparent;
        padding-right: rem(10);

        &::placeholder {
            color: $white;
        }
    }

    button {
        @include adjust-flex();
        @include reset-button();
        flex-shrink: 0;
        width: rem(32);
        height: rem(32);
        border-radius: 50%;
        background-color: $white;
    }
}

.header-button-icon {
    @include adjust-flex();
    @include reset-button();
    flex-shrink: 0;
    padding: 0;
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    -webkit-transition: background-color $transition;
    -o-transition: background-color $transition;
    transition: background-color $transition;

    img {
        width: rem(25);
    }
}

.header-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    @include adjust-flex(center, center);
    background-color: $vivid-cerulean;
    color: $white;
    height: rem(15);
    padding: rem(1) rem(4.5) 0;
    z-index: $z-2;
}
