@import './CardInfoHistory';
@import './InterfaceMobile';

@mixin year-title($fs: 20, $lh: 24) {
    @include font-base($fs, $lh);
    color: $raisin-black075;
    font-weight: 400;
}

@mixin year-bigtitle($fs: 55, $lh: 70) {
    @include font-base($fs, $lh);
    color: $cyan-cornflower;
    font-weight: 700;
}


.t-aboutUsHistory {
    $root: &;
    background-color: $seashell;
    padding-top: rem(82);
    padding-bottom: rem(82);

    .o-container {
        @include break-max(1600px) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }

        @include mobile-down {
            padding-left: rem(24);
            padding-right: rem(24);
        }
    }

    &_titleSub,
    &_title {
        text-align: center;

        @include mobile-down {
            text-align: left;
        }
    }

    &-even {
        .slick-slide:nth-child(even) {
            #{$root}_list_item {
                transform: scale(0.8);
            }
        }
    }
    
    &-odd {
        .slick-slide:nth-child(odd) {
            #{$root}_list_item {
                transform: scale(0.8);
            }
        }
    }


    &_list {
        @include mobile-down {
            display: none;
        }

        &_item {
            transition: all $transition ease-in-out;
            padding-bottom: rem(48);
        }

        .slick-track {
            display: flex;
        }

        .slick-slide {
            pointer-events: none;

            .t-cardBasic_left {
                width: 100%;
            }

            .t-cardBasic_right {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .t-cardBasic_right-year {
                position: absolute;
                left: 0;
                bottom: rem(-32);
                z-index: 5;
                @include year-title();
            }

            .t-cardBasic_desc,
            .t-cardComplex_divider {
                display: none;
            }
        }

        .slick-active.slick-center {
            pointer-events: visible;
        }
    }

    &-active {
        .slick-active.slick-center {
            width: fit-content !important;


            .t-cardBasic_left {
                width: rem(347);

                @include break-max(1600px) {
                    width: rem(443);
                }

                @include break-max(1440px) {
                    width: rem(347);
                }

                @include break-max(1366px) {
                    width: rem(368);
                }

                @include desktop-down {
                    width: rem(260);
                }
            }
    
            .t-cardBasic_right {
                width: rem(265);
                height: auto;
                position: relative;
                margin-left: rem(30);

                @include break-max(1600px) {
                    width: rem(334);
                }

                @include break-max(1440px) {
                    width: rem(280);
                }


                @include break-max(1366px) {
                    width: rem(260);
                }

                @include desktop-down {
                    width: rem(220);
                }
            }

            .t-cardBasic_right-year {
                left: 0;
                bottom: 100%;
                transition: all $transition-long ease-in-out;
                @include year-bigtitle();
                animation-name: year-position;
                animation-duration: 1.25s;
                @include desktop-down {
                    animation-name: year-position-desktop;
                    @include year-bigtitle(38, 50);
                }
            }

            .t-cardBasic_desc,
            .t-cardComplex_divider {
                display: block;
            }
        }

    }

    &_listCaseMoblie {
        display: none;

        @include mobile-down {
            display: block;
        }
    }
}

@keyframes year-position {
    0% {
        @include year-title();
        transform: translate(-440px, 200px);
    }

    100% {
        @include year-bigtitle();
        transform: translate(0, 0);
    }
}

@keyframes year-position-desktop {
    0% {
        @include year-title();
        transform: translate(-288px, 213px);
    }

    100% {
        @include year-bigtitle(38, 50);
        transform: translate(0, 0);
    }
}
