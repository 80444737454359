.a-loading {
    $root: &;
    
    &-fixed {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2em;
        transform: translate(-50%, -50%) rotate(90deg);
    }
    
    &-relative {
        width: 2em;
        transform: translate(-50%, -50%) rotate(90deg);
    }

    &,
    div {
        transform-origin: 50% 50%;
    }

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    &::before,
    &::after,
    div::before,
    div::after {
        position: absolute;
        width: 0.5em;
        height: 0.5em;
        content: '';
        background: $vivid-cerulean;
        border-radius: 100%;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
    }

    &::before,
    div::before {
        top: -0.25em;
        left: -0.25em;
        animation-name: orbBounceBefore;
    }

    &::after,
    div::after {
        top: -0.25em;
        right: -0.25em;
        animation-name: orbBounceAfter;
    }

    &::after {
        animation-delay: 500ms;
    }

    &_a {
        transform: rotate(60deg) translateY(0);

        &::before {
            animation-delay: 166.6666666667ms;
        }

        &::after {
            animation-delay: 666.6666666667ms;
        }
    }

    &_b {
        transform: rotate(120deg) translateY(0);

        &::before {
            animation-delay: 333.3333333333ms;
        }

        &::after {
            animation-delay: 833.3333333333ms;
        }
    }
}

@keyframes orbBounceBefore {
    60% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }

    80% {
        transform: translateX(-100%);
        animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes orbBounceAfter {
    60% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }

    80% {
        transform: translateX(100%);
        animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    }

    100% {
        transform: translateX(0);
    }
}
