.t-collectionNewArrival {
    &_list {
        // margin-left: rem(-8);
        // margin-right: rem(-8);
        margin: rem(-8);

        .o-carousel_arrow {
            top: 30%;

            &_next {
                right: rem(-24);
            }

            &_prev {
                left: rem(-24);
            }
        }
    }

    &_item {
        // padding-left: rem(8);
        // padding-right: rem(8);
        padding: rem(8);
    }

    &_button {
        width: fit-content;
        min-width: rem(157);
        margin: rem(32) auto 0;
    }
}
