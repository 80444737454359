.c-productCollection {
    $root: &;

    &-row,
    &-row-reverse {
        display: flex;
        align-items: center;
        margin-left: rem(-15);
        margin-right: rem(-15);
        @include max-desktop-down {
            margin-left: rem(-8);
            margin-right: rem(-8);
        }
    }

    &-row-reverse {
        flex-direction: row-reverse;
    }

    &-column {
        #{$root}_thumbnail {
            margin-bottom: rem(20);
            @include mobile-down {
                margin-bottom: rem(6);
            }
        }
    }

    &_left,
    &_right {
        padding-left: rem(15);
        padding-right: rem(15);
        @include max-desktop-down {
            padding-left: rem(8);
            padding-right: rem(8);
        }
    }

    &_left {
        flex: 0 0 rem(211);

        @include desktop-down {
            flex: 0 0 rem(160);
        }

        @include mobile-down {
            flex: 0 0 rem(190);
        }
    }

    &_right {
        flex: 1;
    }

    &_thumbnail {
        border-radius: rem(999);
        overflow: hidden;
        width: 100%;
    }

    &_favorite {
        .a-icon {
            transition: all $transition ease-in-out;
        }
    }
}
