// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$raisin-black: rgb(37, 36, 37); //#252425
$gray: rgb(128, 128, 128); // #808080
$aero: rgb(114, 201, 217); //#72c9d9
$vivid-cerulean: rgb(0, 174, 239); //#00aeef
$heliotrope-gray: rgb(168, 160, 168);//#a8a0a8
$celadon-blue: rgb(18, 125, 175);//#127daf 
$electric-crimson: rgb(255, 0, 69); // #ff0045
$dark-pink: rgb(231, 88, 123); // #e7587b
$linen: rgb(246, 238, 225); //#F6EEE1
$eerie-black: rgb(26, 24, 24); // #1a1818
$pale-gold: rgb(217, 200, 145) ; // #d9c891 
$rich-electric-blue: rgb(15, 142, 202); // #0f8eca
$spanish-crimson: rgb(227, 14, 72); // #e30e48
$grayx11: rgb(188, 188, 188); // #bcbcbc
$cosmic-latte: rgb(255, 247, 230); // #fff7e6
$cyan: rgb(0, 178, 240); // #00b2f0
$jet: rgb(51, 51, 51); // #333333
$rich-black: rgb(3, 3, 3); // #030303 
$dark-liver: rgb(81, 80, 81); //#515051
$taupe-gray: rgb(135, 135, 135); //#878787
$red: rgb(177, 17, 22);// #B11116

$verdigris: rgb(61, 185, 181); //#3db9b5
$sonic-silver: rgb(112, 112, 112); //#707070
$dark-gray: rgb(161, 161, 161); //#a1a1a1
$platinum: rgb(229, 229, 229); // #e5e5e5
$azureish-white: rgb(216, 238, 241); // #d8eef1
$cyan-cornflower: rgb(25, 143, 205); // #198FCD
$field-drab: rgb(109, 83, 30); // #6d531e
$pastel-gray: rgb(204, 204, 204); // #cccccc
$isabelline: rgb(238, 238, 238); // #eeeeee
$raisin-black1: #25242580;

$newport-blue: rgb(25, 143, 205);// #198fcd
$platinum1: rgb(233, 233, 233); //#e9e9e9
$anti-flash-white: rgb(241, 241, 241); //#f1f1f1

$brandeis-blue: rgb(0, 104, 239); //#0068ef
$capri: rgb(0, 186, 255); // #00baff
// Background
$snow: rgb(252, 251, 249); // #FCFBF9
$ming: rgb(52, 105, 129); //#346981
$seashell: rgb(249, 245, 238); //#F9F5EE
$white-smoke: rgb(248, 246, 242); // #f8f6f2
$white-smoke1: rgb(245, 245, 245); // #f5f5f5
$celadon-blue: rgb(18, 125, 175);//#127daf

// Overlays
$aero012: rgba($aero, 0.12);
$aero024: rgba($aero, 0.24);
$aero061: rgba($aero, 0.61);
$aero065: rgba($aero, 0.65);
$black003: rgba($black, 0.03);
$black005: rgba($black, 0.05);
$black01: rgba($black, 0.1);
$white065: rgba($white, 0.65);
$white08: rgba($white, 0.8);
$vivid-cerulean01: rgba($vivid-cerulean, 0.1);
$raisin-black01: rgba($raisin-black, 0.1);
$raisin-black015: rgba($raisin-black, 0.15);
$raisin-black02: rgba($raisin-black, 0.2);
$raisin-black075: rgba($raisin-black, 0.75);
$raisin-black07: rgba($raisin-black, 0.7);
$raisin-black08: rgba($raisin-black, 0.8);
$raisin-black008: rgba($raisin-black, 0.08);
$raisin-black03: rgba($raisin-black, 0.3);
$raisin-black05: rgba($raisin-black, 0.5);
$raisin-black06: rgba($raisin-black, 0.6);
$raisin-black043: rgba($raisin-black, 0.43);
$raisin-black004: rgba($raisin-black, 0.04);
$raisin-black011: rgba($raisin-black, 0.11);
$eerie-black015: rgba($eerie-black, 0.15);
$white024: rgba($white, 0.24);
$linen029: rgba($linen, 0.29);
$white036: rgba($white, 0.36);
$white04: rgba($white, 0.4);
$white035: rgba($white, 0.35);
$aero073: rgba($aero, 0.73);
$aero012: rgba($aero, 0.12);
$sonic-silver05: rgba($sonic-silver, 0.5);
$sonic-silver025: rgba($sonic-silver, 0.25);
$spanish-crimson075: rgba($spanish-crimson, 0.75);
$azureish-white085: rgba($azureish-white, 0.85);
$rich-electric-blue-075: rgba($rich-electric-blue, 0.75);
$celadon-blue057: rgba($celadon-blue, 0.57);
$ming008: rgba($ming, 0.08);
$field-drab005: rgba($field-drab, 0.05);
$sonic-silver02: rgba($sonic-silver, 0.2);
$sonic-silver01: rgba($sonic-silver, 0.1);
$black016: rgba($black, 016);
$white02: rgba($white, 0.2);
$white07: rgba($white, 0.7);
$white09: rgba($white, 0.9);
$sonic-silver008: rgba($sonic-silver, 0.08);
// Linears
$blue-gradient: linear-gradient($capri 0%, $brandeis-blue 100%);
