.t-homeflashsale {
    position: relative;
    overflow: hidden;
    max-height: rem(2000);
    padding-top: rem(40);
    padding-bottom: rem(40);

    @include desktop-down {
        padding-top: rem(25);
        padding-bottom: rem(32);
    }

    @include mobile-down {
        padding-top: rem(27);
        padding-bottom: rem(27);
    }


    &.hidden {
        transition: $transition ease-in-out;
        max-height: 0;
        visibility: hidden;
        padding: 0;
    }

    &_header {
        @include adjust-flex(space-between,center); 

        @include tablet-up {
            margin-bottom: rem(32);
        }
        @include tablet-down {
            margin-bottom: rem(48);
        }
        @include mobile-down {
            margin-bottom: rem(43);
        }
        @include break-max(375px) {
            justify-content: space-between;
        }
    }

    &_title {
        margin-left: rem(17);
        white-space: nowrap;
        @include break-max(370px) {
            display: none;
        }
    }

    &_left,
    &_right,
    &_countdown {
        @include adjust-flex(center, center);
    }

    &_left {
        .a-icon {
            @include mobile-down {
                width: rem(44);
                height: rem(44);
            }
        }
        @include break-max(375px) {
            margin-right: rem(8);
        }
    }

    &_rightTitle {
        @include mobile-down {
            display: none;
        }
    }

    &_countdown {
        margin-left: rem(17);
        @include mobile-down {
            margin-left: rem(8);

        }
    }

    &_countdownItem {
        background: $raisin-black;
        border-radius: rem(10);
        min-width: rem(47);
        height: rem(47);
        @include adjust-flex(center, center);
        position: relative;
        padding: 0 rem(6);

        &:not(:last-child) {
            margin-right: rem(15);

            &::before {
                content: ':';
                position: absolute;
                right: rem(-15);
                top: 0;
                bottom: 0;
                width: rem(15);
                font-size: rem(20);
                color: $raisin-black;
                @include adjust-flex(center,center); 
            }
        }

        @include mobile-down {
            min-width: rem(36);
            height: rem(36);
        }
    }

    &_content {
        .slick-list {
            margin: 0 rem(-9);
            @include tablet-down {
                margin: rem(-5.5);
            }
            @include small-mobile {
                margin: rem(-16);
                padding-bottom: rem(20); // dots
            }
        }

        .slick-track {
            @include small-mobile {
                display: flex;
            }
        }

        .slick-slide {
            padding: rem(9);
            @include tablet-down {
                padding: rem(5.5);
            }
            @include small-mobile {
                padding: 0;
                padding-left: rem(16);
            }
        }

        .o-carousel_arrow {
            top: 30%;
            @include mobile-down {
                width: rem(32);
                height: rem(32);

                &::before {
                    background-size: rem(12);
                }

                &_prev {
                    left: rem(-12);
                }

                &_next {
                    right: rem(-12);
                }
            }
        }
       
    }

    &_list {
        .container {
            @include small-mobile {
                padding-right: 0;
            }
        } 
    }

}
