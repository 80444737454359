.t-profileProductList {
    background-color: $white;
    border-radius: rem(10);
    padding: rem(58) rem(60) rem(49);

    @include desktop-down {
        padding: rem(30) rem(24) rem(24);
    }

    @include mobile-down {
        background-color: transparent;
        padding: 0;
    }

    &_list {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-33.5);
        margin-right: rem(-33.5);

        @include max-desktop-down {
            margin-left: rem(-10);
            margin-right: rem(-10);
        }

        @include mobile-down {
            margin-top: rem(-7);
        }
    }

    &_item {
        flex: 0 0 calc(100% / 3);
        padding-left: rem(33.5);
        padding-right: rem(33.5);
        margin-top: rem(46);

        @include max-desktop-down {
            padding-left: rem(10);
            padding-right: rem(10);
        }

        @include desktop-down {
            margin-top: rem(20);
        }

        @include tablet-down {
            flex: 0 0 50%;
        }

        @include mobile-down {
            margin-top: rem(16);
        }
    }

}
