.t-popupAddress {
    &_checkboxStatus {
        @include mobile-down {
            max-width: rem(98);

            .a-checkbox {
                input {
                    min-width: rem(24);
                }

                label {
                    @include font-base(16, 19); }
            }
        }
    }

    &_button {
        min-width: rem(163);
    }
}
