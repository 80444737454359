.t-notFound {
    background: $blue-gradient;
    @include adjust-flex(center,center);
    padding: rem(60);
    min-height: rem(400);
    height: calc(100vh - #{$height-header-desktop}px - #{$height-footer-desktop}px);
    @include tablet-down {
        padding: rem(40);
        height: calc(100vh - #{$height-header-tablet-down}px - (#{$height-footer-tablet-down}px /2));
    }
    @include mobile-down {
        padding: rem(32);
        height: calc(100vh - #{$height-header-mobile-down}px - (#{$height-footer-mobile-down}px /2));
    }

    &_table {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            h5 {
                @include font-base(120, 144);
                text-shadow: 0 rem(3) rem(16) $brandeis-blue;
                color: $white;
            }
        }
        
        &-button {
            padding: rem(15) rem(19);
            border-radius: rem(24);
            border: 0;
            background: $white;
        }
    }
}
