.a-toastify {
    $root: &;

    &_message {
        padding: rem(5);
        font-size: rem(16);
        text-align: center;
    }

    &_description {
        font-size: rem(16);
    }

    .a-icon {
        width: rem(48);
        height: rem(48);
        margin: auto;
    }

    .Toastify {
        &__toast {
            @include small-mobile {
                margin-bottom: rem(5);
            }
        }
    }

    .Toastify__toast--default {
        color: $white;
        background-color: rgba($black, 0.68);
        border-radius: 0;
    }

    &-center {
        .Toastify__toast-container--top-center {
            top: 50%;
            left: 50%;
            max-width: calc(100% - 20px);
            transform: translate(-50%, -50%);
        }

        .Toastify__toast-body {
            margin: 0 auto;
        }
    }
}
