.t-homeshipping {
    $root: &;
    @mixin square($dimension: 0) {
        width: rem($dimension);
        height: rem($dimension);
    }

    &-homepage {
        #{$root}_img {
            @include square(69);
            @include desktop-down {
                @include square(41);
            }
            @include mobile-down {
                @include square(51);
            }
        }
        #{$root}_wrapper {
            @include mobile-down {
                flex-direction: row;
                padding-top: rem(24);
            }
        }
        #{$root}_content {
            @include mobile-down {
                margin-left: rem(24);
            }
        }
        #{$root}_title {
            margin-bottom: rem(7);
            margin-top: rem(16);
            @include mobile-down {
                flex-grow: 0;
                margin-top: rem(0);
                margin-bottom: rem(4);
            }
        }
        @include mobile-down {
            margin-top: rem(-24);
        }
    }

    &-productdetail {
        #{$root}_img {
            @include square(53);
        }
        #{$root}_wrapper {
            padding-top: rem(30);
        }
        #{$root}_title {
            flex-grow: 1;
            margin-top: rem(12);
            margin-bottom: rem(8);
            @include mobile-down {
                margin-top: rem(8);
                margin-bottom: rem(4);
            }
        }
        margin-top: rem(-30);
    }

    &_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &_content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
