:root {
    scroll-behavior: initial;
}

body {
    font-family: $font-neue-kabel, sans-serif;
    font-size: 16px;
    color: $raisin-black;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
}

blockquote {
    padding: 10.05px;
    line-height: 1.5;
    font-size: 16px;
    border: 3px dashed #00aeef;
    border-radius: 10px;
    background: #fff;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.pointer {
    cursor: pointer;
}

.text-right {
    text-align: right;
}

.events-none {
    pointer-events: none;
}


.u-mt {
    $top: '4', '5', '6', '7', '8', '9', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '38', '40', '43', '47', '45', '48', '46', '51', '55', '60', '63', '64', '70', '74', '78', '80';
    $top-sm: '0';
    $top-md: '0', '4', '6', '8', '9', '12', '16', '20', '22', '24', '26', '28', '32', '34', '36', '42', '46', '50', '55', '60', '66', '70', '72', '74', '82';
    $top-lg: '0', '16', '10', '175', '160', '124', '150', '109', '100', '20', '25', '31', '35', '32', '34', '42', '45', '40', '48', '56', '50', '55', '52', '60', '61', '64', '65', '90', '75', '80', '95', '90';
    $top-xl: '0', '25', '38', '66', '54', '33';
    $top-nagative: '6', '10', '15', '16', '42';
    $top-nagative-md: '34', '16', '14';
    $top-nagative-lg: '40', '32';
  
    @each $val in $top {
        &-#{$val} {
            margin-top: #{$val}px;
        }
    }
  
    @each $val in $top-sm {
        &-sm-#{$val} {
            @media (min-width: 576px) {
                margin-top: #{$val}px;
            }
        }
    }
  
    @each $val in $top-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                margin-top: #{$val}px;
            }
        }
    }
  
    @each $val in $top-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                margin-top: #{$val}px;
            }
        }
    }

    @each $val in $top-xl {
        &-xl-#{$val} {
            @media (min-width: 1200px) {
                margin-top: #{$val}px;
            }
        }
    }

    @each $val in $top-nagative {
        &-negative-#{$val} {
            margin-top: -#{$val}px;
        }
    }

    @each $val in $top-nagative-md {
        &-negative-#{$val} {
            @media (min-width: 768px) {
                margin-top: -#{$val}px;
            }
        }
    }

    @each $val in $top-nagative-lg {
        &-negative-#{$val} {
            @media (min-width: 992px) {
                margin-top: -#{$val}px;
            }
        }
    }
}

.u-ml {
    $left: '4', '5', '6', '8', '16', '10', '18', '14', '17', '11', '24', '20', '30', '35', '60', '80';
    $left-md: '24', '13', '0', '4';
    $left-lg: '6', '83';
    $left-negative: '2', '5', '7', '8', '12', '15', '11', '10', '16', '24';
    $left-negative-md: '14', '16', '8';
    $left-negative-lg: '10', '16', '28';
    $left-negative-xl: '30';
  
    @each $val in $left {
        &-#{$val} {
            margin-left: #{$val}px;
        }
    }

    @each $val in $left-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                margin-left: #{$val}px;
            }
        }
    }
  
  
    @each $val in $left-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                margin-left: #{$val}px;
            }
        }
    }
  
    @each $val in $left-negative {
        &-negative-#{$val} {
            margin-left: -#{$val}px;
        }
    }
  
    @each $val in $left-negative-md {
        &-negative-lg-#{$val} {
            @media (min-width: 768px) {
                margin-left: -#{$val}px;
            }
        }
    }

    @each $val in $left-negative-lg {
        &-negative-lg-#{$val} {
            @media (min-width: 992px) {
                margin-left: -#{$val}px;
            }
        }
    }

    @each $val in $left-negative-xl {
        &-negative-lg-#{$val} {
            @media (min-width: 1200px) {
                margin-left: -#{$val}px;
            }
        }
    }
}

.u-mr {
    $right: '4', '5', '8', '16', '12', '18', '17', '10', '11', '30', '40';
    $right-sm: '10';
    $right-md: '20', '15', '35';
    $right-lg: '6', '25';
    $right-xl: '55';
    $right-negative: '2', '5', '7', '8', '15', '11', '10', '16', '24';
    $right-negative-md: '14', '16', '8';
    $right-negative-lg: '10', '16', '28';
    $right-negative-xl: '30';
  
    @each $val in $right {
        &-#{$val} {
            margin-right: #{$val}px;
        }
    }

    @each $val in $right-sm {
        &-sm-#{$val} {
            @media (min-width: 576px) {
                margin-right: #{$val}px;
            }
        }
    }

    @each $val in $right-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                margin-right: #{$val}px;
            }
        }
    }
  
  
    @each $val in $right-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                margin-right: #{$val}px;
            }
        }
    }

    @each $val in $right-xl {
        &-xl-#{$val} {
            @media (min-width: 1200px) {
                margin-right: #{$val}px;
            }
        }
    }
  
    @each $val in $right-negative {
        &-negative-#{$val} {
            margin-right: -#{$val}px;
        }
    }
  
    @each $val in $right-negative-md {
        &-negative-lg-#{$val} {
            @media (min-width: 768px) {
                margin-right: -#{$val}px;
            }
        }
    }

    @each $val in $right-negative-lg {
        &-negative-lg-#{$val} {
            @media (min-width: 992px) {
                margin-right: -#{$val}px;
            }
        }
    }

    @each $val in $right-negative-xl {
        &-negative-lg-#{$val} {
            @media (min-width: 1200px) {
                margin-right: -#{$val}px;
            }
        }
    }
}

.u-mb {
    $bottom: '10', '15', '16', '18', '12', '19', '17', '14', '27', '29', '24', '20', '26', '22', '28', '36', '30', '32', '33', '39', '35', '40', '43', '5', '57', '6', '8', '7';
    $bottom-sm: '58';
    $bottom-md: '17', '13', '25', '33', '32', '34', '36', '43', '16', '35', '50', '29', '28', '0', '73', '24';
    $bottom-lg: '25', '48', '0', '35', '42', '34', '32', '26', '58', '82';
  
    @each $val in $bottom {
        &-#{$val} {
            margin-bottom: #{$val}px;
        }
    }
  
    @each $val in $bottom-sm {
        &-sm-#{$val} {
            @media (min-width: 576px) {
                margin-bottom: #{$val}px;
            }
        }
    }
  
    @each $val in $bottom-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                margin-bottom: #{$val}px;
            }
        }
    }
  
    @each $val in $bottom-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                margin-bottom: #{$val}px;
            }
        }
    }
}

.u-pt {
    $top: '10', '12', '32', '18', '120', '28', '25', '22', '204', '20', '24', '33', '3', '34', '35', '37', '38', '31', '30', '40', '42', '47', '48', '5', '50', '59', '57', '54', '60', '64', '70', '71', '80';
    $top-md: '39', '70', '20', '34', '200', '30', '34', '20', '33', '45', '114', '24', '22', '28', '90', '53', '42', '149', '78', '32', '80', '88', '37';
    $top-lg: '90', '80', '40', '126', '46', '35', '33', '34', '81', '44', '61', '90', '103', '105', '24', '48', '30';
  
    @each $val in $top {
        &-#{$val} {
            padding-top: #{$val}px;
        }
    }
  
    @each $val in $top-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                padding-top: #{$val}px;
            }
        }
    }
  
    @each $val in $top-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                padding-top: #{$val}px;
            }
        }
    }
}

.u-pb {
    $bottom: '10', '100', '106', '21', '25', '20', '24', '3', '36', '34', '30', '32', '4', '40', '48', '50', '53', '57', '60', '64', '70', '71', '74', '80', '84', '85';
    $bottom-md: '80', '2', '41', '28', '42', '14', '48', '71', '72', '80', '98', '114';
    $bottom-lg: '96', '106', '3', '0', '70', '78', '45', '48', '26', '87', '64', '100', '95', '28', '94';
    $bottom-xl: '135';
  
    @each $val in $bottom {
        &-#{$val} {
            padding-bottom: #{$val}px;
        }
    }
  
    @each $val in $bottom-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                padding-bottom: #{$val}px;
            }
        }
    }
  
    @each $val in $bottom-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                padding-bottom: #{$val}px;
            }
        }
    }
  
    @each $val in $bottom-xl {
        &-lg-#{$val} {
            @media (min-width: 1200px) {
                padding-bottom: #{$val}px;
            }
        }
    }
}

.u-pl {
    $left: '16', '15', '10', '11', '2', '24', '34', '32', '3', '5', '6', '7', '8';
    $left-md: '16', '8', '30', '65', '14';
    $left-lg: '16', '0', '60', '10', '28';
    $left-xl: '30';
  
    @each $val in $left {
        &-#{$val} {
            padding-left: #{$val}px;
        }
    }
  
    @each $val in $left-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                padding-left: #{$val}px;
            }
        }
    }
  
    @each $val in $left-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                padding-left: #{$val}px;
            }
        }
    }
  
    @each $val in $left-xl {
        &-lg-#{$val} {
            @media (min-width: 1200px) {
                padding-left: #{$val}px;
            }
        }
    }
}

.u-pr {
    $right: '16', '15', '10', '11', '2', '24', '34', '32', '3', '5', '55', '6', '7', '8';
    $right-md: '16', '76', '8', '65', '14';
    $right-lg: '16', '76', '10', '28';
    $right-xl: '30';
  
    @each $val in $right {
        &-#{$val} {
            padding-right: #{$val}px;
        }
    }
  
    @each $val in $right-md {
        &-md-#{$val} {
            @media (min-width: 768px) {
                padding-right: #{$val}px;
            }
        }
    }
  
    @each $val in $right-lg {
        &-lg-#{$val} {
            @media (min-width: 992px) {
                padding-right: #{$val}px;
            }
        }
    }
  
    @each $val in $right-xl {
        &-lg-#{$val} {
            @media (min-width: 1200px) {
                padding-right: #{$val}px;
            }
        }
    }
}

@for $val from 8 through 100 {
    .u-fs-#{$val} {
        font-size: rem($val);
    }
    .u-lh-#{$val} {
        line-height: rem($val);
    }
}

@each $name, $value in $breakpoints-size {
    @for $val from 8 through 100 {
        .u-fs-#{$name}-#{$val} {
            @media #{$value} {
                font-size: rem($val);
            }
        }
        .u-lh-#{$name}-#{$val} {
            @media #{$value} {
                line-height: rem($val);
            }
        }
    }
}
