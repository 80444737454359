.t-productDetailInstruction {
    &_img {
        width: rem(48);
        height: auto;
        margin: 0 auto rem(14);

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &_panel {
        background-color: $aero012;
        border-radius: rem(10);
        padding: rem(38.5) rem(34) rem(22.5);
        margin-top: rem(31);

        @include desktop-down {
            padding: rem(35) rem(26) rem(17);
            margin-top: rem(20);
        }

        @include mobile-down {
            padding: rem(39) rem(20) rem(21);
            margin-top: rem(24);
        }
    }

    &_list {
        margin-top: rem(-30);

        @include desktop-down {
            margin-top: rem(-13);
        }

        @include mobile-down {
            margin-top: rem(-23);
        }
    }

    &_item {
        margin-top: rem(30);


        @include desktop-down {
            margin-top: rem(13);
        }

        @include mobile-down {
            margin-top: rem(23);
        }
    }
}
