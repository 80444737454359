.t-newsCategory {
    padding: rem(50) 0 rem(48);
    background-color: $cosmic-latte;

    &_title {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            border: rem(2) solid $vivid-cerulean;
            width: rem(43);
            right: rem(-43);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-reverse {
        background-color: $white;

        .row {
            flex-direction: row-reverse;
        }
    }

    &_item {
        margin-bottom: rem(16);
        @include mobile-down {
            margin-bottom: rem(24);
        }
    }

    &_carousel {
        .newsCategory-dots {
            margin-top: rem(16);
            max-width: fit-content;
            display: flex;

            @include mobile-down {
                margin-top: rem(8);
            }

            &_arrow {
                &-prev,
                &-next {
                    cursor: pointer;
                }
            }

            ul {
                width: 100%;
                display: flex;
                margin: 0 rem(53);

                @include desktop-down {
                    margin: 0 rem(24);
                }

                @include mobile-down {
                    margin: 0 rem(16);
                }

                li {
                    align-self: center;
                }
            }
        }
    }

    .divider {
        border-top: rem(1) solid $sonic-silver01;
        max-width: rem(168);
        margin-left: auto;
        margin-right: auto;
    }
}
