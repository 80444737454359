.t-popupSizeGuide {
    position: relative;
    border-radius: rem(10);

    &_menu {
        margin-left: auto;
        margin-right: auto;
        background: $white;
        width: max-content;
        position: relative;
        border-radius: rem(100);

        &-active {
            left: 0;
            top: rem(3);
            z-index: 1;
            width: 50%;
            position: absolute;
            border-radius: rem(100);
            height: calc(100% - rem(6));
            background-color: $dark-pink;
            transition: all $transition-long ease-in-out;

            &.full {
                width: 100%;
            }

            &.left:not(.full) {
                left: rem(3);
            }

            &.right:not(.full) {
                left: calc(50% - 3px);
            }
        }

        &-tab {
            z-index: 1;
            border-radius: rem(100);
            padding: rem(14) rem(88);
            cursor: pointer;
            @include adjust-flex(center, center);
            @include tablet-down {
                padding: rem(10) rem(50);
            }
            @include mobile-down {
                padding: rem(10) rem(30);
            }
            @include small-mobile {
                padding: rem(10) rem(15);
            }


            .a-paragraph-heading {
                min-width: max-content;
                transition: all $transition-long ease-in-out;
            }
        }
    }

    .a-inputRange {
        margin-bottom: rem(45);
        @include mobile-down {
            margin-bottom: rem(26);
        }
    }
   
    &_suggest {
        background: $white08;
        margin-top: rem(9);
        border-radius: rem(10);
        border: rem(1) solid $white036;
        padding: rem(27) 0 rem(27) rem(27);
        transition: all $transition-long ease-in-out;
        @include tablet-down {
            margin-top: 0;
            padding-left: rem(44);
        }
        @include mobile-down {
            margin-top: rem(15);
            padding: rem(22);
        }

        &.hidden {
            opacity: 0;
            visibility: hidden;
            @include tablet {
                height: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &-title {
            @include adjust-flex(flex-start, center);

            .a-paragraph-text {
                margin-left: rem(8);
            }
        }
    }

    &_table {
        position: relative;
        width: 100%;

        &-info {
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include tablet-down {
                width: 100%;
            }
        }

        &-img {
            width: 55%;
            right: 0;
            bottom: 0;
            position: absolute;
            @include tablet-down {
                width: 100%;
                position: relative;
            }
        }
    }

    &_thumbnailSize {
        text-align: center;

        img {
            max-width: 100%;
        }
    }
}
