.o-carousel {
    $root: &;
    position: relative;

    &_arrow {
        $arrow: &;
        border-radius: 50%;
        height: rem(50);
        transition: $transition-long ease;
        width: rem(50);
        z-index: 1;

        &::before {
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            background-size: rem(18);
            content: '';
            display: block;
            height: 100%;
            opacity: 1;
            transition: $transition-long ease;
            width: 100%;
        }

        &.black {
            background: $white;
            filter: drop-shadow(0 15px 20px $black003);

            &:hover {
                background-color: $vivid-cerulean;

                &::before {
                    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(287deg) brightness(104%) contrast(104%);
                }
            }
        }

        &.blueWhite {
            background: $vivid-cerulean;
            filter: drop-shadow(0 15px 20px $black003);

            @include mobile-down {
                height: rem(37);
                width: rem(37);

                &::before {
                    background-size: rem(13.5);
                }
            }

            &:hover {
                background: $white;

                &::before {
                    filter: invert(47%) sepia(88%) saturate(667%) hue-rotate(156deg) brightness(96%) contrast(108%);
                }
            }
        }

        &.whiteBlue {
            background: $white;

            &::before {
                background-size: 22px;
            }

            &:hover {
                background: $vivid-cerulean;

                &::before {
                    filter: brightness(0) invert(1);
                }
            }
        }


        &_prev {
            left: rem(-35);
  
            &.black,
            &.transparent {
                &::before {
                    background-image: url('~assets/icons/ic_prev_black.svg');
                }
            }

            &.transparentBlue {
                &::before {
                    background-size: rem(7) rem(14);
                    background-image: url('~assets/icons/ic_prev_blue.svg');
                }
            }

            &.blueWhite {
                &::before {
                    background-image: url('~assets/icons/ic_prev_white.svg');
                }
            }

            &.whiteBlue {
                &::before {
                    background-image: url('~assets/icons/ic_next_white_blue.svg');
                    transform: rotate(180deg);
                }
            }
        }

        &_next {
            right: rem(-35);
  
            &.black,
            &.transparent {
                &::before {
                    background-image: url('~assets/icons/ic_next_black.svg');
                }
            }

            &.transparentBlue {
                &::before {
                    background-size: rem(7) rem(14);
                    background-image: url('~assets/icons/ic_next_blue.svg');
                }
            }

            &.blueWhite {
                &::before {
                    background-image: url('~assets/icons/ic_next_white.svg');
                }
            }

            &.whiteBlue {
                &::before {
                    background-image: url('~assets/icons/ic_next_white_blue.svg');
                }
            }
        }

        &.slick-disabled {
            cursor: no-drop;

            &::before {
                opacity: 0.5;
            }
        }
    }

    .slick-slide {
        outline: none;

        div {
            &:focus {
                outline: 0;
            }
        }
    }

    .slick-dots {
        bottom: 0;
        display: flex !important;
        position: relative;
        overflow: auto;
        max-width: rem(135);
        width: fit-content;
        transition: $transition-long linear;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            flex-shrink: 0;
            display: block;
            height: auto;
            margin: 0 rem(5);
            width: auto;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &-outDots {
        .slick-dots {
            margin: rem(13) auto 0;
        }
    }

    &-innerDots {
        .slick-dots {
            bottom: rem(21);
            margin-top: 0;
            position: absolute;
        }
    }

    &_dot {
        &.circle-white {
            position: relative;
            display: block;
            height: rem(23);
            width: rem(23);

            &::before {
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                background-color: transparent;
                border: 0 solid $white;
                height: 0;
                width: 0;
                border-radius: 50%;
                content: '';
                transition: $transition-long ease-in-out;
            }

            &::after {
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                background-color: $white065;
                height: rem(9);
                width: rem(9);
                border-radius: 50%;
                content: '';
                transition: $transition-long ease-in-out;
            }
        }

        &.circle-black {
            position: relative;
            display: block;
            height: rem(17);
            width: rem(17);

            &::before {
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                background-color: transparent;
                border: 0 solid $raisin-black;
                height: 0;
                width: 0;
                border-radius: 50%;
                content: '';
                transition: $transition-long ease-in-out;
            }

            &::after {
                position: absolute;
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                background-color: $raisin-black02;
                height: rem(9);
                width: rem(9);
                border-radius: 50%;
                content: '';
                transition: $transition-long ease-in-out;
            }
        }
    }

    .slick-active {
        #{$root}_dot {
            &.circle-white {
                &::before {
                    border-width: 1px;
                    width: rem(22);
                    height: rem(22);
                }

                &::after {
                    width: rem(13);
                    height: rem(13);
                    background-color: $white;
                }
            }

            &.circle-black {
                &::before {
                    border-width: 1px;
                    width: rem(16);
                    height: rem(16);
                }

                &::after {
                    background-color: $raisin-black;
                }
            }
        }
    }
    
    &-equalHeight {
        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: inherit;

            > div,
            .o-carousel_wrap,
            .o-carousel_item {
                > div {
                    height: 100%;
                }
            }
        }
    }

}
