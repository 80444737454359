.o-player {
    $root: &;
    position: relative;


    &_layer {
        background-color: $white;
        position: relative;
        z-index: 1;
        cursor: pointer;

        &-playing {
            z-index: -1;
            opacity: 0;
        }
    }

    &_video {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &-playing {
            z-index: 1;
        }

        video,
        iframe,
        &-youtube {
            width: 100%;
            height: 100%;
            object-fit: fill;

            &::-webkit-media-controls-timeline {
                display: none;
            }
        }
    }

    &_player-icon {
        position: absolute;
        top: calc(50% - 47px);
        left: calc(50% - 47px);
        border-radius: 50%;
        width: rem(94);
        height: rem(94);
        background-color: $white036;
        transition: 0.3s;
        @include adjust-flex();

        &::before {
            content: '';
            position: absolute;
            width: rem(70);
            height: rem(70);
            border-radius: 50%;
            top: rem(12);
            left: rem(12);
            background-color: $white04;
        }

        .img {
            width: rem(19);
            position: relative;
        }

        &:hover {
            box-shadow: 0 0 10px 2px rgba($color: $black, $alpha: 0.15);
        }
    }
}
