.t-checkoutInfo {
    $root: &;
    border-radius: rem(10);
    overflow: hidden;

    .pointer {
        cursor: pointer;
    }

    &_title {
        background: $aero065;
        padding: rem(25) rem(34) rem(21);

        @include small-mobile {
            padding: rem(25) rem(20) rem(21);
        }
    }

    &_wrap {
        background: $aero012;
        padding: rem(16) rem(35) rem(28);

        @include small-mobile {
            padding: rem(28) rem(20) rem(38);
        }
    }

    &_infomationLine {
        @include adjust-flex(space-between, center);
    }

    &_info {
        &_code {
            opacity: 0.7;
        }
    }

    &_divider {
        background: $raisin-black;
        height: rem(1);
        opacity: 0.1;
        width: 100%;
    }

    &_wrapGeneral {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &_wrapSub {
        width: 100%;
        max-width: rem(323);
        @include desktop-up {
            max-width: 100%;
        }
    }
    
    &_status {
        #{$root}-success & {
            color: $celadon-blue;
        }
        #{$root}-error & {
            color: $electric-crimson;
        }
    }

    &_submitCode {
        &_general {
            border-radius: rem(500);
            // border: 1px solid $celadon-blue;
            border: 1px solid rgba(0, 0, 0, 0.36);
            background-color: $white;
            position: relative;
            overflow: hidden;
            padding: rem(2);
            @include adjust-flex(flex-start, stretch);
            #{$root}-success & {
                border-color: $celadon-blue;
            }
            #{$root}-error & {
                border-color: $electric-crimson;
            }

            input {
                border: 0;
                @include font-base(16, 21);
                width: 100%;
                padding: rem(12) rem(5) rem(12) rem(24);
                font-family: inherit;

                &:focus {
                    outline: none;
                }

                &:placeholder-shown {
                    color: $raisin-black;
                }
            }

            button {
                font-family: inherit;
                padding: rem(15) rem(30);
                border-radius: rem(500);
                background-color: $raisin-black;
                color: #fff;
                border: 0;
                text-transform: uppercase;
                min-width: rem(128);
                @include adjust-flex(center, center);
                @include font-base(14, 17);
            }
        }
    }
}
