.a-colorpicker {
    $root: &;
  
    &_body {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border: 1px solid transparent;
        border-radius: 50%;
        transition: $transition ease;
        cursor: pointer;
  
        #{$root}_input:checked:not(:disabled) + & {
            border-color: $vivid-cerulean;
        }
  
        #{$root}_input:hover:not(:disabled) + & {
            border-color: $vivid-cerulean;
            cursor: pointer;
        }
        #{$root}_input:disabled + & {
            pointer-events: none;
            opacity: 0.5;
        }
        
        img {
            border-radius: 50%;
        }
       
    }

    &-sm {
        #{$root}_body {
            width: rem(29);
            height: rem(29);

           
            img {
                width: rem(23);
                height: rem(23);
            }
            @include mobile-down {
                width: rem(17);
                height: rem(17);
    
                img {
                    width: rem(13);
                    height: rem(13);
                }
            }
        }
      
    }

    &-md {
        #{$root}_body {
            width: rem(36);
            height: rem(36);

            img {
                width: rem(30);
                height: rem(30);
            }
            
            @include mobile-down {
                width: rem(29);
                height: rem(29);
    
                img {
                    width: rem(23);
                    height: rem(23);
                }
            }
        }
    }
    
}
