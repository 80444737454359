.popupGallery {
    &_carouselBottom {
        max-width: rem(128 * 5);
        margin: rem(16) auto 0;

        .o-carousel {
            &_arrow {
                &_prev {
                    left: rem(-64);
                }
                
                &_next {
                    right: rem(-64);
                }
            }
        }

        @include mobile-down {
            max-width: rem(128 * 4);
        }

        @include small-mobile {
            max-width: rem(64 * 4);
        }

        &-item {
            padding: 0 rem(4);
            max-width: rem(128);
            margin: auto;
            cursor: pointer;

            @include small-mobile {
                width: rem(64);
            }
        }
    }
}
