.t-checkoutNoted {
    border-radius: rem(10);
    background-color: $white;
    padding: rem(24) rem(65);
    overflow: hidden;

    @include tablet-down {
        padding: rem(24) rem(37) rem(30);
    }

    @include mobile-down {
        padding: rem(32) rem(24) rem(34);
    }
}
