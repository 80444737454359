.o-comment {
    &_textarea {
        position: relative;
        border: 1.5px solid $celadon-blue057;
        padding: rem(13) rem(18) rem(8);
        border-radius: rem(10);
        @include mobile-down {
            padding: rem(25) rem(23) rem(12);
        }

        textarea {
            appearance: none;
            font-family: inherit;
            margin: 0;
            outline: none;
            resize: none;
            width: 100%;
            border: 0;
            padding: 0;
            @include font-base(16,19);
            color: $raisin-black;

            &::placeholder {
                color: $raisin-black05;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &_btn {
        min-width: rem(134);
        width: fit-content;
    }
}
