.p-contact {
    padding: rem(80) 0 rem(104);

    &_left {
        width: 100%;
        max-width: rem(548);

        @include max-desktop-down {
            max-width: rem(480);
        }

        @include desktop-down {
            max-width: 100%;
        }
    }
}
