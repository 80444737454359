.t-aboutuskols {
    $root: &;
    position: relative;
    padding-top: rem(133);
    padding-bottom: rem(185);
    @include tablet-down {
        padding-top: rem(84);
        padding-bottom: rem(145);
    }

    @include mobile-down {
        padding-top: rem(100);
        padding-bottom: rem(119);
    }

    .o-container {
        position: relative;
    }

    &_background {
        &-left {
            position: absolute;
            top: rem(15);
            left: rem(10);
            width: rem(304);
            height: rem(304);
            z-index: -1;

            @include tablet-down {
                width: rem(238);
                height: rem(238);
                left: rem(-60);
                top: rem(40);
            }

            @include mobile-down {
                width: rem(182);
                height: rem(182);
                left: 66%;
                top: 1%;
            }
        }

        &-right {
            position: absolute;
            bottom: rem(-131);
            right: rem(-77);
            width: rem(421);
            height: rem(421);
            z-index: -1;

            @include tablet-down {
                bottom: rem(-98);
                right: rem(-110);
                width: rem(343);
                height: rem(343);
            }

            @include mobile-down {
                display: none;
            }
        }
    }

    &_tag {
        margin-bottom: rem(16);
        @include mobile-down {
            margin-bottom: rem(6);

            .a-paragraph-text {
                text-align: start;
            }
        }
    }

    &_heading {
        margin-bottom: rem(64);

        @include tablet-down {
            margin-bottom: rem(46);
        }

        @include mobile-down {
            margin-bottom: rem(3);

            .a-paragraph-heading {
                text-align: start;
            }
        }
    }

    &_item {
        &:nth-child(even) {
            padding-top: rem(32);
            @include tablet-down {
                padding-top: rem(45);
            }
        }

        @include small-mobile {
            width: rem(256);
        }

        @include break-max(359px) {
            width: rem(200);
        }
    }

    .o-carousel {
        .slick-list {
            margin-left: rem(-16);
            margin-right: rem(-16);

            @include tablet-down {
                margin-right: rem(-7.5);
                margin-left: rem(-7.5);
            }

            @include mobile-down {
                margin-right: rem(-5);
                margin-left: rem(-5);
            }
        }

        &_item {
            padding-right: rem(16);
            padding-left: rem(16);

            @include tablet-down {
                padding-right: rem(7.5);
                padding-left: rem(7.5);
            }

            @include mobile-down {
                padding-right: rem(5);
                padding-left: rem(5);
            }
        }

        &_arrow {
            &_prev {
                left: rem(-26);

                @include mobile-down {
                    left: 0;
                    top: 138%;
                    transform: translateY(-150%);
                    width: rem(37);
                    height: rem(37);
                    background-color: $vivid-cerulean;
                }

                @include break-max(359px) {
                    top: 150%;
                }
            }

            &_next {
                right: rem(-24);

                @include mobile-down {
                    left: rem(47);
                    top: 138%;
                    transform: translateY(-150%);
                    width: rem(37);
                    height: rem(37);
                    background-color: $vivid-cerulean;
                }

                @include break-max(359px) {
                    top: 150%;
                }
            }
        }
    }

    .slick-slide {
        &:nth-child(even) {
            padding-top: rem(32);

            @include tablet-down {
                padding-top: rem(45);
            }
            
            @include mobile-down {
                padding-top: 0;
            }
        }
    }
}
