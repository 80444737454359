.t-aboutUsMission {
    padding-bottom: rem(85);

    &_thumbnail {
        position: relative;

        &::before {
            background: url('~assets/images/aboutUsMission/bg.png') no-repeat;
            background-size: contain;
            content: '';
            position: absolute;
            right: 2.5%;
            top: -3%;
            max-width: rem(216);
            width: 100%;
            height: 40%;
            pointer-events: none;

            @include max-desktop-down {
                right: -1.5%;
            }

            @include mobile-down {
                max-width: rem(119);
            }
        }

        &_img {
            max-width: calc(100% * 5/6);
        }
    }

    &_list {
        @include adjust-flex(center, flex-start);
        flex-wrap: wrap;
        margin: rem(-23) rem(-10);

        @include max-desktop-down {
            margin: rem(-16) rem(-10);
        }

        &_item {
            flex: 0 0 calc(100% / 3);
            padding: rem(23) rem(10);

            @include max-desktop-down {
                padding: rem(16) rem(10);
            }

            @include mobile-down {
                flex: 0 0 50%;
            }
        }
    }

    .t-cardInfo img {
        width: rem(37);
    }
}
