@use 'sass:math';

@mixin text-overflow ($number: 2) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: ($number);
    overflow: hidden;
    text-overflow: ellipsis;
}


@mixin aspect-ratio($width: 1, $height: 1) {
    padding-bottom: math.div($height, $width) * 100%;
}

@mixin transition($value) {
    transition: $value;
}

@mixin scroll-bars(
    $size: 4,
    $thumb-color: $vivid-cerulean, 
    $track-color: $raisin-black02, 
    $border-radius-track: 100,
    $border-radius-thumb: 100,
    $border-thumb: 2
) {
    // For Internet Explorer
    scrollbar-face-color: $thumb-color;
    scrollbar-track-color: $track-color;

    // For Google Chrome
    &::-webkit-scrollbar {
        height: rem($size);
        width: rem($size);
    }

    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border: rem($border-thumb) solid $track-color;
        border-radius: rem($border-radius-thumb);
    }

    &::-webkit-scrollbar-track {
        background: $track-color;
        border-radius: $border-radius-track;
    }
}

@mixin font-base($font-size: 16, $line-height: 24) {
    font-size: rem($font-size);
    line-height: rem($line-height);
}

@mixin style-bg-color() {
    &-bg-white {
        background-color: $white;
    }
}

@mixin style-font-weight() {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: normal;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: bold;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }
}

@mixin style-general() {
    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-underline {
        text-decoration: underline;
    }

    &-italic {
        font-style: italic;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }

    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-lineThrough {
        text-decoration: line-through;
    }

    &-nowrap {
        white-space: nowrap;
    }
}

@mixin reset-button {
    appearance: none;
    background-color: none;
    border: 0;
    outline: none;
}

@mixin list-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
    align-items: $align-item;
    display: flex;
    justify-content: $justify-content;
}

$colors-design: (
    'white': $white,
    'black': $black,
    'gray': $gray,
    'raisinBlack': $raisin-black,
    'raisinBlack015':$raisin-black015,
    'raisinBlack05': $raisin-black05,
    'raisinBlack07': $raisin-black07,
    'raisinBlack075': $raisin-black075,
    'raisinBlack06': $raisin-black06,
    'raisinBlack03': $raisin-black03,
    'celadonBlue': $celadon-blue,
    'verdigris': $verdigris,
    'vividCerulean': $vivid-cerulean,
    'raisinBlack08': $raisin-black08,
    'electricCrimson': $electric-crimson,
    'spanishCrimson075': $spanish-crimson075,
    'richElectricBlue': $rich-electric-blue,
    'richElectricBlue075': $rich-electric-blue-075,
    'cyanCornflower': $cyan-cornflower,
    'spanishCrimson': $spanish-crimson,
    'white07': $white07,
    'white09': $white09,
    'pastelGray': $pastel-gray,
    'cyan': $cyan,
    'jet': $jet,
    'richBlack': $rich-black,
    'darkLiver': $dark-liver,
    'sonicSilver025': $sonic-silver025,
    'darkGray': $dark-gray,
    'taupeGray': $taupe-gray,
    'raisinBlack1': $raisin-black1,
);


@mixin style-color {
    @each $name, $color in $colors-design {
        &-#{$name} {
            color: $color;
        }
    }
}

@mixin style-letter-spacing() {
    &-s01 {
        letter-spacing: 0.01em;
    }

    &-s03 {
        letter-spacing: 0.03em;
    }

    &-s06 {
        letter-spacing: 0.06em;
    }

    &-s03 {
        letter-spacing: 0.03em;
    }

    &-s5 {
        letter-spacing: 0.5em;
    }
}

$font-family: (
    'neueKabel': $font-neue-kabel,
);

@mixin style-font-family {
    @each $name, $font in $font-family {
        &-#{$name} {
            font-family: $font;
        }
    }
}
