.nav-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    height: rem(60);
    box-shadow: 0 rem(-15) rem(10) $black005;
    @include adjust-flex(center, stretch);
    z-index: $z-header-nav;
    transition: all $transition ease-in-out;

    &_element {
        @include adjust-flex(center, center);
        flex-grow: 1;
        -webkit-transition: background-color $transition;
        -o-transition: background-color $transition;
        transition: background-color $transition;

        &.active {
            background-color: $vivid-cerulean01;

            img {
                filter: invert(51%) sepia(15%) saturate(7020%) hue-rotate(163deg) brightness(100%) contrast(101%);
            }
        }
    }

    @include mobile-up {
        display: none;
    }
}
