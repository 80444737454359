
.t-productDetailSlider {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-10.5);

    &_left {
        flex: 0 0 95px;
        max-width: 95px;
        padding: 0 rem(10.5);
        @include desktop {
            flex: 0 0 87%;
            max-width: 87%;
            order: 2;
            margin: rem(12) auto 0;
        }
        @include mobile-down {
            flex: 0 0 87%;
            max-width: 87%;
            order: 2;
            margin: rem(12) auto 0;
        }
    }

    &_right {
        flex: 0 0 calc(100% - 95px);
        max-width: calc(100% - 95px);
        padding: 0 rem(10.5);
        @include desktop {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_thumbnail {
        &_item {
            cursor: pointer;
            
            &.video {
                position: relative;

                &::after {
                    content: '';
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: rem(16);
                    height: rem(16);
                    background: url('~assets/icons/ic_play_blue.svg') no-repeat center / contain;
                }
            }
        }

        .slick {
            &-track {
                display: flex;
                height: auto !important;
                flex-direction: column;
                @include desktop {
                    flex-direction: row;
                }
                @include mobile-down {
                    flex-direction: row;
                }
            }

            &-list {
                margin: rem(-8) 0;
                @include  max-desktop-down {
                    margin: rem(-5) 0;
                }
                @include desktop-down {
                    margin: rem(-8) 0;
                }
                @include desktop {
                    margin: 0 rem(-4);
                }
                @include mobile-down {
                    margin: 0 rem(-4);
                }
            }

            &-slide {
                padding: rem(8) 0;
                opacity: 0.6;
                @include  max-desktop-down {
                    padding: rem(5) 0;
                }
                @include desktop-down {
                    padding: rem(8) 0;
                }
                @include desktop {
                    padding: 0 rem(4);
                }
                @include mobile-down {
                    padding: 0 rem(4);
                }

                &.slick-current {
                    opacity: 1;
                }
            }
        }

        .o-carousel_arrow {
            width: rem(20);
            height: rem(40);

            &_next {
                right: rem(-25);
            }

            &_prev {
                left: rem(-25);
            }
        }
    }

    &_main {
        &_item {
            &.video {
                .o-player_video {
                    display: flex;
                    align-items: center;
                }

                video {
                    height: auto;
                }
            }
        }
    }
}
