.a-paragraph-text {
    $root: &;
    @include style-color();
    @include style-font-weight();
    @include style-general();
    @include style-letter-spacing();
    @include style-font-family();
    text-decoration-color: inherit;
    margin: 0;
    transition: $transition ease;

    &-inline {
        display: inline-block;
    }
}
