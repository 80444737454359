@import './HeaderElements';
@import './NavTop';
@import './NavBottom';
@import './NavSider';
@import './Search';
@import './NavSearch';

.t-header {
    position: relative;
    background-color: $white;

    @include desktop-up {
        position: sticky;
        top: 0;
        z-index: $z-header;
    }

    &_topBackground {
        background: url('~assets/images/header/header_bg_top.jpg') no-repeat left / cover;

        @include tablet-up {
            background-position: center;
        }

        @include mobile-down {
            background: linear-gradient(to right, $vivid-cerulean 50%, $raisin-black 50%);
        }
    }

    &_flexBetween {
        @include adjust-flex(space-between, center);
    }

    &_contact {
        height: rem(39);

        @include mobile-up {
            height: rem(50);
        }
    }

    &_contactLeft {
        background-color: $vivid-cerulean;
        height: 100%;

        img {
            width: rem(10);
            margin-right: rem(5);
        }

        @include adjust-flex(flex-start, center);

        @include mobile-down {
            flex-grow: 1;
        }

        @include tablet {
            width: 85%;

        }

        @include desktop {
            flex-grow: 1;
        }

        @include desktop-up {
            padding-right: rem(121);

        }
    }

    &_contactRight {
        @include adjust-flex(flex-end, center);
        flex-grow: 1;

        &_element {
            margin-left: rem(45);

            @include max-desktop-down {
                margin-left: rem(16);
            }

            &-desktop {
                @include desktop-down {
                    display: none;
                }
            }

            &-tabletDesktop {
                display: none;

                @include tablet-desktop {
                    display: block;
                }
            }

            &-mobileTablet {
                @include desktop-up {
                    display: none;
                }
            }

            &-search {
                max-width: rem(366);
                width: 100%;
            }
        }

        &_btn {
            @include adjust-flex();
            @include reset-button();
            @include font-base(16, 19);
            color: $white;
            font-family: inherit;
            background-color: transparent;
            padding: 0;
            white-space: nowrap;

            .a-icon {
                margin-right: rem(3);
            }
        }
    }

    &_navWrapper {
        height: rem(68);
        // Hide because the logo size has changed
        // @include mobile-up {
        //     height: rem(83);
        // }
    }

    &_logo {
        max-width: rem(140);
        width: 100%;

        @include desktop-up {
            max-width: rem(184);
        }
    }

    &_actions {
        @include adjust-flex(flex-end, center);

        &_element {
            margin-left: rem(8);

            &-desktop {
                @include mobile-down {
                    display: none;
                }
            }

            &-mobile {
                @include mobile-up {
                    display: none;
                }
            }
        }
    }
}
