.t-popupAddressList {
    &-list {
        overflow-y: auto;
        @include scroll-bars();
        max-height: rem(480);
        padding: rem(32) rem(32) rem(42);
    }

    &-item {
        &:not(:first-child) {
            margin-top: rem(58);
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
}
