.a-paragraph-heading {
    $root: &;
    @include style-color();
    @include style-font-weight();
    @include style-general();
    @include style-letter-spacing();
    @include style-font-family();
    margin: 0;
    transition: $transition ease;
}
