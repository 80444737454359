.t-showroomLocation {
    background-color: $seashell;
    padding-top: rem(86);
    padding-bottom: rem(112);

    @include desktop-down {
        padding-bottom: rem(80);
    }

    @include mobile-down {
        padding-top: rem(44);
        padding-bottom: rem(56);
    }

    &_hiddenDesktopUp {
        @include desktop-up {
            display: none;
        }
    }

    &_hiddenDesktopDown {
        @include desktop-down {
            display: none;
        }
    }

    &_area {
        @include adjust-flex(flex-start, center);
        padding-top: rem(20);
        margin-bottom: rem(26);

        @include mobile-up {
            padding-top: rem(17);
            margin-bottom: rem(22);
        }

        @include desktop-up {
            padding-top: rem(14);
            margin-bottom: rem(51);
        }
    }

    &_areaLabel {
        margin-right: rem(14);
    }

    &_areaSelector {
        min-width: rem(171);
    }

    &_locationList {
        overflow-y: auto;
        max-height: rem(516);
        padding-right: rem(16);
        @include scroll-bars(4, $vivid-cerulean, $sonic-silver02, 100, 100, 0);


        @include mobile-up {
            max-height: rem(287);
            padding-right: rem(58);
        }

        &_element {
            cursor: pointer;

            &:not(:first-child) {
                padding-top: rem(15);
            }

            @include mobile-up {
                padding-bottom: rem(30);
                padding-right: rem(16);

                &:not(:first-child) {
                    border-top: rem(1) solid $raisin-black01;
                    padding-top: rem(30);
                }
            }

            @include desktop-up {
                padding-right: rem(18);
            }

            &:hover {
                .t-showroomLocation_locationList_title {
                    p {
                        color: $vivid-cerulean;
                    }
                }
            }
        }

        &_iconContentWrapper {
            @include adjust-flex(flex-start, center);
            margin-bottom: rem(4);
        }

        &_title {
            @include mobile-down {
                margin-bottom: rem(10);
            }
        }

        &_address {
            @include mobile-down {
                margin-bottom: rem(15);
            }
        }

        &_icon {
            margin-right: rem(10);
        }
    }

    &_locationMapWrapper {
        @include adjust-flex(flex-start, flex-end);
    }

    &_locationMap {
        width: 100%;
        height: rem(420);
        margin-bottom: rem(35);
        display: flex;

        iframe {
            width: 100%;
            height: auto !important;
        }

        @include mobile-up {
            margin-bottom: rem(55);
        }

        @include desktop-up {
            margin-bottom: 0;
        }
    }

    &_locationMarker {
        cursor: pointer;
        width: rem(28);
        position: absolute;
        transform: translate(-50%, -50%);
        margin-left: rem(2);
        margin-top: rem(-14);
    }

    .gm-style-cc {
        display: none;
    }

    .gmnoprint {
        transform-origin: top left;
        transform: scale(0.8);
    }
}
