.nav-top {
    height: 100%;

    &_list {
        position: relative;
        list-style: none;
        height: 100%;
        z-index: 20;
        @include adjust-flex(center, stretch);

        &-desktop {
            li {
                border-bottom: rem(2) solid transparent;
                -webkit-transition: border $transition;
                -o-transition: border $transition;
                transition: border $transition;

                a,
                span {
                    height: 100%;
                    @include adjust-flex(center, center);
                    -webkit-transition: color 0.1s;
                    -o-transition: color 0.1s;
                    transition: color 0.1s;
                }

                @include adjust-flex(center, center);

                &:hover {
                    border-color: $vivid-cerulean;

                    span {
                        color: $vivid-cerulean;
                    }
                    
                }

                &.high-light-menu:hover {
                    border-color: #f00;

                    span {
                        color: #f00;
                    }
                }

                @include tablet-up {
                    margin: 0 rem(8);
                }

                @include desktop-up {
                    margin: 0 rem(16);
                }

                @include break-min(1400px) {
                    margin: 0 rem(24);
                }

                @include break-min(1600px) {
                    margin: 0 rem(34);
                }
            }

            .active {
                color: $vivid-cerulean;
                border-color: $vivid-cerulean;

                &.high-light-menu {
                    border-color: #f00;
                    color: #f00;
                }
            }

            @include desktop-down {
                display: none;
            }
        }
    }

    &_menu {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        max-width: rem(1360);
        min-height: rem(443);
        background-color: $white;
        margin: 0;
        padding: rem(31) rem(32) rem(37);
        border-radius: 0 0 rem(10) rem(10);
        @include adjust-flex(flex-start, flex-start);
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        -webkit-transform: translate(-50%, rem(-25));
        -ms-transform: translate(-50%, rem(-25));
        transform: translate(-50%, rem(-25));
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;

        &-opened {
            z-index: 10;
            opacity: 1;
            visibility: visible;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }

        &_element {
            @include list-style-none();

            &.block {
                flex-grow: 1;
                margin-left: rem(24);
                padding-top: rem(24);

                ul {
                    list-style: none;
                }

                .a-paragraph-text {
                    margin-bottom: rem(12);
                }
            }

            &.block_image {
                width: rem(284);
                height: rem(375);

                @include desktop-down {
                    max-width: rem(200);
                }
            }

            &.block_menu_image {
                margin-left: rem(62);
                margin-right: rem(16);
                max-width: rem(232);
                padding-top: rem(24);

                @include max-desktop-down {
                    margin-left: rem(40);
                    max-width: rem(200);
                }

                @include desktop-down {
                    margin-left: 0;
                    max-width: unset;
                }

                ul {
                    list-style: none;
                    padding-top: rem(11);
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: rem(-16);
                    margin-right: rem(-16);
                }

                .menu_item {
                    @include adjust-flex(flex-start, center);
                    flex-direction: column;
                    margin-bottom: rem(26);
                    flex: 0 0 50%;
                    padding-left: rem(16);
                    padding-right: rem(16);

                    .a-image {
                        border-radius: 50%;
                        width: rem(84);
                        height: rem(84);
                        margin-bottom: rem(4);

                        @include max-desktop-down {
                            width: rem(72);
                            height: rem(72);
                        }
                    }
                    
                }
            }

            .link:hover {
                .a-paragraph-text {
                    color: $vivid-cerulean;
                }
            }
        }

        @include desktop-down {
            display: none;
        }
    }

    .high-light-menu {
        font-weight: bold;
        color: #f00;
    }
}
