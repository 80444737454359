.t-collectionmediavideo {
    $root: &;
    position: relative;
  
    min-height: rem(504);
    margin-top: rem(36);

    @include break-max($standard-viewport) {
        min-height: rem(461);
    }

    @include max-desktop-down {
        min-height: rem(428);
    }

    @include desktop-down {
        min-height: rem(370);
    }

    @include tablet-down {
        min-height: rem(305);
    }

    @include break-max(800px) {
        min-height: auto;
    }

    .o-container {
        position: relative;

        @include desktop-down {
            position: static;
        }
    }

    &-no-video {
        margin-bottom: rem(35);
        min-height: unset;
    }

    &_left {
        padding-left: rem(84);
        padding-top: rem(87);
        padding-bottom: rem(97);

        @include desktop-down {
            padding-bottom: rem(79);
        }
    
        @include break-max(1399px) {
            padding-top: 0;
            padding-left: 0;
        }

        @include break-max(800px) {
            padding-bottom: 0;
        }    
    

        #{$root}_text {
            max-width: rem(350);

            @include desktop-down {
                max-width: rem(283);
                padding-bottom: rem(24);
            }

            @include tablet-down {
                max-width: rem(168);
            }

            
            @include break-max(800px) {
                max-width: 100%;
            }

            &-no-video {
                max-width: 100%;
            }
        }
    }

    &_title {
        margin-bottom: rem(10);
    }

    &_right {
        position: absolute;
        width: 100%;
        top: 0;
        max-width: rem(896);
        right: rem(12);
        z-index: 3;
        @include break-min($standard-viewport) {
            right: rem(120);
        }

        @include break-max($standard-viewport) {
            max-width: rem(820);
        }

        @include max-desktop-down {
            max-width: rem(762);
        }

        @include desktop-down {
            max-width: rem(658);
            right: 0;
        }

        @include tablet-down {
            max-width: rem(543);
        }

        @include break-max(800px) {
            position: relative;
            max-width: 100%;
        }
    }
}
