.t-saleCategory {
    &_cate {
        margin: rem(-16);

        @include mobile-down {
            margin: rem(-5);
        }

        &-item {
            padding: rem(16);

            @include mobile-down {
                padding: rem(5);
            }
        }
    }

    &_listProduct {
        @include adjust-flex(flex-start, stretch);
        flex-wrap: wrap;

        margin-left: rem(-10);
        margin-right: rem(-10);

        @include desktop-down {
            margin-left: rem(-8);
            margin-right: rem(-8);
        }

        @include mobile-down {
            margin-left: rem(-5);
            margin-right: rem(-5);
        }

        &_item {
            flex: 0 0 calc(100% / 5);
            padding: rem(22) rem(10);
            // min-width: rem(257);

            @include desktop-down {
                padding: rem(32) rem(8);
                flex-basis: calc(100% / 4);
                // min-width: rem(213);
            }

            @include tablet-down {
                flex-basis: calc(100% / 3);
            }
    
            @include mobile-down {
                flex-basis: calc(100% / 2);
                padding: rem(40) rem(5);
                // min-width: rem(155);
            }
        }
    }

}
