.p-products {
    .divider {
        border-top: 1px solid $raisin-black01;
    }

    .wrapper {
        margin-top: rem(11);
        @include desktop-up {
            @include adjust-flex(space-between, center);
        }
    }

    .description {
        @include desktop-up {
            max-width: rem(500);
        }
    }

    .option {
        @include adjust-flex(space-between, center);
        @include desktop-down {
            margin-top: rem(32);
        }

        @include mobile-down {
            margin-top: rem(24);
        }
    }

    .filter,
    .sort {
        @include adjust-flex(center, center);
    }

    .filter {
        cursor: pointer;
        @include desktop-up {
            display: none;
        }
    }

    .sort {
        min-width: rem(220);
        @include mobile-down {
            min-width: rem(160);
        }

        &-text {
            flex-shrink: 0;
            margin-right: rem(12);
            @include mobile-down {
                display: none;
            }
        }
    }

    .product {
        @include break-min(1600px) {
            flex: 0 0 auto;
            width: 25%;
        }
    }

    &_content {
        img,
        video {
            max-width: 100%;
            height: auto !important;
        }

        ul,
        ol {
            margin: revert;
            padding: revert;
        }
    }
}
