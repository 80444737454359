.p-checkout {
    overflow: initial !important;
    padding: rem(30) 0 rem(50);

    @include mobile-down {
        padding-top: rem(40);
    }

    &_infoWrapper {
        @include desktop-up {
            position: -webkit-sticky;
            position: sticky;
            top: rem(145);
        }
    }
}
