.t-collectionCombo {
    background: $aero073;
    padding: rem(44) 0 rem(47);
    @include  tablet {
        padding: rem(37) 0 rem(27);
        background: $aero073  url('~assets/images/flower.png') left bottom no-repeat;
    }
    
    @include mobile-down {
        padding: rem(50) 0 rem(56);
    }

    &_content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include tablet-down {
            align-items: flex-start;
        }
    }

    &_left {
        flex: 0 0 25.5%;
        max-width: 25.5%;
        margin-right: 12%;
        @include max-desktop-down {
            flex: 0 0 22.5%;
            max-width: 22.5%;
            margin-right: 7%;
        }
        @include desktop-down {
            flex: 0 0 calc(25% - 30px);
            max-width: calc(25% - 30px);
            margin-right: rem(30);
        }
        @include tablet-down {
            flex: 0 0 37%;
            max-width: 37%;
            margin-right: 10%;
        }
        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &_right {
        flex: 0 0 62.5%;
        max-width: 62.5%;
        @include max-desktop-down {
            flex: 0 0 67.5%;
            max-width: 67.5%;
        }
        @include desktop-down {
            flex: 0 0 75%;
            max-width: 75%;
        }
        @include tablet-down {
            flex: 0 0 53%;
            max-width: 53%;
        }
        @include mobile-down {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: rem(52);
        }
    }

    &_combo {
        button {
            min-width: rem(160);
            width: fit-content;
        }
    }

    &_descCombo {
        margin-bottom: rem(27);
        @include text-overflow(4);
        @include tablet-down {
            margin-top: rem(45);
        }

        @include mobile-down {
            margin-top: 0;
        }
    }

    &_price {
        margin-top: rem(16);
        @include tablet-desktop {
            margin-top: rem(11);
        }
        margin-bottom: rem(10);
    }

    &_list {
        display: flex;
        align-items: center;
        margin: 0 rem(-40);
        flex-wrap: wrap;
        @include max-desktop-down {
            margin: 0 rem(-20);
        }
        @include desktop-down {
            margin: 0 rem(-10);
        }
    }

    &_item {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 rem(40);
        @include max-desktop-down {
            padding: 0 rem(20);
        }
        @include desktop-down {
            padding: 0 rem(10);
        }
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &:last-child {
            @include tablet-down {
                margin-top: rem(27);
            }
            @include mobile-down {
                margin-top: rem(35);
            }
        }
    }

}
