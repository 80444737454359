.nav-sider {
    @include desktop-up {
        display: none;
    }

    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    &_backdrop {
        background-color: $raisin-black05;

        &-close {
            transform: translateX(-110%);
        }

        &-open {
            z-index: 300;
        }
    }

    &_wrapper {
        z-index: 310;
        background-color: $white;
        padding: rem(32);
        max-width: rem(257);
        transition: transform $transition-long ease-in-out;

        @include mobile-up {
            max-width: rem(430);
        }

        &-close {
            transform: translateX(-110%);
        }
    }

    &_top {
        @include adjust-flex(space-between, center);
        margin-bottom: rem(32);
    }

    &_logo {
        width: rem(151);
        height: rem(23);
    }

    &_close {
        cursor: pointer;
    }

    &_menuItem {
        margin-bottom: rem(16);
    }

    &_menuItemLeaf {
        margin-bottom: rem(5);
    }

    &_menuItemToggle {
        @include adjust-flex(space-between, stretch);

        &-borderNon {
            border: 0;
        }

        &-cursorDefault {
            cursor: default;
        }

        &_iconWrapper {
            @include adjust-flex(flex-end, center);
            padding-bottom: rem(4.5);

            &-grow {
                flex-grow: 1;
            }
        }

        a {
            &:hover {
                .a-paragraph-text {
                    color: $vivid-cerulean;
                }
            }
        }
    }

    &_menuItemPanel {
        padding: rem(7.5) 0 0 rem(16);
    }

    .high-light-menu {
        font-weight: bold;
        color: #f00;
    }
}
