.p-profileOrders {
    &_panel {
        padding: rem(60) rem(83) rem(50) rem(64);
        background-color: $white;
        border-radius: rem(10);
 
        @include tablet-down {
            padding: rem(32) rem(24) rem(25);
        }

        @include mobile-down {
            padding: rem(20) rem(16) rem(16);
        }
    }
}
