.p-newsDetail {
    &_postWrapper {
        @include adjust-flex(center, flex-start);
    }

    &_side {
        flex-shrink: 0;
        width: 25%;
        margin-right: rem(30);

        @include tablet-down {
            width: 35%;
            margin-right: 0;
        }

        @include mobile-down {
            display: none;
        }
    }

    &_post,
    &_hashtagContainer {
        padding: rem(24);
        border-radius: rem(10);
        background-color: $white;
        box-shadow: rem(0) rem(4) rem(16) rgba($black, 0.03);
    } 

    &_hashtagList {
        @include adjust-flex(flex-start, flex-start);
        flex-wrap: wrap;

        margin-right: rem(-10);
    }

    &_hashtag {
        padding: rem(4) rem(8);
        margin-top: rem(12);
        margin-right: rem(10);
        border-radius: rem(4);
        background-color: $anti-flash-white;
    }

    &_post {

        @include tablet-down {
            background-color: transparent;
            box-shadow: none;
        }

        @include mobile-down {
            padding: 0;
        }

        &_content {
            img {               
                max-width: 100%;
                @include mobile-up {
                    min-height: 100% !important;                    
                }
                @include mobile-down {
                    height: auto !important;
                }
            }
            
            video {
                max-width: 100%;
                height: auto !important;
            }

            ul,
            ol {
                margin: revert;
                padding: revert;
            }

            div * {
                padding-bottom: rem(9);
            }
        }
    }

    &_product {
        &_row {
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-10);
            margin-right: rem(-10);
        }

        &_col {
            flex: 0 0 20%;
            max-width: 20%;
            padding: 0 rem(10);

            @include desktop-down {
                flex: 0 0 25%;
                max-width: 25%;
            }

            @include tablet-down {
                flex: 0 0 33.333%;
                max-width: 33.333%;
            }

            @include mobile-down {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}
