
.o-reply {
    &_answer {
        position: relative;

        &:first-child {
            padding-top: rem(16);

            &::before {
                position: absolute;
                pointer-events: none;
                content: '';
                left: rem(12);
                top: 0;
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 16px solid $white-smoke1;
            }
        }

        &:not(:first-child) {
            margin-top: rem(8);
        }

        &_wrap {
            border-radius: rem(8);
            padding: rem(20) rem(24) rem(16) rem(24);
            background-color: $white-smoke1;
        }
    }

    &_editor {
        border: 1.5px solid $celadon-blue057;
        border-radius: rem(10);

        &_box {
            width: 100%;
            height: rem(150);
            padding: rem(15);
            overflow-y: scroll;
            resize: none;
            outline: none;

            &::-webkit-scrollbar {
                width: 0;
            }

        }

        &_action {
            padding: rem(15);
            background-color: $seashell;
            border-bottom-left-radius: rem(8);
            border-bottom-right-radius: rem(8);

            .a-button {
                width: fit-content;
            }
        }
    }
}
