.t-cartProduct {
    &_content {
        margin-top: rem(20);
        position: relative;
        // @include adjust-flex(flex-start, flex-start); 
        display: flex;
        @include desktop-down {
            display: block;
        }

        @include mobile-down {
            margin-top: rem(26);
        }
    }

    &_total {
        max-width: rem(392);
        width: 100%;
        margin-left: rem(67);

        @include break-max(1366px) {
            margin-left: rem(34);
            max-width: rem(320);
        }

        @include desktop-down {
            max-width: 100%;
            margin-left: 0;
            margin-top: rem(70);
        }

        @include mobile-down {
            margin-top: rem(60);
        }
    }

    &_billingInfo {
        @include desktop-up {
            position: -webkit-sticky;
            position: sticky;
            top: rem(145);
        }

        border-radius: rem(10);
        overflow: hidden;
        background-color: $aero012;
        padding-bottom: rem(41);

        &-title {
            background: $aero;
            padding: rem(25) rem(34) rem(21);
        }

        &-general {
            border-radius: rem(500);
            border: 1px solid $celadon-blue;
            background-color: $white;
            position: relative;
            overflow: hidden;
            padding: rem(2);
            @include adjust-flex(flex-start, stretch);
    
            input {
                border: 0;
                @include font-base(16, 21);
                width: 100%;
                padding: rem(12) rem(5) rem(12) rem(24);
                font-family: inherit;
    
                &:focus {
                    outline: none;
                }
    
                &:placeholder-shown {
                    color: $raisin-black;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
    
            button {
                font-family: inherit;
                padding: 0 rem(30);
                border-radius: rem(500);
                background-color: $vivid-cerulean;
                color: #fff;
                border: 0;
                text-transform: uppercase;
                min-width: rem(128);
                height: rem(46);
                @include adjust-flex(center, center);
                @include font-base(14, 17);

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }

        &-deleteButton {
            background-color: $electric-crimson !important;
        }
    }

    .divider {
        border-top: rem(1) solid $raisin-black01;
    }

    .pointer {
        cursor: pointer;
    }

    .loadingIcon {
        height: rem(30);
        width: rem(30);
    }
}

.t-listCartProduct {
    &_info {
        width: 100%;
    }

    &_image {
        min-width: rem(93);
        border-radius: rem(8);
        overflow: hidden; 
    }

    &_option {
        max-width: rem(166);
    }

    &_buttonRemove {
        cursor: pointer;
        margin-top: rem(12);
    }

    table {
        width: 100%;
        border-spacing: 0;

        thead {
            th {
                text-align: left;

                &:nth-child(4) {
                    width: rem(90);
                }

                &:nth-child(2) {
                    width: rem(300);

                    @include tablet-down {
                        width: rem(350);
                    }
                }

                &:last-child {
                    text-align: right;
                }
                padding-bottom: rem(21);
            }
        }

        tbody {
            td {
                padding: rem(23) 0;
                border-top: 1px solid $raisin-black01;
                vertical-align: top;

                &:nth-child(2) {
                    padding-right: rem(16);
                }

                &:first-child {
                    vertical-align: middle;
                }
                
                &:last-child {
                    text-align: right;
                    min-width: rem(150);
                }
            }
        }
    }
}

.t-listCartProductMobile {
    &_image {
        width: 100%;
        max-width: rem(105);
        border-radius: rem(8);
        overflow: hidden;
    }

    &_item {
        padding: rem(30) 0;
        border-bottom: rem(1) solid $raisin-black01;

        &:first-child {
            border-top: rem(1) solid $raisin-black01;
        }
    }

    &_option {
        max-width: rem(166);
    }

    &_quantity {
        max-width: rem(104);
    }

    &_buttonRemove {
        cursor: pointer;
    }
}
