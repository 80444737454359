.m-voucher {
    position: relative;
    width: 100%;
    height: 100%;
   
    &_wrapper {
        position: relative;
        display: flex;
        min-height: rem(139);
        filter: drop-shadow(0 3px 12px rgba($black, 0.06));
        width: 100%;

        @include mobile-down {
            min-height: rem(88);
        }
    }

    &_layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            display: block;
        }
    }

    &_wrapDiscount {
        position: relative;
        @include adjust-flex(center, center);
        width: 100%;
        max-width: 30%;
        @include desktop-down {
            // max-width: 45%;
            max-width: 30%;
        }
        @include small-mobile {
            max-width: 30%;
        }
    }

    &_discount {
        max-width: 50%;
        margin: 0 auto;
    }

    &_title {
        position: relative;
        margin-bottom: rem(4);
    }
    
    &_description {
        position: relative;
        margin-bottom: rem(4);
    }

    &_outDate {
        position: relative;
    }

    &_wrapContent {
        width: 100%;
        @include adjust-flex(center,center);
    }

    &_content {
        padding: rem(20);
        height: 100%;
        width: calc(100% - 30%);
        display: flex;
        flex-direction: column;
        @include desktop-down {
            padding: rem(20) rem(10);
        }
        @include mobile-down {
            padding: rem(10);
        }
    }

    &_flex {
        width: 100%;
        margin-top: auto;
        @include adjust-flex(space-between, flex-end);
    }

    &_btn {
        width: fit-content;
        margin-top: auto;

        .a-button {
            &:disabled {
                background-color: $grayx11;
            }
            padding: rem(0) rem(30);
        }
    }

    &_btnDetail {
        .a-button {
            background: transparent;
            padding: 0;
        }
    }
}
