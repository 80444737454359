.t-productDetailSuggest {
    .o-carousel {
        &_arrow {
            top: 35%;

            &_prev {
                left: rem(-22);
            }

            &_next {
                right: rem(-22);
            }
        }
    }
}
