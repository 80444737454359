.t-checkoutShippingAddress {
    background-color: $white;
    border-radius: rem(10);
    padding: rem(53) rem(67);
    padding-bottom: rem(28);

    @include tablet-down {
        padding: rem(41) rem(37) rem(35);
    }

    @include mobile-down {
        padding: rem(30) rem(24) rem(26);
    }

    &_button {
        cursor: pointer;
        border-bottom: rem(1) solid $vivid-cerulean;
    }

    .a-checkbox {
        label {
            @include font-base(14, 19); 
        }
    }
}
