.t-policymenu {
    &_item {
        height: 100%;
    }

    .a-categoryItem {
        flex-direction: row;
        justify-content: flex-start;
        padding: rem(15) rem(20);

        @include mobile-down {
            flex-direction: column;
            justify-content: center;
            padding: rem(10.5) rem(11.5);
        }

        &_icon {
            width: rem(35);
            height: rem(30);
            @include mobile-down {
                height: rem(30);
                width: rem(25);
            }
        }

        &_title {
            @include font-base(16,20);
            font-weight: 500;
            margin-top: 0;
            margin-left: rem(12);

            @include mobile-down {
                @include font-base(14,14);
                margin-left: 0;
            }
        }
    }
}
