.t-productDetailFeature {
    &_featureList {
        list-style-type: circle;
        padding-left: rem(20);
        margin-top: rem(32);
        @include desktop-down {
            margin-top: rem(4);
        }

        li {
            margin-top: rem(20);
            @include desktop-down {
                margin-top: rem(16);
            }

            @include mobile-down {
                margin-top: rem(12);
            }
        }
    }
}
