.m-jobItem {
    $root: &;

    background-color: $white;
    padding: rem(28) rem(84);
    border-radius: rem(10);
    display: flex;
    cursor: pointer;

    @include desktop-down {
        padding: 0;
        border-radius: 0;
    }

    &_index {
        flex-shrink: 0;
    }

    &_info {
        flex-grow: 1;
        margin-left: rem(11);
        
        @include desktop-up {
            margin-left: rem(55);
            display: flex;
            align-items: center;
        }
    }

    &_title {
        @include desktop-up {
            width: 45%;
        }
        
    }

    &_salary {
        @include desktop-up {
            width: 22.5%;
        }
    }

    &_time {
        @include desktop-up {
            width: 22.5%;
        }
    }

    &_link {
        @include desktop-up {
            width: 15%;
        }
    }

    &:hover {
        #{$root}_link {
            .a-icon {
                animation: arrowSlide 0.75s ease infinite alternate;
            }
        }
    }
}

@keyframes arrowSlide {
    0% {
        transform: translateX(0);
    }
  
    100% {
        transform: translateX(60%);
    }
}
