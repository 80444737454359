.t-contactUsForm {
    padding: rem(56) rem(60) rem(64) rem(76);
    background-color: $white;
    border-radius: 0 rem(10) rem(10) 0;
    overflow: hidden;

    @include desktop-down {
        border-radius: rem(10) rem(10) 0 0;
        padding: rem(60) rem(54);
    }
    @include mobile-down {
        padding: rem(53) rem(22) rem(73);
    }

    &_title {
        margin-bottom: rem(44);
        @include mobile-down {
            margin-bottom: rem(20);
        }
    }

    &_form {
        display: flex;
        flex-direction: column;
        gap: rem(30);
        @include mobile-down {
            gap: rem(20);
        }
    }

    &_fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: rem(36);
        @include tablet-down {
            gap: rem(29);
        }
        @include mobile-down {
            grid-template-columns: 1fr;
        }
    }

    &_helps {
        .m-pulldown_container {
            border-color: $sonic-silver05;
        }
        
        &-error {
            .m-pulldown_container {
                border-color: $electric-crimson;
            }
        }
    }

    &_helpLabel {
        .a-paragraph-text {
            margin-bottom: rem(6);
        }
    }

    &_btn {
        @include adjust-flex(space-between, center);
        @include mobile-down {
            flex-direction: column;
            align-items: flex-start;
            gap: rem(40);
        }
    }

    &_btnSend {
        min-width: rem(134);

        .a-button {
            height: rem(43);
        }
    }
}
