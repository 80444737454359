.t-homeNewSale {
    
    @include desktop-down {
        .o-container {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }            
    }
}
