.a-categoryItem {
    $root: &;
    padding: rem(10) 0;
    background-color: $white;
    transition: $transition-long ease;
    border-radius: rem(10);
    text-align: center;
    @include adjust-flex(center, center);
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &-material {
        #{$root}_title {
            font-weight: 500;
            color: $raisin-black;
        }
    }

    &_icon {
        width: rem(27);
        height: rem(27);

        img {
            width: 100%;
            height: 100%;
        }
    }

    &_title {
        @include font-base(16, 21);
        margin-top: rem(5);
        color: rgba($raisin-black, 0.7);
    }

    &-active,
    &:hover {
        background-color: $vivid-cerulean;

        #{$root}_title {
            color: $white;
        }

        #{$root}_icon {
            filter: brightness(0) invert(1);
        }
    }
}
