.m-newsCard {
    $root: &;

    &_wrap {
        #{$root}-horizontal & {
            @include mobile-up {
                display: flex;
            }
        }
    }

    &_image {
        .a-image {
            border-radius: rem(16);
            overflow: hidden;
        }
      
        #{$root}-horizontal & {
            @include tablet-up {
                width: rem(224);
            }
            @include mobile-up {
                width: rem(206);
                flex-shrink: 0;
                margin-right: rem(8);
            }
        }
    }

    &_info {
        margin-top: rem(8);
    }

    &_title {
        #{$root}-vertical & {
            @include text-overflow(2);
        }
        #{$root}-horizontal & {
            @include text-overflow(2);
            @include mobile-up {
                @include text-overflow(1);
            }
        }
    }

    &_description {
        @include text-overflow(2);
    }
}
