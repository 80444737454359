.p-materialDetail {
    &_list {
        margin: 0 rem(-16);


        @include desktop-down {
            margin: rem(-9) rem(-10);
        }

        @include mobile-down {
            margin: rem(-5) 0;
        }

        &-item {
            padding: 0 rem(16);

            @include desktop-down {
                padding: rem(9) rem(10);
            }

            @include mobile-down {
                padding: rem(5) 0;
            }
        }
    }

    .divider {
        border-top: rem(1) solid $raisin-black01;
    }

    &_suggestList {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-20) rem(-10) 0;
        @include desktop-down {
            margin: rem(8) rem(-7) 0;
        }
        @include mobile-down {
            margin-top: rem(-13);
        }
    }

    &_suggestItem {
        flex: 0 0 20%;
        padding: 0 rem(10);
        margin-top: rem(46);
        @include desktop-down {
            flex: 0 0 calc(100% / 3);
            padding: 0 rem(7);
            margin-top: rem(25);
        }
        @include mobile-down {
            flex: 0 0 50%;
            margin-top: rem(30);
        }
    }
}
