.t-aboutUsPeople {
    $root: &;
    @include tablet-down {
        flex-direction: column;
    }
    display: flex;
    align-items: center;

    &_describe {
        &-info {
            margin-bottom: rem(59);
            position: relative;
            z-index: 2;
            @include tablet-down {
                margin-top: rem(0);
                margin-bottom: rem(34);
            }
        }

        &-icon {
            position: absolute;
            width: rem(105);
            height: rem(77);
            top: rem(-45);
            left: rem(-50);
            z-index: -1;
            @include tablet {
                top: rem(-45);
                left: rem(-40);
            }
            @include mobile-down {
                top: rem(-97);
                left: rem(0);
            }
        }

        &-position {
            color: $cyan-cornflower;
        }
    }

    &_avatar {
        .a-image {
            @include aspect-ratio(656, 566);
        }
        @include tablet-down {
            margin-top: rem(74);
        }
        @include mobile-down {
            margin-top: rem(47);
        }
    }

    &-reverse {
        flex-direction: row-reverse;
        @include tablet-down {
            flex-direction: column-reverse;
        }
        #{$root}_describe {
            &-info {
                margin-top: 0;
                @include tablet-down {
                    margin-top: rem(110);
                    margin-bottom: rem(34);
                }
                @include mobile-down {
                    margin-top: rem(123);
                }
            }

            &-icon {
                top: rem(-35);
                left: rem(-50);

                @include tablet-down {
                    top: rem(-45);
                    left: rem(-40);
                }
                @include mobile-down {
                    top: rem(-97);
                    left: rem(0);
                }
            }
        }
        #{$root}_avatar {
            @include tablet-down {
                margin-top: 0;
            }

            .a-image {
                @include aspect-ratio(624, 491);
            }
        }
    }
}
