.m-voucherCard {
    $root: &;
    cursor: pointer;
    max-width: rem(207);

    &_header {
        @include adjust-flex(flex-start, center); 

        #{$root}_label {
            padding: 0 rem(12);
            background: $vivid-cerulean;
            border-radius: rem(13);
            margin-right: rem(8);
            flex-shrink: 0;

            @include break(576px, 991px) {
                max-width: rem(52);
                padding: 0;
                border-radius: rem(7);
            }
        }
    }

    &_description {
        @include text-overflow(1); 
    }
}
