.t-popupRecruitment {
    &_divider {
        border: 1px solid $isabelline;
    }

    &_pulldown {
        width: rem(200);
        @include mobile-down {
            width: rem(170);
        }
        @include small-mobile {
            width: 100%;
        }
    }

    &_blockAddress {
        height: 100%;
        border-radius: rem(16);
        border: 1px solid $sonic-silver025;
        padding: rem(17);
    }

    &_inputFile {
        width: 100%;
        position: relative;
        border: 1px dashed $cyan;
        border-radius: rem(100);
        padding: rem(16) rem(38) rem(16) rem(16);
        cursor: pointer;

        &.error {
            border-color: $electric-crimson;
        }

        &_label {
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            padding: 0 rem(5);
            width: fit-content;
            left: rem(26);
            background-color: $white;
        }

        &_icon {
            position: absolute;
            right: rem(16);
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            pointer-events: none;

            &.remove {
                pointer-events: initial;
            }
        }

        input {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
    }
}
