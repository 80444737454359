.m-pulldown {
    $root: &;
    width: 100%;
    
    &_wrap {
        position: relative;
        min-height: rem(46);
        @include mobile-down {
            min-height: rem(44);
        }
    }
 
    &_label {
        position: absolute;
        top: rem(-15);
        left: rem(26);
        background-color: $white;
        padding: 0 rem(3);
    }

    &_container {
        $container: &;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        background: $white;
        cursor: pointer;
        border-radius: rem(25);
        padding: rem(12) rem(42) rem(12) rem(20);
        border: 1px solid $celadon-blue;
        box-shadow: none;
        z-index: 1;
        transition: 0.3s linear;

        @include mobile-down {
            border-radius: rem(20);
            padding-left: rem(12);
        }

        &-open {
            border: 1px solid transparent;
            box-shadow: 0 0 5px 5px rgba($color: $black, $alpha: 0.03);
            z-index: 9;
            padding-right: rem(12);

            #{$container}_arrow {
                opacity: 0;
            }
        }

        &-disabled {
            cursor: initial;
        }


        &-secondary {
            border-color: rgba($dark-gray, 0.25);
            
            #{$container}_title {
                color: $raisin-black;
                @include font-base(14, 18);
            }

            #{$container}_title-placeholder {
                color: $dark-gray;
            }

            #{$container}_arrow {
                background-image: url('~assets/icons/ic_arrow_down_gray.svg');
                transform: rotate(0);
                background-color: transparent;
            }
        }

        @include mobile-down {
            border-radius: rem(20);
            padding-left: rem(12);
        }

        &-menu {
            transition: $transition ease-in-out;
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            overflow: auto;
            @include scroll-bars();
            #{$container}-open & {
                max-height: rem(200);
                opacity: 1;
                visibility: visible;
            }

            &-loading {
                opacity: 0;
                transition: $transition;
                visibility: hidden;
                height: 0;
                @include adjust-flex();

                img {
                    width: rem(24);
                }

                #{$container}-open & {
                    height: rem(24);
                    opacity: 1;
                    visibility: visible;
                }
            }

            &-item {
                overflow: hidden;
                @include adjust-flex(flex-start, center);
                @include font-base(16, 20);
                color: rgba($color: $raisin-black, $alpha: 0.5);
                height: rem(46);
                @include mobile-down {
                    height: rem(40);
                }

                &:hover {
                    color: $vivid-cerulean;
                }

                @include mobile-down {
                    @include font-base(14, 18);
                }

                // #{$container}-open & {
                //     overflow: auto;
                //     height: rem(46);

                //     @include mobile-down {
                //         height: rem(40);
                //     }
                // }

                // hide scroll bar
                &::-webkit-scrollbar {
                    display: none;
                }

                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
            }
        }

        &_title {
            white-space: nowrap;
            min-height: rem(20);
            @include font-base(16, 20);
            color: $vivid-cerulean;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;

            @include mobile-down {
                @include font-base(14, 18);
                min-height: rem(18);
            }

            &-placeholder {
                color: $raisin-black07;
            }

            #{$container}-open & {
                padding-bottom: rem(13);
            }

            input {
                color: $raisin-black;
                font: inherit;
                outline: none;
                background-color: transparent;
                padding: 0;
                width: 100%;
                border: 0;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
            }
        }

        &_arrow {
            position: absolute;
            background-image: url('~assets/icons/ic_arrow_down_white.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 35% 35%;
            right: rem(5);
            top: rem(4);
            width: rem(37);
            height: rem(37);
            transform: rotate(90deg);
            background-color: $vivid-cerulean;
            border-radius: 50%;
            opacity: 1;
            transition: 0.3s;

            @include mobile-down {
                width: rem(30);
                height: rem(30);
                top: rem(5);
                background-size: 30% 30%;
            }


        }

    }

    &-error {
        #{$root}_container {
            border-color: $electric-crimson;
        }

    }
}
