.t-describeSummary {
    &_thumbnail {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: rem(-60);
            right: rem(-110);
            background-color: $azureish-white085;
            width: rem(328);
            height: rem(328);
            border-radius: 50%;

            @include desktop-down {
                right: rem(-107);
                bottom: rem(-143);

            }

            @include mobile-down {
                bottom: -30%;
                right: -15%;
                width: rem(244);
                height: rem(244);
            }
        }
    
    }

    &_content {
        @include adjust-flex(center, flex-start);
        flex-direction: column;
        position: relative;

        @include desktop-up {
            padding-left: rem(60);
        }

        @include mobile-down {
            margin-top: rem(48);
        }
    }


    &_description {
        margin-top: rem(34);

        @include desktop-down {
            margin-top: rem(29);
        }
        
        @include mobile-down {
            margin-top: rem(21);
        }
    }
}
