.o-popup {
    $root: &;
    border: 0;
    border-radius: rem(8);
    height: 100%;
    outline: 0;
    position: fixed;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &_close {
        @include reset-button();
        background-color: transparent;
        padding: 0;
        position: absolute;
        right: rem(28);
        top: rem(28);
        z-index: $z-1;

        @include tablet-down {
            right: rem(8);
            top: rem(8);
        }
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding-left: rem(16);
        padding-right: rem(16);
        width: 100%;
    }

    &_wrapper {
        background: $white;
        box-shadow: 0 0 rem(24) rem(1) $black;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        max-height: calc(100vh - #{rem(120)});
        max-width: rem(740);
        overflow: hidden;
        padding-top: rem(40);
        position: relative;
        width: 100%;
        z-index: 103;

        @include tablet-down {
            max-height: calc(100vh - #{rem(60)});
        }

        @include mobile-down {
            max-height: calc(100vh - #{rem(48)});
        }
    }

    &_body {
        @include scroll-bars(6, $black, $white, 0, 1);
        flex: 1;
        margin-top: rem(20);
        overflow-y: auto;
        padding: 0 rem(60) rem(24);

        @include tablet-down {
            margin-top: rem(12);
            padding: 0 rem(16) rem(16);
        }
    }

    &_header {
        padding-left: rem(64);
        padding-right: rem(64);

        @include mobile-down {
            padding-left: rem(16);
            padding-right: rem(16);
        }
    }

    &-authenticate {
        #{$root}_close {
            position: static;
            margin: rem(20) rem(20) 0 auto;
            @include mobile-down {
                margin: rem(8) rem(8) 0 auto;
            }
        }
        #{$root}_main {
            @include mobile-down {
                padding: 0;
            }
        }
        #{$root}_wrapper {
            box-shadow: none;
            max-width: rem(994);
            padding: 0;
            border-radius: rem(30);
            overflow: auto;
            @include mobile-down {
                height: 100vh;
                max-height: 100%;
                border-radius: 0;
            }
        }
        #{$root}_body {
            overflow: unset;
            padding: rem(31) 0 rem(75);
            margin-top: 0;
            @include mobile-down {
                padding: rem(30) 0;
            }
        }
    }
    
    &-voucher {

        #{$root}_wrapper {
            border-radius: 10px;
            background: #f9f5ee;
            padding: 0;
        }
        #{$root}_body {
            padding: rem(68) rem(50) rem(48);
            margin: 0;

            @include tablet-down {
                padding: rem(106) rem(52) rem(84);
            }

            @include mobile-down {
                padding: rem(78) rem(16) rem(60);
            }
        }
        #{$root}_close {
            right: rem(30);
            top: rem(30);

            @include mobile-down {
                right: rem(28);
                top: rem(28);
            }
        }
    }
    
    &-address {
        #{$root}_wrapper {
            border-radius: rem(32);
            padding: 0;
        }
        #{$root}_body {
            padding: rem(35) rem(32) rem(32);
            margin: 0;
        }

        #{$root}_close {
            right: rem(32);
            top: rem(35);
        }
    }

    &-gallery {
        #{$root}_wrapper {
            padding: 0;
            max-width: rem(994);
            background: transparent;
            box-shadow: none;

            @include max-desktop-down {
                max-width: rem(800);
            }
        }
        #{$root}_body {
            padding: 0;
            margin: 0;
            overflow: hidden;
        }

        #{$root}_close {
            right: rem(16);
            top: rem(16);
        }
    }
    
    &-voucherCustomer {
        #{$root}_wrapper {
            padding: rem(40) rem(32) rem(32);
            max-width: rem(964);
            background: $white;
            box-shadow: none;
            border-radius: rem(32);
            box-shadow: 0 4px 16px $black01;

            @include tablet-down {
                max-width: rem(514);
            }

            @include mobile-down {
                padding: rem(22) rem(16) rem(16);
            }
        }
        #{$root}_body {
            padding: 0;
            margin: 0;
            overflow: hidden;
        }

        #{$root}_close {
            right: rem(32);
            top: rem(35);
        }
    }

    &-confirm,
    &-removeOrder {
        #{$root}_wrapper {
            border-radius: rem(32);
            max-width: rem(400);
            padding: 0;
        }

        #{$root}_body {
            margin: 0;
            padding: rem(20);
        }

        #{$root}_close {
            right: rem(16);
            top: rem(16);
        }
    }

    &-addressList {
        #{$root}_wrapper {
            border-radius: rem(32);
            padding: 0;
        }
        #{$root}_body {
            // padding: rem(42) rem(32) rem(32);
            padding: 0;
            margin: 0;
            // max-height: rem(620);
            // overflow: auto;
            // @include scroll-bars();
        }

        #{$root}_close {
            right: rem(24);
            top: rem(16);
        }
    } 
    
    &-checkStore {
        #{$root}_wrapper {
            background: $seashell;
            max-width: rem(1360);
            max-height: rem(630);
            height: 100%;
            padding-top: rem(64);
            padding-bottom: rem(90);
            
            @include tablet-down {
                max-height: rem(1021);
                padding-top: rem(93);
                padding-bottom: rem(113);
            }
            @include small-mobile {
                max-height: rem(784);
                padding-top: rem(71);
                padding-bottom: rem(33);
            }
        }

        #{$root}_main {
            padding-left: 0;
            padding-right: 0;
        }

        #{$root}_body {
            margin-top: 0;
            padding: 0;
        }
    }

    &-sizeGuide {
        #{$root}_wrapper {
            border-radius: rem(10);
            background: $seashell !important;
            box-shadow: 0 0 rem(24) rem(1) $black;
            max-height: calc(100vh - #{rem(120)});
            max-width: rem(1360);
            @include tablet-down {
                max-width: rem(670);
            }
            @include mobile-down {
                padding: rem(70) rem(20);
                max-width: rem(346);
            }
        }
        #{$root}_close {
            top: rem(31);
            right: rem(31);
            @include mobile-down {
                top: rem(24);
            }
        }
        #{$root}_body {
            margin-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }
    }

    &-recruitment {
        #{$root}_wrapper {
            border-radius: rem(32);
            box-shadow: 0;
            max-width: rem(1262);
            padding: 0;
            overflow: auto;

            @include tablet-down {
                max-width: rem(686);
            }
            
            @include small-mobile {
                max-width: rem(360);
            }
        }
        
        #{$root}_body {
            margin: 0;
            padding: 0 rem(32) rem(32);
            overflow: initial;
        }

        #{$root}_close {
            position: static;
            margin: rem(10) rem(32) 0 auto;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba($black, 0.3);
    inset: 0;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba($black, 0.74);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
        @include tablet-up {
            padding-right: rem(7);
        }
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}
