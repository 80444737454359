.t-popupCheckStore {
    &_content {
        margin-top: rem(30);
        @include adjust-flex(space-between, flex-start);
        @include tablet-down {
            margin-top: rem(28);
            flex-direction: column;
            justify-content: flex-start;
        }
        @include small-mobile {
            margin-top: rem(27);
        }
    }

    &_product {
        width: 100%;
        display: flex;

        @include tablet-down {
            margin-bottom: rem(45);
        }

        @include small-mobile {
            margin-bottom: rem(70);
        }
    }

    &_productImg {
        max-width: rem(231);
        width: 100%;
        height: 100%;
        margin-right: rem(33);
        @include small-mobile {
            display: none;
        }

        .a-image {
            border-radius: rem(10);
        }
    }

    &_productDetail {
        width: 100%;
    }

    &_detail {
        @include small-mobile {
            @include adjust-flex(flex-start, center);
        }
    }

    &_imgMobile {
        max-width: rem(140);
        width: 100%;
        height: 100%;
        margin-right: rem(28);
        @include small-mobile-up {
            display: none;
        }

        .a-image {
            border-radius: rem(10);
        }
    }

    &_productCode {
        margin-top: rem(2);
        margin-bottom: rem(14);
        @include small-mobile {
            margin-top: rem(4);
        }
    }
    
    &_productChoose {
        display: flex;
        width: 100%;
        margin-top: rem(20);
        @include desktop {
            flex-direction: column;
        }
    }

    &_productColor {
        margin-right: rem(36);
        @include desktop {
            margin-bottom: rem(8);
        }
    }

    &_productColor,
    &_productSize {
        max-width: rem(128);
        width: 100%;

        .a-paragraph-text {
            margin-bottom: rem(4);
        }
    }

    &_productSize {
        @include desktop {
            z-index: 0;
        }
    }

    &_store {
        width: 100%;
        flex: 0 0 41%;
        @include desktop {
            flex: 0 0 50%;
        }
    }

    &_location {
        width: 100%;
        @include adjust-flex(flex-start, center);
        @include small-mobile {
            justify-content: space-between;
        }

        &-label {
            flex: 0 0 auto;
            margin-right: rem(14);
        }

        &-select {
            max-width: rem(171);
            width: 100%;
        }
    }

    &_amount {
        margin-top: rem(30);
        margin-bottom: rem(14);
        @include tablet-down {
            margin-top: rem(20);
            margin-bottom: rem(18);
        }
        @include small-mobile {
            margin-top: rem(24);
            margin-bottom: rem(14);
        }
    }

    &_list {
        overflow-y: scroll;
        height: rem(292);
        padding-right: rem(28);

        &::-webkit-scrollbar {
            width: rem(4);
        }

        &::-webkit-scrollbar-track {
            background: $raisin-black02;
            border-radius: rem(100);
        }

        &::-webkit-scrollbar-thumb {
            background: $vivid-cerulean;
            border-radius: rem(100);
        }

        @include tablet-down {
            height: rem(299);
            padding-right: rem(46);
        }

        @include small-mobile {
            height: rem(254);
            padding-right: rem(49);
        }
    }

    &_storeInfo {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: rem(14);

        @include small-mobile {
            flex-direction: row;
            padding-bottom: rem(26);
        }

        &:not(:first-child) {
            padding-top: rem(14);
            @include small-mobile {
                padding-top: rem(26);
            }
        }

        &::after {
            position: absolute;
            content: '';
            background: $raisin-black01;
            width: 100%;
            height: rem(1);
            bottom: 0;
        }
    }

    &_storeName {
        flex: 0 0 auto;

        @include mobile-down {
            flex: 0 0 rem(100);
        }
    }

    &_storeInfoBlock {
        margin-top: rem(12);
        @include adjust-flex(space-between, flex-start);
        @include small-mobile {
            margin-top: 0;
            margin-left: rem(22);
            flex-direction: column;
        }
    }
    
    &_storeAddress {
        max-width: rem(255);
        width: 100%;
        @include small-mobile {
            max-width: 100%;
            margin-bottom: rem(14);
        }
    }

    &_storePhone,
    &_storeTime {
        @include adjust-flex();

        .a-icon {
            margin-right: rem(10);
        }
    }
}
