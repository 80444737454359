.t-contactUsInfo {
    position: relative;
    padding: rem(60) rem(60) rem(60) rem(47);
    height: 100%;
    background-color: $celadon-blue;
    border-radius: rem(10) 0 0 rem(10);
    overflow: hidden;

    @include desktop-down {
        padding: rem(60) rem(54);
        border-radius: 0 0 rem(10) rem(10);
    }

    @include mobile-down {
        padding: rem(50) rem(30) rem(61);
    }

    &::after {
        content: '';
        position: absolute;
        background-image: url('~assets/images/contactUsInfo/backgound_image.png');
        background-repeat: no-repeat;
        background-size: cover;
        width: rem(344);
        height: rem(266);
        z-index: $z-1;
        left: 0;
        bottom: 0;

        @include desktop-down {
            content: none;
        }
    }

    &_info {
        &:not(:last-child) {
            margin-bottom: rem(24);
        }
    }

    &_wrapLabel {
        display: flex;
        margin-right: rem(30);
        min-width: rem(115);

        &_icon {
            .a-image {
                @include aspect-ratio(1, 1);
                width: rem(20);
            }
        }
    }
}
