.t-aboutUsAchievements {
    &_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &_left {
        flex: 0 0 40%;
        max-width: 40%;
        @include desktop-down {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include tablet-up {
            display: flex;
            align-items: center;
        }
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_carouselInfo {
        width: 100%;

        .o-carousel_arrow {
            top: auto;
            bottom: 0;
            transform: none;

            &_prev {
                left: 0;
            }

            &_next {
                left: rem(70);
                right: auto;
            }
        }
    }

    &_right {
        flex: 0 0 50%;
        max-width: 50%;
        @include desktop-down {
            flex: 0 0 45%;
            max-width: 45%;
        }
        @include tablet-up {
            display: flex;
            align-items: center;
        }
        @include tablet-down {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    &_carouselThumbnail {
        width: 100%;

        .o-carousel_arrow {
            top: calc(100% + 10px);
            transform: none;

            &_prev {
                left: 0;
            }

            &_next {
                left: rem(70);
                right: auto;
                @include mobile-down {
                    left: rem(47);
                }
            }
        }
    }
}
