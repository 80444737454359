$filled-button: (
    'primary': $vivid-cerulean,
    'dark': $raisin-black,
    'white': $white,
    'red': $red
);
$outline-button: (
    'outline-primary': $vivid-cerulean,
    'outline-dark': $raisin-black,
);

.a-button {
    $root: &;
    @include reset-button();
    position: relative;
    transition: $transition-long ease;
    border-radius: rem(999);
    padding-left: rem(16);
    padding-right: rem(16);
    width: 100%;
    @include adjust-flex(center, center);
    white-space: nowrap;

    &:not(:disabled) {
        &:hover,
        &:focus {
            transform: scale(1.05);

            &#{$root}-combo {
                background-color: $vivid-cerulean;
            }

            &#{$root}-collection-detail {
                border: 1px solid $spanish-crimson;

                .a-paragraph-text {
                    color: $spanish-crimson;
                }
            }
        }
    }

    &_loadingIcon {
        width: rem(24);
    }

    &-sm-small {
        height: rem(35);
    }
    
    &_wrapper {
        @include adjust-flex(center, center);
    }

    &-xs {
        height: rem(35);
        @include tablet-desktop {
            height: rem(30);
        }
    }

    &-sm {
        height: rem(42);
    }

    &-md {
        height: rem(46);
    }

    &-lg {
        height: rem(50);
    }

    @each $name, $color in $filled-button {
        &-#{$name} {
            border: 0;
            background-color: $color;
        }
    }

    @each $name, $color in $outline-button {
        &-#{$name} {
            border: 1px solid $color;
            background-color: transparent;
        }
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}
