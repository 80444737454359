.a-inputUpload {  
    $root: &;
    display: flex;
    align-items: center;

    button {
        @include reset-button();
        background-color: transparent;
        display: flex;
        align-items: center;
        border-bottom: 2px solid $celadon-blue;
        padding: 0;
        margin-left: rem(5);

        img {
            margin-left: rem(3);
        }
    }

    &-error {
        .a-paragraph-text {
            color: $electric-crimson;
        }

        button {
            border-color: $electric-crimson;
        }

        img {
            filter: brightness(1.35) invert(1);
        }
    }
}
