.t-describeAttribute {
    &_intro {
        margin-bottom: rem(60);
        @include tablet-down {
            margin-bottom: rem(70);
        }
        @include mobile-down {
            margin-bottom: rem(57);
        }
    }

    &_introTitle {
        margin-bottom: rem(9);
        @include tablet-down {
            margin-bottom: rem(10);
        }
        @include mobile-down {
            margin-bottom: rem(18);
        }

        .a-paragraph-heading {
            @include mobile-down {
                text-align: left;
            }
        }
    }

    &_introDesc {
        max-width: rem(664);
        margin: auto;
        @include desktop-down {
            max-width: 100%;
        }
        
        .a-paragraph-text {
            @include mobile-down {
                text-align: left;
            }
        }
    }

    &_statisticWrapper {
        @include adjust-flex(flex-start, center);
        @include mobile-down {
            @include adjust-flex();
            margin-bottom: rem(70);
        }
    }

    &_statistic {
        border-radius: 50%;
        width: rem(316);
        height: rem(316);
        @include desktop-down {
            width: rem(238);
            height: rem(238);
        }
        @include mobile-down {
            width: rem(232);
            height: rem(232);
        }
    }

    &_item {
        max-width: rem(233);
        display: flex;
        flex-direction: column;
        @include tablet-down {
            max-width: rem(172);
        }
        @include mobile-down {
            max-width: 100%;
        }

        &-img {
            width: rem(37);
            height: rem(37);

            img {
                width: 100%;
            }

            @include mobile-down {
                order: 2;
                margin-top: rem(12);
                margin-bottom: rem(11);
            }
        }

        &-title {
            margin-top: rem(14);
            margin-bottom: rem(7);
            @include mobile-down {
                margin: 0;
                order: 1;
            }
        }

        &-description {
            @include mobile-down {
                order: 3;
            }
        }
    }

}
