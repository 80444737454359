$filled-tab-button: (
    'aero012': $aero012,
    'white': $white,
);

.a-tabButton {
    $root: &;
    @include reset-button();
    transition: $transition ease-in-out;
    width: 100%;
    @include adjust-flex(initial, center);
    padding: 0 rem(19);
    height: 100%;
    min-height: rem(60);
    border-radius: rem(10);
    border: 0;
    cursor: pointer;
    color: $raisin-black;

    @include tablet-down {
        padding: 0 rem(15);
    }

    @include small-mobile {
        flex-direction: column;
        align-items: center;
        min-height: rem(86);
        padding: rem(10) rem(5) 0;
    }
    
    @each $name, $color in $filled-tab-button {
        &-#{$name} {
            background-color: $color;
        }
    }

    &-secondary {
        border-radius: rem(22);
        background-color: transparent;
        border: rem(1) solid $vivid-cerulean;
        min-height: rem(40);
    }
   
    .a-icon,
    img {
        flex-shrink: 0;
        margin-right: rem(12);
        @include small-mobile {
            margin-right: 0;
            margin-bottom: rem(7.2);
        }
    }

    &-active,
    &:hover {
        background-color: $vivid-cerulean;
        box-shadow: 0 10px 20px $field-drab005;

        .a-icon,
        img {
            filter: brightness(0) invert(1);
        }

        color: $white;
    }
}
