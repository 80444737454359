@import './components';

.p-home {
    .voucher {
        box-shadow: 0 15px 20px $black003;
    }

    .advertise,
    .flashSale {
        background-color: $linen029;
        @include mobile-down {
            background-color: transparent;
        }
    }
}
